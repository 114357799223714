.preloader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: #fff
}

.dash {
    margin: 0 15px;
    width: 35px;
    height: 15px;
    border-radius: 8px;
    background: #5255c5
}

.dash-one {
    margin-right: -18px;
    background: #5255c5;
    -ms-transform-origin: center left;
    transform-origin: center left;
    animation: spin 3s linear infinite
}

.dash-two {
    background: #5dba3b;
    -ms-transform-origin: center right;
    transform-origin: center right;
    animation: spin2 3s linear infinite;
    animation-delay: .2s
}

.dash-three {
    background: #ff8b00;
    -ms-transform-origin: center right;
    transform-origin: center right;
    animation: spin3 3s linear infinite;
    animation-delay: .3s
}

.dash-four {
    background: #ff5157;
    -ms-transform-origin: center right;
    transform-origin: center right;
    animation: spin4 3s linear infinite;
    animation-delay: .4s
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    25% {
        transform: rotate(360deg)
    }
    30% {
        transform: rotate(370deg)
    }
    35% {
        transform: rotate(360deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes spin2 {
    0% {
        transform: rotate(0deg)
    }
    20% {
        transform: rotate(0deg)
    }
    30% {
        transform: rotate(-180deg)
    }
    35% {
        transform: rotate(-190deg)
    }
    40% {
        transform: rotate(-180deg)
    }
    78% {
        transform: rotate(-180deg)
    }
    95% {
        transform: rotate(-360deg)
    }
    98% {
        transform: rotate(-370deg)
    }
    100% {
        transform: rotate(-360deg)
    }
}

@keyframes spin3 {
    0% {
        transform: rotate(0deg)
    }
    27% {
        transform: rotate(0deg)
    }
    40% {
        transform: rotate(180deg)
    }
    45% {
        transform: rotate(190deg)
    }
    50% {
        transform: rotate(180deg)
    }
    62% {
        transform: rotate(180deg)
    }
    75% {
        transform: rotate(360deg)
    }
    80% {
        transform: rotate(370deg)
    }
    85% {
        transform: rotate(360deg)
    }
    100% {
        transform: rotate(360deg)
    }
}

@keyframes spin4 {
    0% {
        transform: rotate(0deg)
    }
    38% {
        transform: rotate(0deg)
    }
    60% {
        transform: rotate(-360deg)
    }
    65% {
        transform: rotate(-370deg)
    }
    75% {
        transform: rotate(-360deg)
    }
    100% {
        transform: rotate(-360deg)
    }
}

body {
    background: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5em;
    color: #777;
    -webkit-font-smoothing: antialiased
}

body::-moz-selection {
    background: #5255c5;
    color: #fff
}

body::selection {
    background: #5255c5;
    color: #fff
}

body::-moz-selection {
    background: #5255c5;
    color: #fff
}

body::-webkit-selection {
    background: #5255c5;
    color: #fff
}

body:-ms-input-placeholder {
    color: #aeaeae;
    opacity: 1
}

body::placeholder {
    color: #aeaeae;
    opacity: 1
}

body:-ms-input-placeholder {
    color: #aeaeae
}

body::-ms-input-placeholder {
    color: #aeaeae
}

body h1,
body .h1,
body h2,
body .h2,
body h3,
body .h3,
body h4,
body .h4,
body h5,
body .h5,
body h6,
body .h6 {
    margin-top: 0;
    font-family: "Baloo", cursive;
    line-height: 1.2em;
    color: #090605
}

@media (max-width: 1920px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body h1,
    body .h1,
    body h2,
    body .h2,
    body h3,
    body .h3,
    body h4,
    body .h4,
    body h5,
    body .h5,
    body h6,
    body .h6 {
        text-align: center
    }
}

body h1,
body .h1 {
    font-size: 48px
}

@media (max-width: 767px) {
    body h1,
    body .h1 {
        font-size: 40px
    }
}

@media (max-width: 480px) {
    body h1,
    body .h1 {
        font-size: 34px
    }
}

body h2,
body .h2 {
    font-size: 36px
}

@media (max-width: 767px) {
    body h2,
    body .h2 {
        font-size: 30px
    }
}

body h3,
body .h3 {
    font-size: 24px
}

@media (max-width: 767px) {
    body h3,
    body .h3 {
        font-size: 22px
    }
}

body h4,
body .h4 {
    font-size: 20px
}

body h5,
body .h5 {
    font-size: 18px
}

body h6,
body .h6 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 17px
}

body p {
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 1.7em;
    color: #777
}

@media (max-width: 1920px) {
    body p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    body p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    body p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body p {
        margin-bottom: 20px
    }
}

body a {
    display: inline-block;
    text-decoration: none;
    outline: none !important;
    line-height: 1.3em
}

body a:focus {
    color: initial
}

body a::-moz-focus-inner {
    border: 0 !important
}

body img {
    max-width: 100%;
    display: inline-block
}

body blockquote,
body .blockquote {
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #d8d8d8;
    border-radius: 30px
}

@media (max-width: 1920px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body blockquote,
    body .blockquote {
        margin-bottom: 20px
    }
}

@media (max-width: 1920px) {
    body blockquote,
    body .blockquote {
        padding-top: 30px
    }
}

@media (max-width: 1600px) {
    body blockquote,
    body .blockquote {
        padding-top: 30px
    }
}

@media (max-width: 1440px) {
    body blockquote,
    body .blockquote {
        padding-top: 30px
    }
}

@media (max-width: 1199px) {
    body blockquote,
    body .blockquote {
        padding-top: 30px
    }
}

@media (max-width: 991px) {
    body blockquote,
    body .blockquote {
        padding-top: 25px
    }
}

@media (max-width: 767px) {
    body blockquote,
    body .blockquote {
        padding-top: 20px
    }
}

@media (max-width: 480px) {
    body blockquote,
    body .blockquote {
        padding-top: 20px
    }
}

@media (max-width: 1920px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 30px
    }
}

@media (max-width: 991px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 25px
    }
}

@media (max-width: 767px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 20px
    }
}

@media (max-width: 480px) {
    body blockquote,
    body .blockquote {
        padding-bottom: 20px
    }
}

body blockquote::after,
body .blockquote::after {
    content: '\f27e';
    position: absolute;
    top: 0;
    left: 50%;
    padding: 0 15px;
    background: #fff;
    font-family: 'Material Design Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 55px;
    color: #5dba3b;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

body blockquote h4,
body blockquote .h4,
body .blockquote h4,
body .blockquote .h4 {
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5em;
    text-align: center;
    letter-spacing: 1px
}

body blockquote h6,
body blockquote .h6,
body .blockquote h6,
body .blockquote .h6 {
    margin-bottom: 0;
    text-align: center
}

body time {
    display: block;
    font-family: "Roboto", sans-serif;
    font-style: italic;
    font-size: 15px;
    line-height: 1.4em;
    color: #777
}

@media (max-width: 1920px) {
    body form {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body form {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body form {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body form {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    body form {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    body form {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body form {
        margin-bottom: 20px
    }
}

body form button[type='submit'] {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    body form button[type='submit'] {
        margin-top: 30px
    }
}

@media (max-width: 1600px) {
    body form button[type='submit'] {
        margin-top: 30px
    }
}

@media (max-width: 1440px) {
    body form button[type='submit'] {
        margin-top: 30px
    }
}

@media (max-width: 1199px) {
    body form button[type='submit'] {
        margin-top: 30px
    }
}

@media (max-width: 991px) {
    body form button[type='submit'] {
        margin-top: 25px
    }
}

@media (max-width: 767px) {
    body form button[type='submit'] {
        margin-top: 20px
    }
}

@media (max-width: 480px) {
    body form button[type='submit'] {
        margin-top: 20px
    }
}

@media (max-width: 480px) {
    body form button[type='submit'] {
        margin-left: auto;
        margin-right: auto;
        display: block
    }
}

body label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #777;
    cursor: pointer
}

@media (max-width: 1920px) {
    body label {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    body label {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    body label {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    body label {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    body label {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    body label {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    body label {
        margin-bottom: 5px
    }
}

body input {
    display: block;
    width: 100%;
    padding: 5px 15px;
    border: 1px solid #aeaeae;
    border-radius: 10px;
    font-size: 15px;
    line-height: 15px;
    outline: none !important
}

@media (max-width: 1920px) {
    body input {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    body input {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    body input {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    body input {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    body input {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    body input {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    body input {
        margin-bottom: 15px
    }
}

body select {
    display: block;
    width: 100%;
    padding: 5px 15px;
    border: 1px solid #aeaeae;
    border-radius: 10px;
    font-size: 15px;
    line-height: 15px;
    outline: none !important
}

@media (max-width: 1920px) {
    body select {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    body select {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    body select {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    body select {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    body select {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    body select {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    body select {
        margin-bottom: 15px
    }
}

body textarea {
    display: block;
    width: 100%;
    height: 200px;
    margin-bottom: 30px;
    padding: 15px;
    border: 1px solid #aeaeae;
    border-radius: 10px;
    outline: none !important;
    font-size: 15px;
    resize: none
}

@media (max-width: 1920px) {
    body textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    body textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    body textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    body textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    body textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    body textarea {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    body textarea {
        margin-bottom: 15px
    }
}

body *[data-parent-move] {
    position: relative
}

body ul {
    padding: 0;
    list-style-position: inside;
    list-style: none
}

@media (max-width: 1920px) {
    body ul {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body ul {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body ul {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body ul {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    body ul {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    body ul {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body ul {
        margin-bottom: 20px
    }
}

body ul li {
    position: relative;
    margin-bottom: 0;
    /* padding-left: 20px */
}

body ul li::after {
    /* content: ''; */
    position: absolute;
    left: 0;
    /* top: 7px; */
    /* width: 8px; */
    /* height: 8px; */
    border-radius: 50%;
    background: #777
}

body ul li:last-child p {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    body ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    body ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    body ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    body ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    body ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    body ul li p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    body ul li p {
        margin-bottom: 5px
    }
}

body ul li ul {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    body ul li ul li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    body ul li ul li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    body ul li ul li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    body ul li ul li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    body ul li ul li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    body ul li ul li:last-child p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    body ul li ul li:last-child p {
        margin-bottom: 5px
    }
}

body ol {
    padding: 0;
    list-style-position: inside;
    counter-reset: myCounter
}

@media (max-width: 1920px) {
    body ol {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    body ol {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    body ol {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    body ol {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    body ol {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    body ol {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    body ol {
        margin-bottom: 20px
    }
}

body ol li {
    position: relative;
    margin-bottom: 0;
    list-style: none
}

body ol li::before {
    counter-increment: myCounter;
    content: counter(myCounter) ". ";
    position: relative;
    left: 0;
    top: 0;
    float: left;
    margin-right: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #777
}

body ol li:last-child p {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    body ol li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    body ol li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    body ol li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    body ol li p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    body ol li p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    body ol li p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    body ol li p {
        margin-bottom: 5px
    }
}

body ol li ol {
    margin-bottom: 0;
    padding-left: 20px;
    counter-reset: myCounter2
}

body ol li ol li::before {
    counter-increment: myCounter2;
    content: counter(myCounter) "." counter(myCounter2) ". "
}

@media (max-width: 1920px) {
    body ol li ol li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    body ol li ol li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    body ol li ol li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    body ol li ol li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    body ol li ol li:last-child p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    body ol li ol li:last-child p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    body ol li ol li:last-child p {
        margin-bottom: 5px
    }
}

body .moving-element {
    transition: all .3s;
    transition-timing-function: linear
}

@media (max-width: 767px) {
    body .moving-element {
        display: none
    }
}

.button,
button,
.btn {
    position: relative;
    z-index: 5;
    overflow: hidden;
    min-width: 165px;
    padding: 9px 9px 9px 9px;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    font-family: "Baloo", cursive;
    font-size: 20px;
    text-align: center;
    outline: none !important;
    color: #fff
}

@media (max-width: 1920px) {
    .button,
    button,
    .btn {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .button,
    button,
    .btn {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .button,
    button,
    .btn {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .button,
    button,
    .btn {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .button,
    button,
    .btn {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .button,
    button,
    .btn {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .button,
    button,
    .btn {
        margin-bottom: 15px
    }
}

.button::after,
button::after,
.btn::after {
    
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    width: 40px;
    height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-right: 5px;
    background: #e07b21;
    font-family: 'Material Design Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 30px;
    transition: all .4s
}

.button::before,
button::before,
.btn::before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #ff8b00
}

.button:hover,
button:hover,
.btn:hover {
    color: #fff;
    text-decoration: none
}

.button:hover:after,
button:hover:after,
.btn:hover:after {
    width: 100%;
    border-radius: 10px
}

.button:focus,
button:focus,
.btn:focus {
    color: #fff
}

.button.color-1::before,
button.color-1::before,
.btn.color-1::before {
    background: #5255c5
}

.button.color-1::after,
button.color-1::after,
.btn.color-1::after {
    background: #474aab
}

.button.color-2::before,
button.color-2::before,
.btn.color-2::before {
    background: #5dba3b
}

.button.color-2::after,
button.color-2::after,
.btn.color-2::after {
    background: #50a033
}

.button.color-3::before,
button.color-3::before,
.btn.color-3::before {
    background: #ff8b00
}

.button.color-3::after,
button.color-3::after,
.btn.color-3::after {
    background: #e07b21
}

.button.color-4::before,
button.color-4::before,
.btn.color-4::before {
    background: #ff5157
}

.button.color-4::after,
button.color-4::after,
.btn.color-4::after {
    background: #e04b4e
}

.button.color-5::before,
button.color-5::before,
.btn.color-5::before {
    background: #ffc000
}

.button.color-5::after,
button.color-5::after,
.btn.color-5::after {
    background: #e5ad00
}

.read-more {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
    text-decoration: none !important;
    color: #090605;
    transition: all .2s
}

@media (max-width: 1920px) {
    .read-more {
        margin-top: 20px
    }
}

@media (max-width: 1600px) {
    .read-more {
        margin-top: 20px
    }
}

@media (max-width: 1440px) {
    .read-more {
        margin-top: 20px
    }
}

@media (max-width: 1199px) {
    .read-more {
        margin-top: 20px
    }
}

@media (max-width: 991px) {
    .read-more {
        margin-top: 20px
    }
}

@media (max-width: 767px) {
    .read-more {
        margin-top: 15px
    }
}

@media (max-width: 480px) {
    .read-more {
        margin-top: 15px
    }
}

.read-more:hover {
    color: #5255c5
}

.read-more i {
    margin-bottom: -2px;
    transition: transform .2s
}

.slick-dots {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0
}

.slick-dots li {
    margin-right: 10px;
    padding-left: 0
}

@media (max-width: 1920px) {
    .slick-dots li {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .slick-dots li {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .slick-dots li {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .slick-dots li {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .slick-dots li {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .slick-dots li {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .slick-dots li {
        padding-top: 30px
    }
}

.slick-dots li:last-child {
    margin-right: 0
}

.slick-dots li::after,
.slick-dots li::before {
    display: none
}

.slick-dots li.slick-active button {
    width: 20px;
    background: #5255c5
}

.slick-dots li button {
    display: block;
    width: 10px;
    min-width: 0;
    height: 10px;
    margin-bottom: 0;
    padding: 0;
    border-radius: 10px;
    background: #d1d1d1;
    font-size: 0;
    line-height: 0;
    transition: all .3s
}

.slick-dots li button::after,
.slick-dots li button::before {
    display: none
}

.slick-dots li button:hover {
    background: #474aab
}

@media (max-width: 480px) {
    .slick-dots li.slick-active button {
        width: 30px
    }
    .slick-dots li button {
        width: 15px;
        height: 15px;
        border-radius: 15px
    }
}

.color-1 .slick-dots li.slick-active button {
    background: #5255c5
}

.color-1 .slick-dots li button:hover {
    background: #474aab
}

.color-2 .slick-dots li.slick-active button {
    background: #5dba3b
}

.color-2 .slick-dots li button:hover {
    background: #50a033
}

.color-3 .slick-dots li.slick-active button {
    background: #ff8b00
}

.color-3 .slick-dots li button:hover {
    background: #e07b21
}

.color-4 .slick-dots li.slick-active button {
    background: #ff5157
}

.color-4 .slick-dots li button:hover {
    background: #e04b4e
}

.color-5 .slick-dots li.slick-active button {
    background: #ffc000
}

.color-5 .slick-dots li button:hover {
    background: #e5ad00
}

.color-1 .slider-arrow,
.color-1 .slick-arrow {
    background: #5255c5
}

.color-1 .slider-arrow:hover,
.color-1 .slick-arrow:hover {
    background: #474aab
}

.color-2 .slider-arrow,
.color-2 .slick-arrow {
    background: #5dba3b
}

.color-2 .slider-arrow:hover,
.color-2 .slick-arrow:hover {
    background: #50a033
}

.color-3 .slider-arrow,
.color-3 .slick-arrow {
    background: #ff8b00
}

.color-3 .slider-arrow:hover,
.color-3 .slick-arrow:hover {
    background: #e07b21
}

.color-4 .slider-arrow,
.color-4 .slick-arrow {
    background: #ff5157
}

.color-4 .slider-arrow:hover,
.color-4 .slick-arrow:hover {
    background: #e04b4e
}

.color-5 .slider-arrow,
.color-5 .slick-arrow {
    background: #ffc000
}

.color-5 .slider-arrow:hover,
.color-5 .slick-arrow:hover {
    background: #e5ad00
}

.slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 100;
    width: 60px;
    height: 60px;
    min-width: 0;
    margin-bottom: 0;
    padding: 0;
    border-radius: 50%;
    background: #ff5157;
    font-size: 0;
    line-height: 0;
    text-align: center;
    color: #fff;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .3s
}

.slick-arrow::after {
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    padding-right: 0;
    background-color: transparent;
    text-align: center;
    font-size: 60px;
    line-height: 60px
}

.slick-arrow::before {
    display: none
}

.slick-arrow:focus {
    color: #fff
}

.slick-arrow.slick-next {
    right: 0
}

.slick-arrow.slick-prev {
    left: 0;
    -ms-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%)
}

.slider-arrow {
    position: absolute;
    top: 50%;
    z-index: 100;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ff5157;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    color: #fff;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all .3s
}

.slider-arrow.arrow-left {
    left: 0
}

.slider-arrow.arrow-right {
    right: 0
}

.slider-arrow:hover {
    background: #e04b4e;
    color: #fff
}

@media (max-width: 480px) {
    .slider-arrow {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-indent: -2px
    }
}

@media (max-width: 1920px) {
    .enroll-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .enroll-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .enroll-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .enroll-form {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .enroll-form {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .enroll-form {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .enroll-form {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .enroll-form h3,
    .enroll-form .h3 {
        margin-bottom: 15px
    }
}

.enroll-form .inner-enroll-form {
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px
}

.enroll-form .inner-enroll-form.column-2 .input-wrap {
    width: 50%
}

.enroll-form .inner-enroll-form.column-3 .input-wrap {
    width: 33.33%
}

.enroll-form .inner-enroll-form .input-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 1920px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .enroll-form .inner-enroll-form .input-wrap {
        margin-bottom: 15px
    }
}

.enroll-form .inner-enroll-form .input-wrap input {
    margin-bottom: 0
}

.enroll-form textarea {
    height: 135px
}

@media (max-width: 1920px) {
    .enroll-form textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .enroll-form textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .enroll-form textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .enroll-form textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .enroll-form textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .enroll-form textarea {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .enroll-form textarea {
        margin-bottom: 15px
    }
}

.enroll-form .button-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.enroll-form button[type='submit'] {
    margin-top: 0
}

@media (max-width: 991px) {
    .enroll-form h3,
    .enroll-form .h3 {
        text-align: center
    }
    .enroll-form .button-wrap {
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (max-width: 480px) {
    .enroll-form .inner-enroll-form {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .enroll-form .inner-enroll-form.column-2 .input-wrap {
        width: 100%
    }
    .enroll-form .inner-enroll-form.column-3 .input-wrap {
        width: 100%
    }
    .enroll-form .button-wrap {
        -ms-flex-pack: center;
        justify-content: center
    }
}

.sort-form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .sort-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .sort-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .sort-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .sort-form {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .sort-form {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .sort-form {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .sort-form {
        margin-bottom: 30px
    }
}

.sort-form:-ms-input-placeholder {
    color: #a5aaa6;
    opacity: 1
}

.sort-form::placeholder {
    color: #a5aaa6;
    opacity: 1
}

.sort-form:-ms-input-placeholder {
    color: #a5aaa6
}

.sort-form::-ms-input-placeholder {
    color: #a5aaa6
}

.sort-form .input-wrap {
    padding: 0 15px;
    -ms-flex-positive: 1;
    flex-grow: 1
}

@media (max-width: 1920px) {
    .sort-form .input-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 1600px) {
    .sort-form .input-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 1440px) {
    .sort-form .input-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 1199px) {
    .sort-form .input-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 991px) {
    .sort-form .input-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 767px) {
    .sort-form .input-wrap {
        margin-bottom: -15px
    }
}

@media (max-width: 480px) {
    .sort-form .input-wrap {
        margin-bottom: -15px
    }
}

.sort-form .input-wrap input {
    margin-bottom: 0;
    padding: 11px 20px;
    border: none;
    background: #f1f1f9;
    color: #a5aaa6
}

@media (max-width: 1920px) {
    .sort-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .sort-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .sort-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .sort-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .sort-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .sort-form .input-wrap input {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .sort-form .input-wrap input {
        margin-bottom: 15px
    }
}

@media (max-width: 1920px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 15px
    }
}

.sort-form button[type='submit'] {
    margin-top: 0;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0
}

.sort-form .select2-container--default .select2-selection--single {
    height: auto;
    max-width: 260px;
    padding: 12px 20px;
    border: none;
    background: #f1f1f9;
    color: #a5aaa6
}

.sort-form .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 1;
    color: #a5aaa6
}

.sort-form .select2-container--default .select2-selection--single .select2-selection__rendered i {
    margin-right: 5px
}

@media (max-width: 991px) {
    .sort-form {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: end;
        align-items: flex-end;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto
    }
    .sort-form .input-wrap {
        width: 50%;
        margin-bottom: 0;
        padding-left: 15px;
        padding-right: 15px;
        max-width: 280px
    }
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 0
    }
    .sort-form .select2-container--default .select2-selection--single {
        max-width: none
    }
    .sort-form button[type='submit'] {
        margin-right: 15px;
        margin-left: 15px
    }
}

@media (max-width: 767px) {
    .sort-form {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .sort-form .input-wrap {
        width: 100%;
        max-width: none;
        padding-left: 0;
        padding-right: 0
    }
}

@media (max-width: 767px) and (max-width: 1920px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1600px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1440px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1199px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 991px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) and (max-width: 480px) {
    .sort-form .input-wrap select,
    .sort-form .input-wrap .select2 {
        margin-bottom: 15px
    }
}

.footer-subscribe {
    display: -ms-flexbox;
    display: flex
}

.footer-subscribe:-ms-input-placeholder {
    color: #eef8eb;
    opacity: 1
}

.footer-subscribe::placeholder {
    color: #eef8eb;
    opacity: 1
}

.footer-subscribe:-ms-input-placeholder {
    color: #eef8eb
}

.footer-subscribe::-ms-input-placeholder {
    color: #eef8eb
}

.footer-subscribe button[type='submit'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 50px;
    min-width: 50px;
    margin-top: 0;
    padding: 9px 0;
    border-radius: 0 10px 10px 0;
    background: #fff;
    transition: all .2s
}

.footer-subscribe button[type='submit']::after {
    display: none
}

.footer-subscribe button[type='submit']::before {
    display: none
}

.footer-subscribe button[type='submit']:hover {
    background: #f1f1f9
}

.footer-subscribe button[type='submit']:hover i {
    color: #ff8b00
}

.footer-subscribe button[type='submit'] i {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 32px;
    line-height: 1em;
    color: #5dba3b;
    transition: all .2s
}

.footer-subscribe input {
    margin-bottom: 0;
    border-radius: 0;
    border-radius: 10px 0 0 10px;
    border: 1px solid #fff;
    border-right: none;
    background: rgba(255, 255, 255, 0.25);
    color: #fff
}

@media (max-width: 1920px) {
    .comment-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .comment-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .comment-form {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .comment-form {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .comment-form {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .comment-form {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .comment-form {
        margin-bottom: 30px
    }
}

.comment-form .inner-comment-form {
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px
}

.comment-form .inner-comment-form .input-wrap {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 1920px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .comment-form .inner-comment-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 1920px) {
    .comment-form textarea {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .comment-form textarea {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .comment-form textarea {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .comment-form textarea {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .comment-form textarea {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .comment-form textarea {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .comment-form textarea {
        margin-bottom: 20px
    }
}

.comment-form button[type='submit'] {
    margin-top: 0
}

@media (max-width: 480px) {
    .comment-form .inner-comment-form {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .comment-form .inner-comment-form .input-wrap {
        width: 100%
    }
}

.latest-post {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.latest-post .latest-logo {
    overflow: hidden;
    min-width: 50px;
    margin-right: 10px;
    border-radius: 5px;
    transition: all .3s
}

.latest-post .latest-logo:hover {
    border-radius: 50%
}

.latest-post .latest-desk a {
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: #090605;
    transition: all .2s
}

@media (max-width: 1920px) {
    .latest-post .latest-desk a {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .latest-post .latest-desk a {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .latest-post .latest-desk a {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .latest-post .latest-desk a {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .latest-post .latest-desk a {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .latest-post .latest-desk a {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .latest-post .latest-desk a {
        margin-bottom: 5px
    }
}

.latest-post .latest-desk a:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    .progress-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .progress-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .progress-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .progress-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .progress-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .progress-wrap {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .progress-wrap {
        margin-bottom: 15px
    }
}

.progress-wrap:nth-child(4n+1) .progress-line-wrap .progress-line {
    background: #5dba3b
}

.progress-wrap:nth-child(4n+2) .progress-line-wrap .progress-line {
    background: #ff8b00
}

.progress-wrap:nth-child(4n+3) .progress-line-wrap .progress-line {
    background: #ff5157
}

.progress-wrap:nth-child(4n+4) .progress-line-wrap .progress-line {
    background: #ffc000
}

.progress-wrap .progress-head {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #090605
}

@media (max-width: 1920px) {
    .progress-wrap .progress-head {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .progress-wrap .progress-head {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .progress-wrap .progress-head {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .progress-wrap .progress-head {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .progress-wrap .progress-head {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .progress-wrap .progress-head {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .progress-wrap .progress-head {
        margin-bottom: 5px
    }
}

.progress-wrap .progress-head span {
    color: #777
}

.progress-wrap .progress-line-wrap {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 25px;
    border-radius: 10px;
    background: #f1f1f9
}

.progress-wrap .progress-line-wrap .progress-line {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0%;
    border-radius: 10px;
    background: #5dba3b;
    transition: all 1.5s;
    transition-timing-function: cubic-bezier(0.105, 0.01, 0.54, 1.255)
}

.progress-wrap .progress-line-wrap .progress-line.color-1 {
    background: #5dba3b
}

.progress-wrap .progress-line-wrap .progress-line.color-2 {
    background: #ff8b00
}

.progress-wrap .progress-line-wrap .progress-line.color-3 {
    background: #ff5157
}

.progress-wrap .progress-line-wrap .progress-line.color-4 {
    background: #ffc000
}

.bread-crumbs {
    margin-bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center
}

.bread-crumbs li {
    margin-right: 20px;
    padding-left: 0
}

.bread-crumbs li::after {
    content: '/';
    top: 0;
    right: -12px;
    left: auto;
    width: auto;
    height: auto;
    border-radius: 0;
    background-color: transparent;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #090605
}

.bread-crumbs li:last-child {
    margin-right: 0
}

.bread-crumbs li:last-child::after {
    display: none
}

.bread-crumbs li a,
.bread-crumbs li p {
    margin-bottom: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.4em;
    color: #090605
}

.moving-element {
    position: absolute
}

@media (max-width: 991px) {
    .moving-element {
        -ms-transform: translate(0, 0) !important;
        transform: translate(0, 0) !important
    }
}

.main-title {
    text-align: center
}

@media (max-width: 1920px) {
    .main-title {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .main-title {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .main-title {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .main-title {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .main-title {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .main-title {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .main-title {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .main-title h2,
    .main-title .h2 {
        margin-bottom: 20px
    }
}

.main-title p {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0
}

@media (max-width: 480px) {
    .main-title p {
        text-align: justify
    }
}

.my-pagination {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .my-pagination {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .my-pagination {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .my-pagination {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .my-pagination {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .my-pagination {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .my-pagination {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .my-pagination {
        padding-top: 30px
    }
}

.my-pagination li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 0;
    padding: 0
}

.my-pagination li::after {
    display: none
}

.my-pagination li:first-child a {
    padding: 0 10px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    border-radius: 15px 0 0 15px
}

.my-pagination li:first-child a:hover {
    color: #5255c5
}

.my-pagination li:first-child a.active-pagination {
    color: #5255c5
}

.my-pagination li:last-child {
    margin-right: 0
}

.my-pagination li:last-child a {
    padding: 0 10px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0 15px 15px 0
}

.my-pagination li:last-child a:hover {
    color: #5255c5
}

.my-pagination li:last-child a.active-pagination {
    color: #5255c5
}

.my-pagination li:nth-child(4n+2) a:hover {
    color: #5255c5
}

.my-pagination li:nth-child(4n+2) a.active-pagination {
    color: #5255c5
}

.my-pagination li:nth-child(4n+3) a:hover {
    color: #5dba3b
}

.my-pagination li:nth-child(4n+3) a.active-pagination {
    color: #5dba3b
}

.my-pagination li:nth-child(4n+4) a:hover {
    color: #ff8b00
}

.my-pagination li:nth-child(4n+4) a.active-pagination {
    color: #ff8b00
}

.my-pagination li:nth-child(4n+5) a:hover {
    color: #ff5157
}

.my-pagination li:nth-child(4n+5) a.active-pagination {
    color: #ff5157
}

.my-pagination li a {
    height: 35px;
    padding: 0 7px;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    text-decoration: none;
    color: #090605;
    transition: all .2s
}

.my-pagination li a i {
    font-size: 30px
}

.my-pagination li a:hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
    color: #5255c5
}

.my-pagination li a.active-pagination {
    color: #5255c5
}

.select2-results__options {
    margin-bottom: 0
}

.select2-results__options li {
    padding-left: 10px;
    padding-right: 10px
}

.select2-results__options li::after {
    display: none
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    margin-bottom: 0;
    padding-left: 15px
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single .select2-selection__arrow,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple .select2-selection__arrow {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.select2-container--default .select2-selection--single {
    display: block;
    width: 100% !important;
    height: 30px;
    margin-bottom: 0;
    padding-left: 15px;
    padding-right: 20px;
    border: 1px solid #aeaeae;
    border-radius: 10px;
    font-size: 15px;
    line-height: 15px;
    outline: none !important
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 0;
    padding-right: 5px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #777
}

.select2.select2-container.select2-container--default {
    width: 100% !important
}

.select2-container {
    display: block
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    font-size: 15px;
    transition: all .2s
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 30px
}

.select2-dropdown {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25)
}

.select2-results__options li[aria-disabled="true"] {
    display: none
}

.select2-search--dropdown {
    display: none
}

.select2-results__option i {
    margin-right: 10px
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    display: inline-block;
    border-radius: 0
}

.gm-style .gm-control-active.gm-fullscreen-control {
    display: none
}

.gm-style .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom .gmnoprint {
    display: none
}

.gm-style .gmnoprint {
    display: none
}

.gm-style .gm-svpc {
    display: none
}

.featherlight-content iframe {
    max-width: 100%
}

.featherlight.featherlight-iframe .featherlight-content {
    padding: 0;
    border-bottom: none
}

.blog-mini-info {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 10;
    overflow: hidden;
    height: 70px;
    width: 70px;
    padding: 15px 10px;
    border-radius: 35px;
    background: #5255c5;
    transition: all .3s
}

.blog-mini-info:hover {
    height: 140px
}

.blog-mini-info h5,
.blog-mini-info .h5 {
    text-align: center;
    color: #fff;
    margin-bottom: 15px
}

.blog-mini-info h6,
.blog-mini-info .h6 {
    text-align: center;
    color: #fff;
    margin-bottom: 15px
}

.blog-mini-info h6 p,
.blog-mini-info .h6 p {
    color: #fff
}

.blog-mini-info .coment-info h6,
.blog-mini-info .coment-info .h6 {
    margin-bottom: 0
}

.blog-info-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0
}

.blog-info-list li {
    margin-right: 20px;
    padding-left: 0
}

@media (max-width: 1920px) {
    .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .blog-info-list li {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .blog-info-list li {
        margin-bottom: 10px
    }
}

.blog-info-list li::after {
    display: none
}

.blog-info-list li p,
.blog-info-list li a {
    margin-bottom: 0;
    color: #777;
    text-decoration: none
}

.blog-info-list li p i,
.blog-info-list li a i {
    margin-right: 10px;
    font-size: 16px;
    color: #5255c5
}

.blog-info-list li a:hover {
    color: #5255c5
}

.color-1 .blog-info-list li p i,
.color-1 .blog-info-list li a i {
    color: #5255c5
}

.color-1 .blog-info-list li a:hover {
    color: #5255c5
}

.color-2 .blog-info-list li p i,
.color-2 .blog-info-list li a i {
    color: #5dba3b
}

.color-2 .blog-info-list li a:hover {
    color: #5dba3b
}

.color-3 .blog-info-list li p i,
.color-3 .blog-info-list li a i {
    color: #ff8b00
}

.color-3 .blog-info-list li a:hover {
    color: #ff8b00
}

.color-4 .blog-info-list li p i,
.color-4 .blog-info-list li a i {
    color: #ff5157
}

.color-4 .blog-info-list li a:hover {
    color: #ff5157
}

.color-5 .blog-info-list li p i,
.color-5 .blog-info-list li a i {
    color: #ffc000
}

.color-5 .blog-info-list li a:hover {
    color: #ffc000
}

.sidebar-search {
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1920px) {
    .sidebar-search {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .sidebar-search {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .sidebar-search {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .sidebar-search {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .sidebar-search {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .sidebar-search {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .sidebar-search {
        margin-bottom: 20px
    }
}

.sidebar-search input {
    margin-bottom: 0;
    border-radius: 15px 0 0 15px;
    border: 2px solid #d8d8d8;
    border-right: none
}

.sidebar-search button[type='submit'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 0;
    margin-top: 0;
    padding: 8px 15px;
    border-radius: 0 15px 15px 0;
    transition: all .2s
}

.sidebar-search button[type='submit']::after {
    display: none
}

.sidebar-search button[type='submit']::before {
    background: #ff5157;
    transition: all .2s
}

.sidebar-search button[type='submit']:hover::before {
    background: #e04b4e
}

.sidebar-search button[type='submit'].color-1::before {
    background: #ff8b00
}

.sidebar-search button[type='submit'].color-1:hover::before {
    background: #e07b21
}

.sidebar-search button[type='submit'].color-2::before {
    background: #ff5157
}

.sidebar-search button[type='submit'].color-2:hover::before {
    background: #e04b4e
}

.sidebar-search button[type='submit'].color-3::before {
    background: #5dba3b
}

.sidebar-search button[type='submit'].color-3:hover::before {
    background: #50a033
}

.sidebar-search button[type='submit'].color-4::before {
    background: #5255c5
}

.sidebar-search button[type='submit'].color-4:hover::before {
    background: #474aab
}

.sidebar-search button[type='submit']:active i {
    -ms-transform: translateY(1px);
    transform: translateY(1px)
}

.sidebar-search button[type='submit'] i {
    font-size: 25px
}

.fade-bg {
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: transparent;
    transition: all .3s
}

.fade-bg.fade-true {
    visibility: visible;
    background: rgba(0, 0, 0, 0.4)
}

.humburger {
    position: relative;
    display: none;
    width: 50px;
    height: 24px;
    margin-left: 15px;
    cursor: pointer
}

.humburger:before,
.humburger:after,
.humburger span {
    content: '';
    position: absolute;
    left: 0;
    height: 2px;
    width: 35px;
    border-radius: 1px;
    background: #5255c5;
    transition: all .4s
}

.humburger:before {
    top: 0
}

.humburger:after {
    bottom: 0
}

.humburger span {
    position: absolute;
    top: 50%;
    margin-top: -1px
}

.humburger.active_humburger span {
    opacity: 0
}

.humburger.active_humburger:before {
    top: 50%;
    margin-top: -1px;
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.humburger.active_humburger:after {
    bottom: 50%;
    margin-bottom: -1px;
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.humburger.desctop-humburger {
    display: block
}

.humburger.desctop-humburger:before,
.humburger.desctop-humburger:after,
.humburger.desctop-humburger span {
    height: 4px;
    background: #5dba3b
}

.humburger.desctop-humburger span {
    margin-top: -2px
}

@media (max-width: 991px) {
    .humburger {
        display: block
    }
    .humburger.desctop-humburger {
        display: none
    }
}

p.back-top {
    position: fixed;
    z-index: 99;
    opacity: .8;
    right: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 50%;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.62);
    background: #5255c5;
    cursor: pointer;
    transition: all .3s;
    -ms-transform: scale(1) translateY(300px);
    transform: scale(1) translateY(300px)
}

p.back-top:hover {
    background: #474aab
}

p.back-top.show_btn {
    -ms-transform: scale(1) translateY(0px);
    transform: scale(1) translateY(0px)
}

p.back-top.color-1 {
    background: #5255c5
}

p.back-top.color-1:hover {
    background: #474aab
}

p.back-top.color-2 {
    background: #5dba3b
}

p.back-top.color-2:hover {
    background: #50a033
}

p.back-top.color-3 {
    background: #ff8b00
}

p.back-top.color-3:hover {
    background: #e07b21
}

p.back-top.color-4 {
    background: #ff5157
}

p.back-top.color-4:hover {
    background: #e04b4e
}

p.back-top.color-5 {
    background: #ffc000
}

p.back-top.color-5:hover {
    background: #e5ad00
}

p.back-top:hover {
    opacity: 1;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.62)
}

p.back-top:active {
    box-shadow: inset 0px 2px 5px 0px rgba(0, 0, 0, 0.62)
}

p.back-top i {
    font-size: 28px;
    color: #fff
}

@media (max-width: 767px) {
    p.back-top {
        margin-right: 40px;
        margin-bottom: 40px
    }
}

@media (max-width: 480px) {
    p.back-top {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        margin-bottom: 20px
    }
}

header .header-top-info {
    background: #3F204A;
    padding-top: 10px;
    padding-bottom: 10px
}

header .header-top-info .top-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

header .header-top-info .top-info p {
    margin-bottom: 0;
    color: #fff
}

header .header-top-info .top-info .soc-list li a {
    font-size: 17px
}

header .header-top-info .top-info .contact-list li {
    margin-right: 15px
}

header .header-top-info .top-info .contact-list li i {
    font-size: 20px;
    color: #fff
}

header .header-top-info .top-info .contact-list li a {
    color: #fff
}

header .header-top-info .top-info .contact-list li a:hover {
    color: #5255c5
}

header .main-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1920px) {
    header .main-header {
        padding-top: 20px
    }
}

@media (max-width: 1600px) {
    header .main-header {
        padding-top: 20px
    }
}

@media (max-width: 1440px) {
    header .main-header {
        padding-top: 20px
    }
}

@media (max-width: 1199px) {
    header .main-header {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    header .main-header {
        padding-top: 20px
    }
}

@media (max-width: 767px) {
    header .main-header {
        padding-top: 15px
    }
}

@media (max-width: 480px) {
    header .main-header {
        padding-top: 15px
    }
}

@media (max-width: 1920px) {
    header .main-header {
        padding-bottom: 20px
    }
}

@media (max-width: 1600px) {
    header .main-header {
        padding-bottom: 20px
    }
}

@media (max-width: 1440px) {
    header .main-header {
        padding-bottom: 20px
    }
}

@media (max-width: 1199px) {
    header .main-header {
        padding-bottom: 20px
    }
}

@media (max-width: 991px) {
    header .main-header {
        padding-bottom: 20px
    }
}

@media (max-width: 767px) {
    header .main-header {
        padding-bottom: 15px
    }
}

@media (max-width: 480px) {
    header .main-header {
        padding-bottom: 15px
    }
}

header .main-header .main-header-info .show-search,
header .main-header .main-header-info .humburger {
    display: none
}

header .main-header .main-header-info .logo img {
    transition: all .2s
}

header .main-header .main-header-info .logo img:hover {
    filter: hue-rotate(180deg)
}

@media (max-width: 991px) {
    header .main-header .main-header-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        width: 100%
    }
    header .main-header .main-header-info .show-search,
    header .main-header .main-header-info .humburger {
        display: block
    }
    header .main-header .main-header-info .show-search {
        margin-right: 15px;
        font-size: 28px;
        color: #5255c5
    }
}

header .main-header .inner-main-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

header .main-header .inner-main-header .sidebar-search {
    margin-bottom: 0;
    max-width: 165px
}

header .main-header .inner-main-header .sidebar-search button[type='submit'] {
    padding: 2px 8px;
    border-radius: 0 10px 10px 0
}

header .main-header .inner-main-header .sidebar-search button[type='submit'] i {
    font-size: 20px;
    height: 22px
}

header .main-header .inner-main-header .sidebar-search input {
    width: 135px;
    border-radius: 10px 0 0 10px
}

header .main-header .inner-main-header .contact-list li:last-child {
    margin-right: 30px
}

@media (max-width: 1199px) {
    header .main-header {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    header .main-header .main-header-info {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    header .main-header .main-header-info {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    header .main-header .main-header-info {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    header .main-header .main-header-info {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    header .main-header .main-header-info {
        margin-bottom: 25px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    header .main-header .main-header-info {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    header .main-header .main-header-info {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    header .main-header .sidebar-search {
        display: none
    }
}

@media (max-width: 767px) {
    header .main-header .main-header-info {
        margin-bottom: 0
    }
    header .main-header .contact-list {
        -ms-flex-direction: column;
        flex-direction: column;
        display: none
    }
    header .main-header .contact-list li {
        margin-right: 0;
        margin-bottom: 20px
    }
    header .main-header .contact-list li:last-child {
        margin-bottom: 0
    }
}

@media (max-width: 991px) {
    header {
        overflow-x: hidden
    }
}

@media (max-width: 480px) {
    header .header-top-info .top-info {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around
    }
}

.header-nav {
    position: relative;
    z-index: 100;
    margin-bottom: 0;
    border-top: 1px solid #ececec
}

.header-nav .close-menu {
    display: none
}

.header-nav .header-nav-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0
}

.header-nav .header-nav-list>li:nth-child(5n+1)::before {
    background: #5255c5
}

.header-nav .header-nav-list>li:nth-child(5n+1) a:hover,
.header-nav .header-nav-list>li:nth-child(5n+1) a.active,
.header-nav .header-nav-list>li:nth-child(5n+1) p:hover,
.header-nav .header-nav-list>li:nth-child(5n+1) p.active {
    color: #5255c5
}

.header-nav .header-nav-list>li:nth-child(5n+1) .hide-lvl a:hover,
.header-nav .header-nav-list>li:nth-child(5n+1) .hide-lvl p:hover {
    background: #5255c5;
    color: #fff
}

.header-nav .header-nav-list>li:nth-child(5n+1) .wrap-to-show i {
    color: #5255c5
}

.header-nav .header-nav-list>li:nth-child(5n+2)::before {
    background: #5dba3b
}

.header-nav .header-nav-list>li:nth-child(5n+2) a:hover,
.header-nav .header-nav-list>li:nth-child(5n+2) a.active,
.header-nav .header-nav-list>li:nth-child(5n+2) p:hover,
.header-nav .header-nav-list>li:nth-child(5n+2) p.active {
    color: #5dba3b
}

.header-nav .header-nav-list>li:nth-child(5n+2) .hide-lvl a:hover,
.header-nav .header-nav-list>li:nth-child(5n+2) .hide-lvl p:hover {
    background: #5dba3b;
    color: #fff
}

.header-nav .header-nav-list>li:nth-child(5n+2) .wrap-to-show i {
    color: #5dba3b
}

.header-nav .header-nav-list>li:nth-child(5n+3)::before {
    background: #ff8b00
}

.header-nav .header-nav-list>li:nth-child(5n+3) a:hover,
.header-nav .header-nav-list>li:nth-child(5n+3) a.active,
.header-nav .header-nav-list>li:nth-child(5n+3) p:hover,
.header-nav .header-nav-list>li:nth-child(5n+3) p.active {
    color: #ff8b00
}

.header-nav .header-nav-list>li:nth-child(5n+3) .hide-lvl a:hover,
.header-nav .header-nav-list>li:nth-child(5n+3) .hide-lvl p:hover {
    background: #ff8b00;
    color: #fff
}

.header-nav .header-nav-list>li:nth-child(5n+3) .wrap-to-show i {
    color: #ff8b00
}

.header-nav .header-nav-list>li:nth-child(5n+4)::before {
    background: #ff5157
}

.header-nav .header-nav-list>li:nth-child(5n+4) a:hover,
.header-nav .header-nav-list>li:nth-child(5n+4) a.active,
.header-nav .header-nav-list>li:nth-child(5n+4) p:hover,
.header-nav .header-nav-list>li:nth-child(5n+4) p.active {
    color: #ff5157
}

.header-nav .header-nav-list>li:nth-child(5n+4) .hide-lvl a:hover,
.header-nav .header-nav-list>li:nth-child(5n+4) .hide-lvl p:hover {
    background: #ff5157;
    color: #fff
}

.header-nav .header-nav-list>li:nth-child(5n+4) .wrap-to-show i {
    color: #ff5157
}

.header-nav .header-nav-list>li:nth-child(5n+5)::before {
    background: #ffc000
}

.header-nav .header-nav-list>li:nth-child(5n+5) a:hover,
.header-nav .header-nav-list>li:nth-child(5n+5) a.active,
.header-nav .header-nav-list>li:nth-child(5n+5) p:hover,
.header-nav .header-nav-list>li:nth-child(5n+5) p.active {
    color: #ffc000
}

.header-nav .header-nav-list>li:nth-child(5n+5) .hide-lvl a:hover,
.header-nav .header-nav-list>li:nth-child(5n+5) .hide-lvl p:hover {
    background: #ffc000;
    color: #fff
}

.header-nav .header-nav-list>li:nth-child(5n+5) .wrap-to-show i {
    color: #ffc000
}

.header-nav .header-nav-list li {
    position: relative;
    z-index: 100;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0
}

.header-nav .header-nav-list li::before {
    content: '';
    position: static;
    display: block;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #5255c5
}

.header-nav .header-nav-list li::after {
    display: none
}

.header-nav .header-nav-list li:hover>.hide-lvl {
    visibility: visible;
    opacity: 1;
    -ms-transform: translateY(0px);
    transform: translateY(0px)
}

.header-nav .header-nav-list li:hover>.hide-lvl li:hover>.hide-lvl {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

.header-nav .header-nav-list li:nth-child(n+4) .hide-lvl .hide-lvl {
    left: auto;
    right: 100%
}

.header-nav .header-nav-list li a,
.header-nav .header-nav-list li p {
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    font-family: "Baloo", cursive;
    font-size: 20px;
    line-height: 1.2em;
    text-decoration: none;
    color: #090605;
    cursor: pointer;
    transition: all .2s
}

@media (max-width: 1920px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 15px
    }
}

@media (max-width: 1600px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 15px
    }
}

@media (max-width: 1440px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 15px
    }
}

@media (max-width: 1199px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 15px
    }
}

@media (max-width: 991px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 15px
    }
}

@media (max-width: 767px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 10px
    }
}

@media (max-width: 480px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-top: 10px
    }
}

@media (max-width: 1920px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 15px
    }
}

@media (max-width: 767px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 10px
    }
}

@media (max-width: 480px) {
    .header-nav .header-nav-list li a,
    .header-nav .header-nav-list li p {
        padding-bottom: 10px
    }
}

.header-nav .header-nav-list li i {
    display: none
}

.header-nav .header-nav-list .hide-lvl {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 50;
    visibility: hidden;
    width: 270px;
    margin-bottom: 0;
    padding: 0;
    border: 1px solid #d8d8d8;
    border-top: none;
    border-radius: 0 0 15px 15px;
    background: #fff;
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transition: all .3s;
    -ms-transform: translateY(20px);
    transform: translateY(20px)
}

.header-nav .header-nav-list .hide-lvl>li {
    z-index: 100;
    background: #fff
}

.header-nav .header-nav-list .hide-lvl>li:last-child {
    border-radius: 0 0 15px 15px
}

.header-nav .header-nav-list .hide-lvl>li:last-child .wrap-to-show {
    border-radius: 0 0 15px 15px
}

.header-nav .header-nav-list .hide-lvl>li:last-child .hide-lvl {
    border-radius: 0 15px 15px 15px !important
}

.header-nav .header-nav-list .hide-lvl>li:last-child .hide-lvl li:last-child {
    overflow: hidden;
    border-radius: 0 0 15px 15px
}

.header-nav .header-nav-list .hide-lvl>li:last-child .hide-lvl li:last-child a {
    border-radius: 0 0 15px 15px
}

.header-nav .header-nav-list .hide-lvl a,
.header-nav .header-nav-list .hide-lvl p {
    display: block
}

.header-nav .header-nav-list .hide-lvl a:hover,
.header-nav .header-nav-list .hide-lvl p:hover {
    background: #5255c5;
    color: #fff
}

.header-nav .header-nav-list .hide-lvl li {
    border-top: 1px solid #ececec
}

.header-nav .header-nav-list .hide-lvl li:first-child {
    border-top: none
}

.header-nav .header-nav-list .hide-lvl li:last-child a,
.header-nav .header-nav-list .hide-lvl li:last-child p {
    border-radius: 0 0 15px 15px
}

.header-nav .header-nav-list .hide-lvl li::before {
    display: none
}

.header-nav .header-nav-list .hide-lvl .wrap-to-show {
    position: relative;
    z-index: 50;
    background: #fff
}

.header-nav .header-nav-list .hide-lvl .hide-lvl {
    z-index: -10;
    top: 0;
    left: 100%;
    border-radius: 0 15px 15px 0 !important;
    -ms-transform: translate(-200px, 0);
    transform: translate(-200px, 0);
    transition: all .3s;
    transition-timing-function: ease-out
}

.header-nav .header-nav-list .hide-lvl .hide-lvl li:first-child {
    border-radius: 0 15px 0 0
}

.header-nav .header-nav-list .hide-lvl .hide-lvl li:first-child a,
.header-nav .header-nav-list .hide-lvl .hide-lvl li:first-child p {
    border-radius: 0 15px 0 0
}

.header-nav .header-nav-list .hide-lvl .hide-lvl li:last-child {
    border-radius: 0 0 15px 0
}

.header-nav .header-nav-list .hide-lvl .hide-lvl li:last-child a,
.header-nav .header-nav-list .hide-lvl .hide-lvl li:last-child p {
    border-radius: 0 0 15px 0
}

.header-nav .header-nav-list .hide-lvl .hide-lvl li a,
.header-nav .header-nav-list .hide-lvl .hide-lvl li p {
    border-radius: 0
}

@media (max-width: 991px) {
    .header-nav {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
        width: 320px;
        height: 100%;
        height: 100vh;
        overflow-y: scroll;
        padding-top: 50px;
        border: none;
        background: #fff;
        -ms-transform: translateX(100%);
        transform: translateX(100%);
        transition: all .3s
    }
    .header-nav.show-header-nav {
        -ms-transform: translateX(0%);
        transform: translateX(0%)
    }
    .header-nav .container {
        width: 100%;
        padding: 0
    }
    .header-nav .container .row {
        margin: 0
    }
    .header-nav .close-menu {
        position: absolute;
        top: 10px;
        right: 5px;
        display: block
    }
    .header-nav .close-menu.show-mob-nav+.hide-lvl {
        display: -ms-flexbox;
        display: flex
    }
    .header-nav .close-menu i {
        font-size: 36px;
        color: #5255c5
    }
    .header-nav .header-nav-list {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .header-nav .header-nav-list li::before {
        height: 1px
    }
    .header-nav .header-nav-list li.drop-right .hide-lvl .hide-lvl {
        right: auto
    }
    .header-nav .header-nav-list li .wrap-to-show {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }
    .header-nav .header-nav-list li .wrap-to-show.show-mob-nav i {
        -ms-transform: rotate(180deg);
        transform: rotate(180deg)
    }
    .header-nav .header-nav-list li .wrap-to-show i {
        display: block;
        width: 40px;
        font-size: 20px;
        line-height: 1.2em;
        text-align: center;
        transition: all .3s
    }
}

@media (max-width: 991px) and (max-width: 1920px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 991px) and (max-width: 1600px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 991px) and (max-width: 1440px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 991px) and (max-width: 1199px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 10px
    }
}

@media (max-width: 991px) and (max-width: 480px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 10px
    }
}

@media (max-width: 991px) and (max-width: 1920px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) and (max-width: 1600px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) and (max-width: 1440px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) and (max-width: 1199px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) and (max-width: 480px) {
    .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .header-nav .header-nav-list li .wrap-to-show a,
    .header-nav .header-nav-list li .wrap-to-show p {
        width: 100%
    }
    .header-nav .header-nav-list li .hide-lvl {
        position: relative;
        display: none;
        width: 100%;
        border-radius: 0;
        border: none;
        border-top: 1px solid #ececec;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
        opacity: 1;
        visibility: visible;
        -ms-transform: translateY(0);
        transform: translateY(0);
        transition: all 0s
    }
    .header-nav .header-nav-list li .hide-lvl li:last-child a,
    .header-nav .header-nav-list li .hide-lvl li:last-child p {
        border-radius: 0
    }
    .header-nav .header-nav-list li .hide-lvl li a,
    .header-nav .header-nav-list li .hide-lvl li p {
        padding-left: 25px
    }
    .header-nav .header-nav-list li .hide-lvl .hide-lvl {
        border-radius: 0 !important;
        border-bottom: none;
        left: 0;
        right: auto;
        transition: all 0s;
        transition-timing-function: ease;
        -ms-transform: translate(0, 0);
        transform: translate(0, 0)
    }
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li {
        border-radius: 0
    }
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li:last-child a,
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li:last-child p {
        border-radius: 0
    }
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li:first-child a,
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li:first-child p {
        border-radius: 0
    }
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li a,
    .header-nav .header-nav-list li .hide-lvl .hide-lvl li p {
        padding-left: 40px
    }
}

.header-type-2 .container-fluid {
    max-width: 1600px
}

.header-type-2 .contact-list li {
    margin-right: 50px
}

.header-type-2 .main-header .inner-main-header .contact-list li:last-child {
    margin-right: 50px
}

@media (max-width: 1199px) {
    .header-type-3 .main-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column
    }
    .header-type-3 .main-header .main-header-info {
        -ms-flex-order: 0;
        order: 0
    }
    .header-type-3 .main-header .inner-main-header.left {
        -ms-flex-order: 1;
        order: 1;
        margin-bottom: 20px
    }
    .header-type-3 .main-header .inner-main-header.right {
        -ms-flex-order: 2;
        order: 2
    }
}

.header-type-4 .top-info .contact-list li {
    margin-right: 15px
}

.header-type-4 .top-info .contact-list li i {
    font-size: 20px;
    color: #fff
}

.header-type-4 .top-info .contact-list li a {
    color: #fff
}

.header-type-4 .top-info .show-search {
    font-size: 20px;
    color: #fff;
    cursor: pointer
}

.header-type-4 .main-header {
    -ms-flex-pack: center;
    justify-content: center
}

@media (max-width: 991px) {
    .header-type-4 .top-info {
        padding-top: 0;
        padding-bottom: 0
    }
    .header-type-4 .top-info .contact-list {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%
    }
    .header-type-4 .top-info .contact-list li {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .header-type-4 .top-info .show-search {
        display: none
    }
    .header-type-4 .main-header .main-header-info {
        margin-bottom: 0
    }
}

.header-type-5 .sidebar-search {
    margin-bottom: 0
}

.header-type-5 .header-nav {
    position: fixed;
    top: 0;
    right: auto;
    left: 0;
    z-index: 1000;
    width: 320px;
    height: 100%;
    height: 100vh;
    overflow-y: scroll;
    padding-top: 50px;
    border: none;
    background: #fff;
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: all .3s
}

.header-type-5 .header-nav.show-header-nav {
    -ms-transform: translateX(0%);
    transform: translateX(0%)
}

.header-type-5 .header-nav .container {
    width: 100%;
    padding: 0
}

.header-type-5 .header-nav .container .row {
    margin: 0
}

.header-type-5 .header-nav .close-menu {
    position: absolute;
    top: 10px;
    right: 5px;
    display: block;
    cursor: pointer
}

.header-type-5 .header-nav .close-menu.show-mob-nav+.hide-lvl {
    display: -ms-flexbox;
    display: flex
}

.header-type-5 .header-nav .close-menu i {
    font-size: 36px;
    color: #5255c5
}

.header-type-5 .header-nav .header-nav-list {
    -ms-flex-direction: column;
    flex-direction: column
}

.header-type-5 .header-nav .header-nav-list li::before {
    height: 1px
}

.header-type-5 .header-nav .header-nav-list li.drop-right .hide-lvl .hide-lvl {
    right: auto
}

.header-type-5 .header-nav .header-nav-list li .wrap-to-show {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.header-type-5 .header-nav .header-nav-list li .wrap-to-show.show-mob-nav i {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
    display: block;
    width: 40px;
    font-size: 20px;
    line-height: 1.2em;
    text-align: center;
    transition: all .3s;
    cursor: pointer
}

@media (max-width: 1920px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 1600px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 1440px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 1199px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 991px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 15px
    }
}

@media (max-width: 767px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 10px
    }
}

@media (max-width: 480px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-top: 10px
    }
}

@media (max-width: 1920px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 15px
    }
}

@media (max-width: 767px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 10px
    }
}

@media (max-width: 480px) {
    .header-type-5 .header-nav .header-nav-list li .wrap-to-show i {
        padding-bottom: 10px
    }
}

.header-type-5 .header-nav .header-nav-list li .wrap-to-show a,
.header-type-5 .header-nav .header-nav-list li .wrap-to-show p {
    width: 100%
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl {
    position: relative;
    display: none;
    width: 100%;
    border-radius: 0;
    border: none;
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    -ms-transform: translateY(0);
    transform: translateY(0);
    transition: all 0s
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl li:last-child a,
.header-type-5 .header-nav .header-nav-list li .hide-lvl li:last-child p {
    border-radius: 0
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl li a,
.header-type-5 .header-nav .header-nav-list li .hide-lvl li p {
    padding-left: 25px
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl {
    border-radius: 0;
    border-bottom: none;
    left: 0;
    right: auto
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl li:last-child a,
.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl li:last-child p {
    border-radius: 0
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl li:first-child a,
.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl li:first-child p {
    border-radius: 0
}

.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl li a,
.header-type-5 .header-nav .header-nav-list li .hide-lvl .hide-lvl li p {
    padding-left: 40px
}

@media (max-width: 1199px) {
    .header-type-5 .main-header {
        -ms-flex-direction: row;
        flex-direction: row
    }
    .header-type-5 .main-header .main-header-info {
        margin-bottom: 0
    }
}

@media (max-width: 767px) {
    .header-type-5 .header-top-info {
        padding-top: 0;
        padding-bottom: 0
    }
    .header-type-5 .header-top-info .top-info {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        justify-content: space-around
    }
    .header-type-5 .header-top-info .contact-list {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center
    }
    .header-type-5 .header-top-info .contact-list li {
        padding-top: 5px;
        padding-bottom: 5px
    }
    .header-type-5 .header-top-info .soc-list {
        padding-top: 5px;
        padding-bottom: 5px
    }
}

footer {
    background: url(../img/absurdity.png) repeat #5dba3b
}

@media (max-width: 1920px) {
    footer {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    footer {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    footer {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    footer {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    footer {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    footer {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    footer {
        padding-top: 30px
    }
}

footer h3,
footer .h3 {
    color: #fff
}

footer p,
footer a {
    color: #fff
}

footer a:hover {
    text-decoration: none;
    color: #5255c5
}

@media (max-width: 1920px) {
    footer .footer-top-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    footer .footer-top-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    footer .footer-top-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    footer .footer-top-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    footer .footer-top-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    footer .footer-top-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    footer .footer-top-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    footer .footer-top-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    footer .footer-top-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    footer .footer-top-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    footer .footer-top-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    footer .footer-top-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    footer .footer-top-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    footer .footer-top-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    footer .footer-tools {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    footer .footer-tools {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    footer .footer-tools {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    footer .footer-tools {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    footer .footer-tools {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    footer .footer-tools {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    footer .footer-tools {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    footer .footer-tools .footer-logo {
        margin-bottom: 20px
    }
}

footer .footer-tools p {
    color: #fff
}

@media (max-width: 1920px) {
    footer .footer-gallery {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    footer .footer-gallery {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    footer .footer-gallery {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    footer .footer-gallery {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    footer .footer-gallery {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    footer .footer-gallery {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    footer .footer-gallery {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    footer .footer-contact {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    footer .footer-contact {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    footer .footer-contact {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    footer .footer-contact {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    footer .footer-contact {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    footer .footer-contact {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    footer .footer-contact {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    footer .footer-contact p {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    footer .footer-contact p {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    footer .footer-contact p {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    footer .footer-contact p {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    footer .footer-contact p {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    footer .footer-contact p {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    footer .footer-contact p {
        margin-bottom: 15px
    }
}

footer .footer-contact ul {
    margin-bottom: 0
}

footer .footer-contact ul li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0
}

@media (max-width: 1920px) {
    footer .footer-contact ul li {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    footer .footer-contact ul li {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    footer .footer-contact ul li {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    footer .footer-contact ul li {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    footer .footer-contact ul li {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    footer .footer-contact ul li {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    footer .footer-contact ul li {
        margin-bottom: 10px
    }
}

footer .footer-contact ul li:last-child {
    margin-bottom: 0
}

footer .footer-contact ul li::after {
    display: none
}

footer .footer-contact ul li i {
    margin-right: 20px;
    font-size: 38px;
    color: #fff
}

footer .footer-contact ul li .footer-contact-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

footer .footer-contact ul li .footer-contact-text p {
    margin-bottom: 0;
    font-size: 15px
}

footer .footer-contact ul li .footer-contact-text a {
    margin-bottom: 5px;
    font-size: 15px;
    transition: all .2s
}

footer .footer-contact ul li .footer-contact-text a:last-child {
    margin-bottom: 0
}

footer .footer-contact ul li .footer-contact-text a:hover {
    color: #5255c5
}

footer .footer-bottom-content {
    border-top: 1px solid #fff;
    text-align: center
}

@media (max-width: 1920px) {
    footer .footer-bottom-content {
        padding-top: 15px
    }
}

@media (max-width: 1600px) {
    footer .footer-bottom-content {
        padding-top: 15px
    }
}

@media (max-width: 1440px) {
    footer .footer-bottom-content {
        padding-top: 15px
    }
}

@media (max-width: 1199px) {
    footer .footer-bottom-content {
        padding-top: 15px
    }
}

@media (max-width: 991px) {
    footer .footer-bottom-content {
        padding-top: 15px
    }
}

@media (max-width: 767px) {
    footer .footer-bottom-content {
        padding-top: 10px
    }
}

@media (max-width: 480px) {
    footer .footer-bottom-content {
        padding-top: 10px
    }
}

@media (max-width: 1920px) {
    footer .footer-bottom-content {
        padding-bottom: 15px
    }
}

@media (max-width: 1600px) {
    footer .footer-bottom-content {
        padding-bottom: 15px
    }
}

@media (max-width: 1440px) {
    footer .footer-bottom-content {
        padding-bottom: 15px
    }
}

@media (max-width: 1199px) {
    footer .footer-bottom-content {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) {
    footer .footer-bottom-content {
        padding-bottom: 15px
    }
}

@media (max-width: 767px) {
    footer .footer-bottom-content {
        padding-bottom: 10px
    }
}

@media (max-width: 480px) {
    footer .footer-bottom-content {
        padding-bottom: 10px
    }
}

footer .footer-bottom-content p {
    margin-bottom: 0;
    text-align: center
}

footer .footer-bottom-content a {
    font-size: 15px;
    transition: all .2s
}

footer .footer-bottom-content a:last-child {
    margin-bottom: 0
}

footer .footer-bottom-content a:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    footer .footer-map {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    footer .footer-map {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    footer .footer-map {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    footer .footer-map {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    footer .footer-map {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    footer .footer-map {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    footer .footer-map {
        padding-bottom: 30px
    }
}

footer .footer-map #footer-map {
    height: 290px
}

@media (max-width: 1199px) {
    footer .row-flex {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    footer .row-flex>div:nth-child(1) {
        -ms-flex-order: 0;
        order: 0
    }
    footer .row-flex>div:nth-child(2) {
        -ms-flex-order: 1;
        order: 1
    }
    footer .row-flex>div:nth-child(3) {
        -ms-flex-order: 2;
        order: 2
    }
    footer .footer-contact h3,
    footer .footer-contact .h3,
    footer .footer-contact p {
        width: 100%;
        text-align: center
    }
    footer .footer-contact ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center
    }
    footer .footer-contact ul li {
        margin-right: 25px
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    footer .footer-contact ul li:last-child {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    footer .footer-contact ul {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    footer .footer-contact ul li {
        width: 50%;
        margin-right: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (max-width: 767px) {
    footer {
        text-align: center
    }
    footer .footer-tools,
    footer .footer-contact {
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        text-align: center
    }
    footer .footer-tools .soc-list,
    footer .footer-contact .soc-list {
        -ms-flex-pack: center;
        justify-content: center
    }
    footer .footer-contact {
        text-align: left
    }
    footer .footer-contact .footer-contact-text a,
    footer .footer-contact .footer-contact-text p {
        text-align: left
    }
    footer .footer-contact ul {
        max-width: none;
        margin-left: auto;
        margin-right: auto
    }
    footer .footer-contact ul li a,
    footer .footer-contact ul li p {
        text-align: left
    }
}

@media (max-width: 480px) {
    footer .footer-contact ul {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    footer .footer-contact ul li {
        width: 100%;
        max-width: 200px;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

.footer-gallery {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: -10px;
    margin-left: -10px
}

.footer-gallery h3,
.footer-gallery .h3 {
    width: 100%;
    max-width: 375px;
    padding-left: 10px;
    padding-right: 10px
}

.footer-gallery .gallery-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    max-width: 390px
}

.footer-gallery .gallery-wrap a {
    margin-bottom: 0;
    border-radius: 0
}

.footer-gallery .gallery-wrap a img {
    border-radius: 5px
}

.footer-gallery .gallery-wrap .gallery-card {
    width: 33.33%;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 20px
}

.footer-gallery .gallery-wrap .gallery-card img {
    border-radius: 5px
}

@media (max-width: 1199px) {
    .footer-gallery {
        -ms-flex-align: center;
        align-items: center
    }
}

@media (max-width: 767px) {
    .footer-gallery {
        text-align: center
    }
}

@media (max-width: 480px) {
    .footer-gallery {
        margin-right: -5px;
        margin-left: -5px
    }
    .footer-gallery h3,
    .footer-gallery .h3 {
        padding-left: 5px;
        padding-right: 5px
    }
    .footer-gallery .gallery-wrap {
        max-width: 330px
    }
    .footer-gallery .gallery-wrap .gallery-card {
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 10px
    }
}

.footer-latest {
    width: 290px;
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 1920px) {
    .footer-latest {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .footer-latest {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .footer-latest {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .footer-latest {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .footer-latest {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .footer-latest {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .footer-latest {
        padding-bottom: 30px
    }
}

.footer-latest p {
    margin-bottom: 20px
}

.footer-latest ul {
    margin-bottom: 0
}

.footer-latest ul li {
    padding-left: 0;
    padding-right: 0;
    text-align: left
}

@media (max-width: 1920px) {
    .footer-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .footer-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .footer-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .footer-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .footer-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .footer-latest ul li {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .footer-latest ul li {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .footer-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .footer-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .footer-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .footer-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .footer-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .footer-latest ul li {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .footer-latest ul li {
        padding-bottom: 5px
    }
}

.footer-latest ul li::after {
    display: none
}

.footer-latest ul li .latest-logo {
    border-radius: 10px
}

.footer-latest ul li .latest-desk a,
.footer-latest ul li .latest-desk time {
    color: #fff
}

.custom-footer-top {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #fff
}

@media (max-width: 1920px) {
    .custom-footer-top {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .custom-footer-top {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .custom-footer-top {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .custom-footer-top {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .custom-footer-top {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .custom-footer-top {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .custom-footer-top {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .custom-footer-top .soc-list,
    .custom-footer-top .footer-logo,
    .custom-footer-top .progect-author {
        margin-bottom: 20px
    }
}

.custom-footer-top .progect-author {
    color: #fff
}

@media (max-width: 991px) {
    .custom-footer-top {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .custom-footer-top .footer-logo {
        -ms-flex-order: 0;
        order: 0
    }
    .custom-footer-top .progect-author {
        -ms-flex-order: 1;
        order: 1
    }
    .custom-footer-top .soc-list {
        -ms-flex-order: 2;
        order: 2
    }
}

@media (max-width: 767px) {
    .custom-footer-top {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .custom-footer-top .footer-logo {
        -ms-flex-order: 0;
        order: 0
    }
    .custom-footer-top .progect-author {
        -ms-flex-order: 1;
        order: 1
    }
    .custom-footer-top .soc-list {
        -ms-flex-order: 2;
        order: 2
    }
}

.footer-type-2 .container-fluid {
    max-width: 1600px
}

.footer-type-2 .container-fluid .fluid-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.footer-type-2 .container-fluid .fluid-footer .footer-tools {
    width: 300px;
    padding-left: 15px;
    padding-right: 15px
}

.footer-type-2 .container-fluid .fluid-footer .footer-tools .footer-subscribe input {
    border-radius: 10px 0 0 10px
}

.footer-type-2 .container-fluid .fluid-footer .footer-tools .footer-subscribe button {
    border-radius: 0 10px 10px 0
}

.footer-type-2 .container-fluid .fluid-footer .footer-gallery-wrap {
    width: 390px;
    padding-left: 15px;
    padding-right: 15px
}

.footer-type-2 .container-fluid .fluid-footer .footer-gallery {
    margin-left: -15px;
    margin-right: -15px
}

.footer-type-2 .container-fluid .fluid-footer .footer-contact {
    width: 290px;
    padding-left: 15px;
    padding-right: 15px
}

.footer-type-2 .container-fluid .fluid-footer .footer-map {
    width: 330px;
    padding-left: 15px;
    padding-right: 15px
}

.footer-type-2 .container-fluid .fluid-footer .footer-map #footer-map {
    height: 300px !important
}

@media (max-width: 1600px) {
    .footer-type-2 .container-fluid {
        max-width: 1200px
    }
    .footer-type-2 .container-fluid .fluid-footer {
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (max-width: 1199px) {
    .footer-type-2 .footer-contact h3,
    .footer-type-2 .footer-contact .h3,
    .footer-type-2 .footer-contact p {
        text-align: left
    }
    .footer-type-2 .footer-contact ul {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .footer-type-2 .footer-contact ul li {
        width: 100%;
        margin-right: 0;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    .footer-type-2 .footer-contact ul li:last-child {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .footer-type-2 .container-fluid .fluid-footer .footer-gallery-wrap {
        width: 100%
    }
}

@media (max-width: 767px) {
    .footer-type-2 .container-fluid .row-flex>div {
        width: 100%
    }
    .footer-type-2 .container-fluid .fluid-footer {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        width: auto
    }
    .footer-type-2 .container-fluid .fluid-footer .footer-tools {
        -ms-flex-order: 0;
        order: 0
    }
    .footer-type-2 .container-fluid .fluid-footer .footer-latest {
        -ms-flex-order: 1;
        order: 1
    }
    .footer-type-2 .container-fluid .fluid-footer .footer-gallery-wrap {
        -ms-flex-order: 3;
        order: 3
    }
    .footer-type-2 .container-fluid .fluid-footer .footer-contact {
        -ms-flex-order: 2;
        order: 2
    }
    .footer-type-2 .container-fluid .fluid-footer .footer-map {
        -ms-flex-order: 4;
        order: 4;
        width: 100%
    }
    .footer-type-2 .footer-contact h3,
    .footer-type-2 .footer-contact .h3,
    .footer-type-2 .footer-contact p {
        text-align: center
    }
}

@media (max-width: 480px) {
    .footer-type-2 .container-fluid .fluid-footer .footer-gallery .gallery-wrap {
        width: 100%;
        margin-left: 0;
        margin-right: 0
    }
    .footer-type-2 .container-fluid .fluid-footer .footer-map {
        width: 100%
    }
    .footer-type-2 .footer-contact h3,
    .footer-type-2 .footer-contact .h3,
    .footer-type-2 .footer-contact p {
        text-align: center
    }
}

@media (max-width: 767px) {
    .footer-type-3 .footer-tools,
    .footer-type-3 .footer-contact {
        max-width: 410px
    }
    .footer-type-3 .footer-contact ul {
        width: 100%;
        max-width: none !important
    }
}

@media (max-width: 1199px) {
    .footer-type-3 .row-flex {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .footer-type-3 .row-flex>div:nth-child(1) {
        -ms-flex-order: 0;
        order: 0
    }
    .footer-type-3 .row-flex>div:nth-child(2) {
        -ms-flex-order: 2;
        order: 2
    }
    .footer-type-3 .row-flex>div:nth-child(3) {
        -ms-flex-order: 1;
        order: 1
    }
}

.footer-type-4 .footer-latest {
    width: auto;
    max-width: 290px;
    padding-left: 0;
    padding-right: 0
}

@media (max-width: 1199px) {
    .footer-type-4 .footer-tools {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto
    }
    .footer-type-4 .footer-contact h3,
    .footer-type-4 .footer-contact .h3,
    .footer-type-4 .footer-contact p {
        text-align: left
    }
    .footer-type-4 .footer-contact ul {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .footer-type-4 .footer-contact ul li {
        width: 100%;
        margin-right: 0;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    .footer-type-4 .footer-contact ul li:last-child {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .footer-type-4 .footer-tools,
    .footer-type-4 .footer-contact {
        margin-left: auto;
        margin-right: auto;
        text-align: center
    }
    .footer-type-4 .footer-tools h3,
    .footer-type-4 .footer-tools .h3,
    .footer-type-4 .footer-tools p,
    .footer-type-4 .footer-contact h3,
    .footer-type-4 .footer-contact .h3,
    .footer-type-4 .footer-contact p {
        text-align: center
    }
    .footer-type-4 .footer-latest {
        margin-left: auto;
        margin-right: auto
    }
    .footer-type-4 .footer-contact ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row
    }
    .footer-type-4 .footer-contact ul li {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 50%
    }
    .footer-type-4 .footer-contact ul li i {
        margin-right: 0;
        margin-bottom: 20px;
        text-align: center
    }
    .footer-type-4 .footer-contact ul li p,
    .footer-type-4 .footer-contact ul li a {
        text-align: center
    }
}

@media (max-width: 480px) {
    .footer-type-4 .footer-contact h3,
    .footer-type-4 .footer-contact .h3,
    .footer-type-4 .footer-contact p {
        text-align: center
    }
    .footer-type-4 .footer-contact ul {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .footer-type-4 .footer-contact ul li {
        -ms-flex-direction: row;
        flex-direction: row;
        width: 100%
    }
    .footer-type-4 .footer-contact ul li i {
        margin-bottom: 0;
        margin-right: 20px
    }
    .footer-type-4 .footer-contact ul li p,
    .footer-type-4 .footer-contact ul li a {
        text-align: left
    }
}

.footer-type-5 .footer-contact ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-bottom: -20px
}

.footer-type-5 .footer-contact ul li {
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 20px
}

.footer-type-5 .footer-contact ul li i {
    margin-right: 0;
    margin-bottom: 15px;
    font-size: 38px;
    line-height: 1
}

.footer-type-5 .footer-contact ul li .footer-contact-text {
    text-align: center
}

.footer-type-5 .footer-contact ul li .footer-contact-text a {
    margin-bottom: 5px;
    line-height: 1.7em
}

@media (max-width: 767px) {
    .footer-type-5 .footer-contact {
        width: 100%;
        max-width: none
    }
    .footer-type-5 .footer-contact ul {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
        max-width: none
    }
    .footer-type-5 .footer-contact ul li {
        width: 50%;
        padding-left: 10px;
        padding-right: 10px
    }
    .footer-type-5 .footer-contact ul li:last-child {
        margin-bottom: 20px
    }
    .footer-type-5 .footer-contact ul li p,
    .footer-type-5 .footer-contact ul li a {
        text-align: center
    }
}

.testimonial-wrap {
    padding-top: 75px
}

@media (max-width: 1920px) {
    .testimonial-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .testimonial-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .testimonial-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .testimonial-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .testimonial-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .testimonial-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .testimonial-wrap {
        margin-bottom: 20px
    }
}

.testimonial-wrap:nth-child(5n+1) .testimonial {
    border: 1px solid #5255c5
}

.testimonial-wrap:nth-child(5n+1) .testimonial .author-avatar {
    border: 3px solid #5255c5
}

.testimonial-wrap:nth-child(5n+1) .testimonial h5 span,
.testimonial-wrap:nth-child(5n+1) .testimonial .h5 span {
    color: #ff5157
}

.testimonial-wrap:nth-child(5n+2) .testimonial {
    border: 1px solid #5dba3b
}

.testimonial-wrap:nth-child(5n+2) .testimonial .author-avatar {
    border: 3px solid #5dba3b
}

.testimonial-wrap:nth-child(5n+2) .testimonial h5 span,
.testimonial-wrap:nth-child(5n+2) .testimonial .h5 span {
    color: #5255c5
}

.testimonial-wrap:nth-child(5n+3) .testimonial {
    border: 1px solid #ff8b00
}

.testimonial-wrap:nth-child(5n+3) .testimonial .author-avatar {
    border: 3px solid #ff8b00
}

.testimonial-wrap:nth-child(5n+3) .testimonial h5 span,
.testimonial-wrap:nth-child(5n+3) .testimonial .h5 span {
    color: #5dba3b
}

.testimonial-wrap:nth-child(5n+4) .testimonial {
    border: 1px solid #ff5157
}

.testimonial-wrap:nth-child(5n+4) .testimonial .author-avatar {
    border: 3px solid #ff5157
}

.testimonial-wrap:nth-child(5n+4) .testimonial h5 span,
.testimonial-wrap:nth-child(5n+4) .testimonial .h5 span {
    color: #ff8b00
}

.testimonial-wrap:nth-child(5n+5) .testimonial {
    border: 1px solid #ffc000
}

.testimonial-wrap:nth-child(5n+5) .testimonial .author-avatar {
    border: 3px solid #ffc000
}

.testimonial-wrap:nth-child(5n+5) .testimonial h5 span,
.testimonial-wrap:nth-child(5n+5) .testimonial .h5 span {
    color: #5dba3b
}

.testimonial-wrap .testimonial {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid #ff8b00;
    border-radius: 10px;
    background: #fff
}

.testimonial-wrap .testimonial .author-avatar {
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    margin-top: -75px;
    border: 3px solid #ff8b00;
    border-radius: 50%
}

@media (max-width: 1920px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .testimonial-wrap .testimonial .author-avatar {
        margin-bottom: 20px
    }
}

.testimonial-wrap .testimonial .author-avatar img {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: none;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.testimonial-wrap .testimonial .testimonials-text {
    width: 100%;
    text-align: center
}

.testimonial-wrap .testimonial h5 span,
.testimonial-wrap .testimonial .h5 span {
    padding-left: 5px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 15px;
    color: #5dba3b
}

@media (max-width: 480px) {
    .testimonial-wrap .testimonial {
        padding-left: 15px;
        padding-right: 15px
    }
    .testimonial-wrap .testimonial .testimonials-text {
        text-align: justify
    }
}

.slick-slide:nth-child(5n+1) .testimonial-wrap .testimonial {
    border: 1px solid #5255c5
}

.slick-slide:nth-child(5n+1) .testimonial-wrap .testimonial .author-avatar {
    border: 3px solid #5255c5
}

.slick-slide:nth-child(5n+1) .testimonial-wrap .testimonial h5 span,
.slick-slide:nth-child(5n+1) .testimonial-wrap .testimonial .h5 span {
    color: #ff5157
}

.slick-slide:nth-child(5n+2) .testimonial-wrap .testimonial {
    border: 1px solid #5dba3b
}

.slick-slide:nth-child(5n+2) .testimonial-wrap .testimonial .author-avatar {
    border: 3px solid #5dba3b
}

.slick-slide:nth-child(5n+2) .testimonial-wrap .testimonial h5 span,
.slick-slide:nth-child(5n+2) .testimonial-wrap .testimonial .h5 span {
    color: #5255c5
}

.slick-slide:nth-child(5n+3) .testimonial-wrap .testimonial {
    border: 1px solid #ff8b00
}

.slick-slide:nth-child(5n+3) .testimonial-wrap .testimonial .author-avatar {
    border: 3px solid #ff8b00
}

.slick-slide:nth-child(5n+3) .testimonial-wrap .testimonial h5 span,
.slick-slide:nth-child(5n+3) .testimonial-wrap .testimonial .h5 span {
    color: #5dba3b
}

.slick-slide:nth-child(5n+4) .testimonial-wrap .testimonial {
    border: 1px solid #ff5157
}

.slick-slide:nth-child(5n+4) .testimonial-wrap .testimonial .author-avatar {
    border: 3px solid #ff5157
}

.slick-slide:nth-child(5n+4) .testimonial-wrap .testimonial h5 span,
.slick-slide:nth-child(5n+4) .testimonial-wrap .testimonial .h5 span {
    color: #ff8b00
}

.slick-slide:nth-child(5n+5) .testimonial-wrap .testimonial {
    border: 1px solid #ffc000
}

.slick-slide:nth-child(5n+5) .testimonial-wrap .testimonial .author-avatar {
    border: 3px solid #ffc000
}

.slick-slide:nth-child(5n+5) .testimonial-wrap .testimonial h5 span,
.slick-slide:nth-child(5n+5) .testimonial-wrap .testimonial .h5 span {
    color: #5dba3b
}

.testimonial-section {
    position: relative;
    background-image: url(../img/absurdity.png);

}

@media (max-width: 1920px) {
    .testimonial-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .testimonial-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .testimonial-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .testimonial-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .testimonial-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .testimonial-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .testimonial-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .testimonial-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .testimonial-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .testimonial-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .testimonial-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .testimonial-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .testimonial-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .testimonial-section {
        padding-bottom: 30px
    }
}

.testimonial-section .testimonial-slider {
    position: relative;
    z-index: 10;
    margin-left: -15px;
    margin-right: -15px
}

@media (max-width: 1920px) {
    .testimonial-section .testimonial-slider {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .testimonial-section .testimonial-slider {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .testimonial-section .testimonial-slider {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .testimonial-section .testimonial-slider {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .testimonial-section .testimonial-slider {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .testimonial-section .testimonial-slider {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .testimonial-section .testimonial-slider {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .testimonial-section .testimonial-slider {
        padding-bottom: 30px
    }
}

.testimonial-section .testimonial-slider .card {
    padding-left: 15px;
    padding-right: 15px
}

.testimonial-section .testimonial-slider .card .testimonial-wrap {
    margin-bottom: 0
}

.testimonial-section .moving-element {
    top: 30%;
    left: 90%
}

.testimonial-section .bottom-img {
    position: absolute;
    left: 10%;
    bottom: 0
}

@media (max-width: 1440px) {
    .testimonial-section .moving-element {
        top: 10%;
        left: 50%
    }
}

@media (max-width: 1199px) {
    .testimonial-section .moving-element {
        top: 10%;
        left: 45%
    }
}

@media (max-width: 991px) {
    .testimonial-section .bottom-img {
        height: 90px
    }
}

@media (max-width: 767px) {
    .testimonial-section .bottom-img {
        height: 75px
    }
}

.second-testimonial-wrap {
    background: url(../img/girl-1.jpg) no-repeat;
    background-position: 25% bottom
}

@media (max-width: 1920px) {
    .second-testimonial-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .second-testimonial-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .second-testimonial-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .second-testimonial-wrap {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .second-testimonial-wrap {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .second-testimonial-wrap {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .second-testimonial-wrap {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .second-testimonial-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .second-testimonial-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .second-testimonial-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .second-testimonial-wrap {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .second-testimonial-wrap {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .second-testimonial-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .second-testimonial-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .second-testimonial-wrap .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .second-testimonial-wrap .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .second-testimonial-wrap .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .second-testimonial-wrap .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .second-testimonial-wrap .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .second-testimonial-wrap .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .second-testimonial-wrap .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .second-testimonial-wrap .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .second-testimonial-wrap .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .second-testimonial-wrap .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .second-testimonial-wrap .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .second-testimonial-wrap .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .second-testimonial-wrap .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .second-testimonial-wrap .container {
        padding-bottom: 30px
    }
}

.second-testimonial-wrap h2,
.second-testimonial-wrap .h2 {
    text-align: center
}

.second-testimonial-wrap .testimonial-wrap {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .second-testimonial-wrap {
        background-position: 15px bottom
    }
    .second-testimonial-wrap .testimonial-slider-second {
        margin-left: -15px;
        margin-right: -15px
    }
    .second-testimonial-wrap .testimonial-slider-second .card {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (max-width: 767px) {
    .second-testimonial-wrap {
        background: #fff
    }
}

.sidebar {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto
}

@media (max-width: 1920px) {
    .sidebar {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .sidebar {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .sidebar {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .sidebar {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .sidebar {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .sidebar {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .sidebar {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .sidebar {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .sidebar {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .sidebar {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .sidebar {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .sidebar {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .sidebar {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .sidebar {
        padding-bottom: 30px
    }
}

.sidebar h4,
.sidebar .h4 {
    border-radius: 15px 15px 0 0;
    margin-bottom: 0;
    background: #5dba3b;
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .sidebar h4,
    .sidebar .h4 {
        padding-bottom: 5px
    }
}

.sidebar h4.color-1,
.sidebar .h4.color-1 {
    background: #5dba3b
}

.sidebar h4.color-2,
.sidebar .h4.color-2 {
    background: #ff8b00
}

.sidebar h4.color-3,
.sidebar .h4.color-3 {
    background: #ff5157
}

.sidebar h4.color-4,
.sidebar .h4.color-4 {
    background: #5255c5
}

.sidebar h4.color-5,
.sidebar .h4.color-5 {
    background: #ffc000
}

.sidebar ul {
    margin-bottom: 0;
    border: 2px solid #d8d8d8;
    border-radius: 0 0 15px 15px;
    border-top: 0
}

@media (max-width: 1920px) {
    .sidebar ul {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar ul {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar ul {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar ul {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .sidebar ul {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .sidebar ul {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .sidebar ul {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .sidebar ul {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar ul {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar ul {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar ul {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .sidebar ul {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .sidebar ul {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .sidebar ul {
        padding-bottom: 5px
    }
}

.sidebar ul li {
    padding-left: 0
}

.sidebar ul li::after {
    display: none
}

@media (max-width: 1920px) {
    .sidebar .sidebar-category {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-category {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-category {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-category {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-category {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-category {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-category {
        margin-bottom: 20px
    }
}

.sidebar .sidebar-category ul li a {
    display: block;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    text-decoration: none;
    color: #090605;
    transition: all .2s
}

@media (max-width: 1920px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-category ul li a {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-category ul li a {
        padding-bottom: 5px
    }
}

.sidebar .sidebar-category ul li a:hover {
    background: #5255c5;
    color: #fff
}

@media (max-width: 1920px) {
    .sidebar .sidebar-latest {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-latest {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-latest {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-latest {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-latest {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-latest {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-latest {
        margin-bottom: 20px
    }
}

.sidebar .sidebar-latest ul li {
    padding-left: 20px;
    padding-right: 20px
}

@media (max-width: 1920px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-latest ul li {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-latest ul li {
        padding-bottom: 5px
    }
}

@media (max-width: 1920px) {
    .sidebar .sidebar-comments {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-comments {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-comments {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-comments {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-comments {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-comments {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-comments {
        margin-bottom: 20px
    }
}

.sidebar .sidebar-comments ul li {
    padding-left: 20px;
    padding-right: 20px
}

@media (max-width: 1920px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-comments ul li {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-comments ul li {
        padding-bottom: 5px
    }
}

.sidebar .sidebar-comments ul li a {
    position: relative;
    padding-left: 30px;
    text-decoration: none;
    color: #777
}

.sidebar .sidebar-comments ul li a:hover {
    color: #5255c5
}

.sidebar .sidebar-comments ul li a:hover span {
    color: #5255c5
}

.sidebar .sidebar-comments ul li a::after {
    content: '\f189';
    position: absolute;
    top: 3px;
    left: 0;
    font-family: 'Material Design Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    color: #ff5157
}

.sidebar .sidebar-comments ul li a span {
    color: #090605
}

@media (max-width: 1920px) {
    .sidebar .sidebar-archive {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-archive {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-archive {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-archive {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-archive {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-archive {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-archive {
        margin-bottom: 20px
    }
}

.sidebar .sidebar-archive ul {
    padding-left: 20px;
    padding-right: 20px
}

@media (max-width: 1920px) {
    .sidebar .sidebar-archive ul {
        padding-top: 20px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-archive ul {
        padding-top: 20px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-archive ul {
        padding-top: 20px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-archive ul {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-archive ul {
        padding-top: 20px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-archive ul {
        padding-top: 15px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-archive ul {
        padding-top: 15px
    }
}

@media (max-width: 1920px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 20px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 20px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 15px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-archive ul {
        padding-bottom: 15px
    }
}

.sidebar .sidebar-archive ul li {
    padding-left: 20px
}

.sidebar .sidebar-archive ul li::after {
    content: '\f35f';
    top: 0;
    display: block;
    width: auto;
    height: auto;
    background: transparent;
    font-family: 'Material Design Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    color: #090605
}

.sidebar .sidebar-archive ul li:last-child a {
    margin-bottom: 0
}

.sidebar .sidebar-archive ul li a {
    font-size: 17px;
    text-decoration: none;
    color: #090605
}

@media (max-width: 1920px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-archive ul li a {
        margin-bottom: 10px
    }
}

.sidebar .sidebar-archive ul li a:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    .sidebar .sidebar-tag {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-tag {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-tag {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-tag {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-tag {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-tag {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-tag {
        margin-bottom: 20px
    }
}

.sidebar .sidebar-tag ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px
}

@media (max-width: 1920px) {
    .sidebar .sidebar-tag ul {
        padding-top: 20px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-tag ul {
        padding-top: 20px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-tag ul {
        padding-top: 20px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-tag ul {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-tag ul {
        padding-top: 20px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-tag ul {
        padding-top: 15px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-tag ul {
        padding-top: 15px
    }
}

.sidebar .sidebar-tag ul li {
    width: 33.33%;
    text-align: center
}

@media (max-width: 1920px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .sidebar .sidebar-tag ul li {
        margin-bottom: 5px
    }
}

.sidebar .sidebar-tag ul li a {
    display: block;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
    padding: 3px 5px;
    border: 1px solid #ff8b00;
    text-align: center;
    text-decoration: none;
    color: #ff8b00;
    transition: all .3s
}

.sidebar .sidebar-tag ul li a:hover {
    background: #ff8b00;
    color: #fff
}

.sidebar .sidebar-banner {
    border-radius: 20px;
    overflow: hidden
}

.sidebar .sidebar-banner a {
    position: relative;
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-size: cover
}

.sidebar .sidebar-banner a img {
    width: 100%;
    border-radius: 15px
}

.sidebar .sidebar-banner a::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 3%;
    left: 3%;
    width: 94%;
    height: 94%;
    border-radius: 15px;
    border: 1px solid #fff
}

.sidebar .sidebar-banner a .banner-desc {
    position: absolute;
    left: 50%;
    top: 120px;
    z-index: 2;
    text-align: center;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.sidebar .sidebar-banner a .banner-desc span {
    display: block
}

.sidebar .sidebar-banner a .banner-desc span:first-child {
    margin-bottom: 10px;
    font-family: "Baloo", cursive;
    font-size: 20px;
    color: #5255c5
}

.sidebar .sidebar-banner a .banner-desc span:last-child {
    color: #090605
}

@media (max-width: 767px) {
    .sidebar {
        max-width: 300px;
        margin-left: auto;
        margin-right: auto
    }
    .sidebar .sidebar-tag ul {
        padding: 15px 10px 5px
    }
    .sidebar .sidebar-tag ul li {
        margin-bottom: 10px;
        padding: 0 5px
    }
    .sidebar .sidebar-tag ul li a {
        width: 100%
    }
    .sidebar .sidebar-banner a .banner-desc {
        top: 130px
    }
}

.soc-list {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0
}

.soc-list li {
    margin-right: 20px;
    padding-left: 0
}

.soc-list li::after {
    display: none
}

.soc-list li:last-child {
    margin-right: 0
}

.soc-list li a {
    font-size: 28px;
    color: #fff;
    transition: all .2s
}

.soc-list li a:hover {
    color: #ff5157
}

.contact-list {
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0
}

.contact-list li {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 30px
}

.contact-list li:last-child {
    margin-right: 0
}

.contact-list li::after {
    display: none
}

.contact-list li i {
    margin-right: 10px;
    font-size: 38px;
    color: #5dba3b
}

.contact-list li i.color-1 {
    color: #5dba3b
}

.contact-list li i.color-2 {
    color: #ff8b00
}

.contact-list li i.color-3 {
    color: #ff5157
}

.contact-list li .footer-contact-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.contact-list li .footer-contact-text p {
    margin-bottom: 0;
    font-size: 15px;
    color: #777
}

.contact-list li .footer-contact-text a {
    font-size: 15px;
    text-decoration: none;
    line-height: 1.7em;
    color: #777;
    transition: all .2s
}

.contact-list li .footer-contact-text a:last-child {
    margin-bottom: 0
}

.contact-list li .footer-contact-text a:hover {
    color: #5255c5
}

.advantages-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .advantages-list {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .advantages-list {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .advantages-list {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .advantages-list {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .advantages-list {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .advantages-list {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .advantages-list {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .advantages-list {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .advantages-list {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .advantages-list {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .advantages-list {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .advantages-list {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .advantages-list {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .advantages-list {
        padding-bottom: 30px
    }
}

.advantages-list li {
    display: -ms-flexbox;
    display: flex;
    max-width: 270px;
    margin-bottom: 0;
    padding-left: 0
}

.advantages-list li::after {
    display: none
}

.advantages-list li:nth-child(5n+1) .advantages-ico::after {
    background: #5dba3b
}

.advantages-list li:nth-child(5n+1) .advantages-ico:hover::after {
    background: #50a033
}

.advantages-list li:nth-child(5n+2) .advantages-ico::after {
    background: #ff5157
}

.advantages-list li:nth-child(5n+2) .advantages-ico:hover::after {
    background: #e04b4e
}

.advantages-list li:nth-child(5n+3) .advantages-ico::after {
    background: #ff8b00
}

.advantages-list li:nth-child(5n+3) .advantages-ico:hover::after {
    background: #e07b21
}

.advantages-list li:nth-child(5n+4) .advantages-ico::after {
    background: #5255c5
}

.advantages-list li:nth-child(5n+4) .advantages-ico:hover::after {
    background: #474aab
}

.advantages-list li:nth-child(5n+5) .advantages-ico::after {
    background: #ffc000
}

.advantages-list li:nth-child(5n+5) .advantages-ico:hover::after {
    background: #e5ad00
}

.advantages-list li .advantages-ico {
    position: relative;
    z-index: 2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 80px;
    height: 80px;
    min-width: 80px;
    margin-right: 15px
}

.advantages-list li .advantages-ico::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #5255c5;
    transition: all .25s
}

.advantages-list li .advantages-ico:hover::after {
    background: #474aab
}

.advantages-list li .advantages-ico img {
    height: 50px
}

.advantages-list li .advantages-desc h4,
.advantages-list li .advantages-desc .h4 {
    text-align: left
}

@media (max-width: 1920px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .advantages-list li .advantages-desc h4,
    .advantages-list li .advantages-desc .h4 {
        margin-bottom: 5px
    }
}

.advantages-list li .advantages-desc p {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .advantages-list {
        -ms-flex-pack: distribute;
        justify-content: space-around
    }
    .advantages-list li {
        margin-bottom: 30px
    }
    .advantages-list li:last-child {
        margin-bottom: 0
    }
}

.benefits-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.benefits-list li {
    padding-left: 35px
}

.benefits-list li::after {
    content: '\f463';
    top: 0;
    height: auto;
    height: auto;
    background-color: transparent;
    font-family: 'Material Design Icons';
    font-size: 23px;
    color: #5255c5
}

.benefits-list li:nth-child(5n+1)::after {
    color: #5255c5
}

.benefits-list li:nth-child(5n+2)::after {
    color: #5dba3b
}

.benefits-list li:nth-child(5n+3)::after {
    color: #ff8b00
}

.benefits-list li:nth-child(5n+4)::after {
    color: #ff5157
}

.benefits-list li:nth-child(5n+5)::after {
    color: #ffc000
}

@media (max-width: 1920px) {
    .benefits-list li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .benefits-list li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .benefits-list li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .benefits-list li p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .benefits-list li p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .benefits-list li p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .benefits-list li p {
        margin-bottom: 5px
    }
}

.benefits-list-big {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

@media (max-width: 1920px) {
    .benefits-list-big {
        margin-bottom: -30px
    }
}

@media (max-width: 1600px) {
    .benefits-list-big {
        margin-bottom: -30px
    }
}

@media (max-width: 1440px) {
    .benefits-list-big {
        margin-bottom: -30px
    }
}

@media (max-width: 1199px) {
    .benefits-list-big {
        margin-bottom: -30px
    }
}

@media (max-width: 991px) {
    .benefits-list-big {
        margin-bottom: -25px
    }
}

@media (max-width: 767px) {
    .benefits-list-big {
        margin-bottom: -20px
    }
}

@media (max-width: 480px) {
    .benefits-list-big {
        margin-bottom: -20px
    }
}

.benefits-list-big li {
    width: 12.5%;
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 1920px) {
    .benefits-list-big li {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .benefits-list-big li {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .benefits-list-big li {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .benefits-list-big li {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .benefits-list-big li {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .benefits-list-big li {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .benefits-list-big li {
        margin-bottom: 20px
    }
}

.benefits-list-big li::after {
    display: none
}

.benefits-list-big li .benefit-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    max-width: 120px;
    margin-left: auto;
    margin-right: auto;
    text-align: center
}

.benefits-list-big li .benefit-card img {
    height: 45px
}

@media (max-width: 1920px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .benefits-list-big li .benefit-card img {
        margin-bottom: 15px
    }
}

.benefits-list-big li .benefit-card h4,
.benefits-list-big li .benefit-card .h4 {
    margin-bottom: 0;
    color: #fff
}

@media (max-width: 1199px) {
    .benefits-list-big li {
        width: 25%
    }
}

@media (max-width: 767px) {
    .benefits-list-big {
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .benefits-list-big li {
        width: 33.33%
    }
}

@media (max-width: 480px) {
    .benefits-list-big {
        -ms-flex-pack: center;
        justify-content: center
    }
    .benefits-list-big li {
        width: 50%
    }
}

.event-list .event-card {
    max-width: none
}

@media (max-width: 1920px) {
    .event-list .event-card {
        margin-bottom: 40px
    }
}

@media (max-width: 1600px) {
    .event-list .event-card {
        margin-bottom: 40px
    }
}

@media (max-width: 1440px) {
    .event-list .event-card {
        margin-bottom: 40px
    }
}

@media (max-width: 1199px) {
    .event-list .event-card {
        margin-bottom: 40px
    }
}

@media (max-width: 991px) {
    .event-list .event-card {
        margin-bottom: 35px
    }
}

@media (max-width: 767px) {
    .event-list .event-card {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) {
    .event-list .event-card {
        margin-bottom: 25px
    }
}

.event-list .event-card:last-child {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .event-list .event-card figcaption h4,
    .event-list .event-card figcaption .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 1920px) {
    .event-list .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .event-list .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .event-list .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .event-list .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .event-list .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .event-list .event-card figcaption p {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .event-list .event-card figcaption p {
        margin-bottom: 10px
    }
}

.event-list .event-card figcaption .read-more {
    margin-top: 0
}

.event-list .event-card figcaption .event-info-list p {
    margin-bottom: 0
}

.event-list .event-card .event-logo {
    min-width: 360px
}

.event-list .event-card .event-logo img {
    height: 275px
}

@media (max-width: 991px) {
    .event-list .event-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 400px;
        -ms-flex-align: center;
        align-items: center
    }
    .event-list .event-card .event-logo {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        margin-bottom: 20px
    }
    .event-list .event-card .event-logo time {
        margin-right: 0;
        margin-top: -40px
    }
    .event-list .event-card p {
        text-align: justify
    }
    .event-list .event-card .event-logo {
        margin-right: 0;
        margin-bottom: 30px
    }
    .event-list .event-card figcaption {
        text-align: center
    }
    .event-list .event-card figcaption .event-info-list {
        -ms-flex-pack: center;
        justify-content: center
    }
    .event-list .event-card figcaption p {
        text-align: justify
    }
    .event-list .event-card figcaption .read-more {
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media (max-width: 480px) {
    .event-list .event-card .event-logo {
        width: 100%;
        min-width: 0
    }
    .event-list .event-card .event-logo img {
        height: auto
    }
}

.event-info-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0
}

.event-info-list li {
    margin-right: 15px;
    padding-left: 0
}

@media (max-width: 1920px) {
    .event-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .event-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .event-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .event-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .event-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .event-info-list li {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .event-info-list li {
        margin-bottom: 10px
    }
}

.event-info-list li::after {
    display: none
}

.event-info-list li p,
.event-info-list li a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    line-height: 1.5em;
    text-decoration: none;
    color: #777
}

.event-info-list li p i,
.event-info-list li a i {
    margin-right: 5px;
    font-size: 20px;
    color: #5255c5
}

.event-info-list li a:hover {
    color: #5255c5
}

.color-1 .event-info-list li a i,
.color-1 .event-info-list li p i {
    color: #5255c5
}

.color-1 .event-info-list li a:hover {
    color: #5255c5
}

.color-2 .event-info-list li a i,
.color-2 .event-info-list li p i {
    color: #5dba3b
}

.color-2 .event-info-list li a:hover {
    color: #5dba3b
}

.color-3 .event-info-list li a i,
.color-3 .event-info-list li p i {
    color: #ff8b00
}

.color-3 .event-info-list li a:hover {
    color: #ff8b00
}

.color-4 .event-info-list li a i,
.color-4 .event-info-list li p i {
    color: #ff5157
}

.color-4 .event-info-list li a:hover {
    color: #ff5157
}

.color-5 .event-info-list li a i,
.color-5 .event-info-list li p i {
    color: #ffc000
}

.color-5 .event-info-list li a:hover {
    color: #ffc000
}

.events-slider-section {
    position: relative
}

@media (max-width: 1920px) {
    .events-slider-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .events-slider-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .events-slider-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .events-slider-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .events-slider-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .events-slider-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .events-slider-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .events-slider-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .events-slider-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .events-slider-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .events-slider-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .events-slider-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .events-slider-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .events-slider-section {
        padding-bottom: 30px
    }
}

.events-slider-section .container {
    position: relative;
    z-index: 10
}

@media (max-width: 1920px) {
    .events-slider-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .events-slider-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .events-slider-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .events-slider-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .events-slider-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .events-slider-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .events-slider-section .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .events-slider-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .events-slider-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .events-slider-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .events-slider-section .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .events-slider-section .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .events-slider-section .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .events-slider-section .container {
        padding-bottom: 30px
    }
}

.events-slider-section h2 {
    text-align: center
}

@media (max-width: 1920px) {
    .events-slider-section h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .events-slider-section h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .events-slider-section h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .events-slider-section h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .events-slider-section h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .events-slider-section h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .events-slider-section h2 {
        margin-bottom: 30px
    }
}

.events-slider-section .event-slider {
    margin-left: -15px;
    margin-right: -15px
}

.events-slider-section .event-slider .card {
    padding-left: 15px;
    padding-right: 15px
}

.events-slider-section .moving-element {
    left: 5%;
    z-index: 0;
    bottom: 10%
}

.home-slider-wrap {
    position: relative
}

.home-slider-wrap .home-slider {
    position: relative;
    height: 750px
}

.home-slider-wrap .home-slider .container {
    position: relative;
    z-index: 10;
    height: 100%
}

.home-slider-wrap .home-slider .container .row {
    height: 100%
}

.home-slider-wrap .home-slider .container .row>div {
    height: 100%
}

.home-slider-wrap .home-slider .card {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding-top: 0
}

.home-slider-wrap .home-slider .card .inner-home-slider {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

.home-slider-wrap .home-slider .card .home-slider-desc {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 10px;
    border-radius: 50px;
    background: #fff
}

.home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 50px;
    border: 1px dashed #000;
    text-align: center
}

@media (max-width: 1920px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-bottom: 30px
    }
}

.home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
.home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
    text-transform: uppercase
}

@media (max-width: 1920px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text h1,
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .h1 {
        margin-bottom: 15px
    }
}

.home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .slider-button-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 390px;
    margin-left: auto;
    margin-right: auto
}

.home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .slider-button-wrap .button {
    margin-bottom: 0
}

.home-slider-wrap .home-slider .card .home-slider-desc .slider-mini-img {
    position: absolute;
    z-index: 0
}

.home-slider-wrap .home-slider .card .home-slider-desc .slider-mini-img.one {
    top: -15px;
    left: -20px
}

.home-slider-wrap .home-slider .card .home-slider-desc .slider-mini-img.two {
    right: -20px;
    bottom: -30px
}

.home-slider-wrap .home-slider .inner-home-slider .slider-mini-img {
    -ms-transform: translate(100px, 0);
    transform: translate(100px, 0);
    animation-timing-function: linear
}

.home-slider-wrap .home-slider .inner-home-slider .slider-mini-img.one {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

.home-slider-wrap .home-slider .inner-home-slider .slider-mini-img.two {
    -ms-transform: translate(0, 0);
    transform: translate(0, 0)
}

.home-slider-wrap .home-slider .inner-home-slider.animate-slide .slider-mini-img.one {
    animation: fly-one 15s forwards;
    animation-iteration-count: infinite;
    animation-direction: alternate
}

.home-slider-wrap .home-slider .inner-home-slider.animate-slide .slider-mini-img.two {
    animation: fly-two 13s forwards;
    animation-iteration-count: infinite
}

.home-slider-wrap .home-slider .home-slider-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    max-width: none;
    height: auto;
    width: 100%
}

@media (max-width: 1600px) {
    .home-slider-wrap .home-slider {
        height: 700px
    }
    .home-slider-wrap .home-slider .home-slider-bg {
        width: auto;
        max-width: none;
        min-width: 100%;
        height: auto;
        height: 100%
    }
}

@media (max-width: 1440px) {
    .home-slider-wrap .home-slider {
        height: 600px
    }
    .home-slider-wrap .home-slider .home-slider-bg {
        width: 100%;
        height: auto;
        min-height: 600px
    }
    .home-slider-wrap .home-slider .card .inner-home-slider {
        padding-right: 70px
    }
}

@media (max-width: 1199px) {
    .home-slider-wrap .home-slider {
        height: 500px
    }
    .home-slider-wrap .home-slider .card .inner-home-slider {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
        padding-right: 0
    }
    .home-slider-wrap .home-slider .home-slider-bg {
        min-height: 500px
    }
    .home-slider-wrap .home-slider .inner-home-slider.animate-slide .slider-mini-img {
        height: 60px
    }
    .home-slider-wrap .home-slider .inner-home-slider.animate-slide .slider-mini-img.two {
        right: 0;
        bottom: -10px
    }
}

@media (max-width: 991px) {
    .home-slider-wrap .home-slider {
        height: 500px
    }
    .home-slider-wrap .home-slider .home-slider-bg {
        height: 100%;
        min-height: 0;
        width: auto
    }
}

@media (max-width: 767px) {
    .home-slider-wrap .home-slider .home-slider-bg {
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 100%;
        min-height: 0;
        width: auto
    }
}

@media (max-width: 480px) {
    .home-slider-wrap .home-slider .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0
    }
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text {
        padding-left: 15px;
        padding-right: 15px
    }
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .slider-button-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .home-slider-wrap .home-slider .card .home-slider-desc .home-slider-text .slider-button-wrap .button:nth-child(1) {
        margin-bottom: 15px
    }
    .home-slider-wrap .home-slider .card .home-slider-desc .slider-mini-img.one {
        top: -35px;
        left: -20px
    }
    .home-slider-wrap .home-slider .card .home-slider-desc .slider-mini-img.two {
        right: -20px;
        bottom: -30px
    }
    .home-slider-wrap .home-slider .inner-home-slider.animate-slide .slider-mini-img.two {
        right: 40px;
        bottom: -15px
    }
}

.home-slider-wrap .slick-list,
.home-slider-wrap .slick-track,
.home-slider-wrap .slick-slide>div {
    height: 100%
}

.home-slider-wrap .slider-arrow.arrow-left {
    left: 100px
}

.home-slider-wrap .slider-arrow.arrow-right {
    right: 100px
}

@media (max-width: 1600px) {
    .home-slider-wrap .slider-arrow.arrow-left {
        left: 30px
    }
    .home-slider-wrap .slider-arrow.arrow-right {
        right: 30px
    }
}

@media (max-width: 767px) {
    .home-slider-wrap .slider-arrow.arrow-left {
        left: 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-indent: -2px
    }
    .home-slider-wrap .slider-arrow.arrow-right {
        right: 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-indent: -2px
    }
}

.home-slider-wrap .slick-dots {
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%
}

@media (max-width: 767px) {
    .home-slider-wrap .slider-arrow {
        display: none
    }
}

@keyframes fly-one {
    0% {
        transform: translate(0, 0)
    }
    10% {
        transform: translate(20px, -15px) rotate(10deg)
    }
    15% {
        transform: translate(20px, -15px) rotate(180deg)
    }
    30% {
        transform: translate(-20px, 15px) rotate(180deg)
    }
    40% {
        transform: translate(-40px, -15px) rotate(-100deg)
    }
    50% {
        transform: translate(-10px, -45px) rotate(10deg)
    }
    60% {
        transform: translate(50px, -85px) rotate(50deg)
    }
    70% {
        transform: translate(40px, -25px) rotate(150deg)
    }
    85% {
        transform: translate(80px, -15px) rotate(-100deg)
    }
    100% {
        transform: translate(0, 0) rotate(0)
    }
}

@keyframes fly-two {
    0% {
        transform: translate(0, 0)
    }
    10% {
        transform: translate(-30px, 15px) rotate(-20deg)
    }
    20% {
        transform: translate(30px, -15px) rotate(40deg)
    }
    30% {
        transform: translate(10px, -25px) rotate(20deg)
    }
    40% {
        transform: translate(40px, -85px) rotate(15deg)
    }
    55% {
        transform: translate(80px, -15px) rotate(-15deg)
    }
    75% {
        transform: translate(0px, 35px) rotate(15deg)
    }
    100% {
        transform: translate(0, 0) rotate(0)
    }
}

@media (max-width: 1920px) {
    .team-slider-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .team-slider-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .team-slider-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .team-slider-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .team-slider-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .team-slider-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .team-slider-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .team-slider-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .team-slider-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .team-slider-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .team-slider-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .team-slider-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .team-slider-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .team-slider-section {
        padding-bottom: 30px
    }
}

.team-slider-section h2,
.team-slider-section .h2 {
    text-align: center
}

@media (max-width: 1920px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .team-slider-section h2,
    .team-slider-section .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .team-slider-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .team-slider-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .team-slider-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .team-slider-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .team-slider-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .team-slider-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .team-slider-section .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .team-slider-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .team-slider-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .team-slider-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .team-slider-section .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .team-slider-section .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .team-slider-section .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .team-slider-section .container {
        padding-bottom: 30px
    }
}

.team-slider-section .team-slider-wrap {
    margin-left: -15px;
    margin-right: -15px
}

.team-slider-section .team-slider-wrap .card {
    padding-left: 15px;
    padding-right: 15px
}

.team-slider-section .team-slider-wrap .card .employe-card {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .progress-skill {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .progress-skill {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .progress-skill {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .progress-skill {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .progress-skill {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .progress-skill {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .progress-skill {
        padding-bottom: 30px
    }
}

.progress-skill .progress-wrap:last-child {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .skill-desc-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .skill-desc-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .skill-desc-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .skill-desc-wrap {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .skill-desc-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .skill-desc-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .skill-desc-wrap {
        margin-bottom: 30px
    }
}

.skill-desc {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

@media (max-width: 1920px) {
    .skill-desc {
        margin-bottom: -30px
    }
}

@media (max-width: 1600px) {
    .skill-desc {
        margin-bottom: -30px
    }
}

@media (max-width: 1440px) {
    .skill-desc {
        margin-bottom: -30px
    }
}

@media (max-width: 1199px) {
    .skill-desc {
        margin-bottom: -30px
    }
}

@media (max-width: 991px) {
    .skill-desc {
        margin-bottom: -25px
    }
}

@media (max-width: 767px) {
    .skill-desc {
        margin-bottom: -20px
    }
}

@media (max-width: 480px) {
    .skill-desc {
        margin-bottom: -20px
    }
}

.skill-desc .skill-desc-block-wrap {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 1920px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .skill-desc .skill-desc-block-wrap {
        margin-bottom: 20px
    }
}

.skill-desc .skill-desc-block-wrap .skill-desc-block {
    padding-left: 15px;
    padding-right: 15px;
    background: #f8f8fa;
    border: 1px solid #e9e8e6;
    text-align: center
}

@media (max-width: 1920px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 20px
    }
}

@media (max-width: 1600px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 20px
    }
}

@media (max-width: 1440px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 20px
    }
}

@media (max-width: 1199px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 20px
    }
}

@media (max-width: 767px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 15px
    }
}

@media (max-width: 480px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-top: 15px
    }
}

@media (max-width: 1920px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 20px
    }
}

@media (max-width: 991px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 20px
    }
}

@media (max-width: 767px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 15px
    }
}

@media (max-width: 480px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block {
        padding-bottom: 15px
    }
}

@media (max-width: 1920px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .skill-desc .skill-desc-block-wrap .skill-desc-block h5,
    .skill-desc .skill-desc-block-wrap .skill-desc-block .h5 {
        margin-bottom: 10px
    }
}

.skill-desc .skill-desc-block-wrap .skill-desc-block p {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .skill-desc {
        -ms-flex-align: stretch;
        align-items: stretch
    }
    .skill-desc .skill-desc-block-wrap {
        width: 50%;
        height: 100%
    }
}

@media (max-width: 550px) {
    .skill-desc .skill-desc-block-wrap {
        width: 100%
    }
}

.preview-card {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px
}

@media (max-width: 1920px) {
    .preview-card {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .preview-card {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .preview-card {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .preview-card {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .preview-card {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .preview-card {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .preview-card {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .preview-card .preview-card-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .preview-card .preview-card-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .preview-card .preview-card-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .preview-card .preview-card-logo {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .preview-card .preview-card-logo {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .preview-card .preview-card-logo {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .preview-card .preview-card-logo {
        margin-bottom: 20px
    }
}

.preview-card .preview-card-logo img {
    border-radius: 20px;
    transition: all .2s
}

.preview-card .preview-card-logo img:hover {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25)
}

.preview-card .preview-card-desc h2,
.preview-card .preview-card-desc .h2 {
    line-height: 1em
}

@media (max-width: 1920px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .preview-card .preview-card-desc h2,
    .preview-card .preview-card-desc .h2,
    .preview-card .preview-card-desc p {
        margin-bottom: 20px
    }
}

.preview-card .preview-card-desc .button {
    margin-bottom: 0
}

.preview-card .preview-card-desc .benefits-list {
    margin-bottom: 0
}

.preview-card .preview-card-desc .benefits-list li:last-child p {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .preview-card .preview-card-desc .benefits-list p {
        margin-bottom: 5px
    }
}

.classes-preview-card {
    overflow: hidden;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    background: #f8f8fa;
    transition: all .2s
}

.classes-preview-card:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15)
}

.classes-preview-card:nth-child(5n+1) .classes-text .button::before {
    background: #5255c5
}

.classes-preview-card:nth-child(5n+1) .classes-text .button::after {
    background: #474aab
}

.classes-preview-card:nth-child(5n+2) .classes-text .button::before {
    background: #ff8b00
}

.classes-preview-card:nth-child(5n+2) .classes-text .button::after {
    background: #e07b21
}

.classes-preview-card:nth-child(5n+3) .classes-text .button::before {
    background: #ff5157
}

.classes-preview-card:nth-child(5n+3) .classes-text .button::after {
    background: #e04b4e
}

.classes-preview-card:nth-child(5n+4) .classes-text .button::before {
    background: #5dba3b
}

.classes-preview-card:nth-child(5n+4) .classes-text .button::after {
    background: #50a033
}

.classes-preview-card:nth-child(5n+5) .classes-text .button::before {
    background: #ffc000
}

.classes-preview-card:nth-child(5n+5) .classes-text .button::after {
    background: #e5ad00
}

.classes-preview-card .classes-logo {
    display: block
}

.classes-preview-card .classes-logo img {
    border-radius: 20px 20px 0 0
}

.classes-preview-card .classes-info-wrap {
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1920px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .classes-preview-card .classes-info-wrap {
        margin-bottom: 20px
    }
}

.classes-preview-card .classes-info-wrap .classes-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    max-width: 120px;
    margin-bottom: 0;
    padding-right: 5px;
    padding-left: 5px;
    background: #5255c5;
    transition: all .3s
}

@media (max-width: 1920px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 15px
    }
}

@media (max-width: 1600px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 15px
    }
}

@media (max-width: 1440px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 15px
    }
}

@media (max-width: 1199px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 15px
    }
}

@media (max-width: 991px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 15px
    }
}

@media (max-width: 767px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 10px
    }
}

@media (max-width: 480px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-top: 10px
    }
}

@media (max-width: 1920px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 15px
    }
}

@media (max-width: 991px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 15px
    }
}

@media (max-width: 767px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 10px
    }
}

@media (max-width: 480px) {
    .classes-preview-card .classes-info-wrap .classes-info {
        padding-bottom: 10px
    }
}

.classes-preview-card .classes-info-wrap .classes-info:hover {
    background: #474aab
}

.classes-preview-card .classes-info-wrap .classes-info::after {
    display: none
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+1) {
    background: #5dba3b
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+1):hover {
    background: #50a033
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+2) {
    background: #ff5157
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+2):hover {
    background: #e04b4e
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+3) {
    background: #5255c5
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+3):hover {
    background: #474aab
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+4) {
    background: #ff8b00
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+4):hover {
    background: #e07b21
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+5) {
    background: #ffc000
}

.classes-preview-card .classes-info-wrap .classes-info:nth-child(5n+5):hover {
    background: #e5ad00
}

.classes-preview-card .classes-info-wrap .classes-info h6,
.classes-preview-card .classes-info-wrap .classes-info .h6 {
    text-align: center;
    color: #fff;
    margin-bottom: 0
}

.classes-preview-card .classes-info-wrap .classes-info h3,
.classes-preview-card .classes-info-wrap .classes-info .h3 {
    margin-bottom: 0;
    text-align: center;
    color: #fff
}

.classes-preview-card .classes-info-wrap .classes-info p {
    margin-bottom: 0;
    text-align: center;
    color: #fff
}

.classes-preview-card .classes-text {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center
}

@media (max-width: 1920px) {
    .classes-preview-card .classes-text {
        padding-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .classes-preview-card .classes-text {
        padding-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .classes-preview-card .classes-text {
        padding-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .classes-preview-card .classes-text {
        padding-bottom: 30px
    }
}

@media (max-width: 991px) {
    .classes-preview-card .classes-text {
        padding-bottom: 25px
    }
}

@media (max-width: 767px) {
    .classes-preview-card .classes-text {
        padding-bottom: 20px
    }
}

@media (max-width: 480px) {
    .classes-preview-card .classes-text {
        padding-bottom: 20px
    }
}

.classes-preview-card .classes-text h3,
.classes-preview-card .classes-text .h3 {
    display: block;
    text-align: center
}

@media (max-width: 1920px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .classes-preview-card .classes-text h3,
    .classes-preview-card .classes-text .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1920px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .classes-preview-card .classes-text p {
        margin-bottom: 20px
    }
}

.classes-preview-card .classes-text .button {
    margin-bottom: 0
}

.slick-slide:nth-child(5n+1) .classes-preview-card .classes-text .button::before,
li:nth-child(5n+1) .classes-preview-card .classes-text .button::before {
    background: #5255c5
}

.slick-slide:nth-child(5n+1) .classes-preview-card .classes-text .button::after,
li:nth-child(5n+1) .classes-preview-card .classes-text .button::after {
    background: #474aab
}

.slick-slide:nth-child(5n+2) .classes-preview-card .classes-text .button::before,
li:nth-child(5n+2) .classes-preview-card .classes-text .button::before {
    background: #ff8b00
}

.slick-slide:nth-child(5n+2) .classes-preview-card .classes-text .button::after,
li:nth-child(5n+2) .classes-preview-card .classes-text .button::after {
    background: #e07b21
}

.slick-slide:nth-child(5n+3) .classes-preview-card .classes-text .button::before,
li:nth-child(5n+3) .classes-preview-card .classes-text .button::before {
    background: #ff5157
}

.slick-slide:nth-child(5n+3) .classes-preview-card .classes-text .button::after,
li:nth-child(5n+3) .classes-preview-card .classes-text .button::after {
    background: #e04b4e
}

.slick-slide:nth-child(5n+4) .classes-preview-card .classes-text .button::before,
li:nth-child(5n+4) .classes-preview-card .classes-text .button::before {
    background: #5dba3b
}

.slick-slide:nth-child(5n+4) .classes-preview-card .classes-text .button::after,
li:nth-child(5n+4) .classes-preview-card .classes-text .button::after {
    background: #50a033
}

.slick-slide:nth-child(5n+5) .classes-preview-card .classes-text .button::before,
li:nth-child(5n+5) .classes-preview-card .classes-text .button::before {
    background: #ffc000
}

.slick-slide:nth-child(5n+5) .classes-preview-card .classes-text .button::after,
li:nth-child(5n+5) .classes-preview-card .classes-text .button::after {
    background: #e5ad00
}

.schedule {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    background: #5dba3b;
    text-align: center;
    color: #fff;
    transition: all .2s
}

@media (max-width: 1920px) {
    .schedule {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .schedule {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .schedule {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .schedule {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .schedule {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .schedule {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .schedule {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .schedule {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .schedule {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .schedule {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .schedule {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .schedule {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .schedule {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .schedule {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .schedule {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .schedule {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .schedule {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .schedule {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .schedule {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .schedule {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .schedule {
        padding-bottom: 30px
    }
}

.schedule:hover {
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25)
}

.schedule.color-1 {
    background: #5255c5
}

.schedule.color-2 {
    background: #5dba3b
}

.schedule.color-3 {
    background: #ff8b00
}

.schedule.color-4 {
    background: #ff5157
}

.schedule.color-5 {
    background: #ffc000
}

.schedule h3,
.schedule .h3 {
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .schedule h3,
    .schedule .h3 {
        margin-bottom: 20px
    }
}

.schedule p {
    max-width: 280px;
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .schedule p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .schedule p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .schedule p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .schedule p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .schedule p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .schedule p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .schedule p {
        margin-bottom: 20px
    }
}

.schedule .schedule-list {
    width: 100%;
    max-width: 230px
}

@media (max-width: 1920px) {
    .schedule .schedule-list {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .schedule .schedule-list {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .schedule .schedule-list {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .schedule .schedule-list {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .schedule .schedule-list {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .schedule .schedule-list {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .schedule .schedule-list {
        margin-bottom: 30px
    }
}

.schedule .schedule-list li {
    padding-left: 0
}

.schedule .schedule-list li::after {
    display: none
}

.schedule .schedule-list li:last-child p {
    margin-bottom: 0
}

.schedule .schedule-list li p {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left
}

@media (max-width: 1920px) {
    .schedule .schedule-list li p {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .schedule .schedule-list li p {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .schedule .schedule-list li p {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .schedule .schedule-list li p {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .schedule .schedule-list li p {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .schedule .schedule-list li p {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .schedule .schedule-list li p {
        margin-bottom: 10px
    }
}

.schedule .button {
    margin-bottom: 0
}

.main-schedule {
    overflow-x: auto
}

@media (max-width: 1920px) {
    .main-schedule {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .main-schedule {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .main-schedule {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .main-schedule {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .main-schedule {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .main-schedule {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .main-schedule {
        margin-bottom: 30px
    }
}

.main-schedule .schedule-head {
    min-width: 720px;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    border-radius: 10px 10px 0 0
}

.main-schedule .schedule-head p {
    width: 16.66%;
    margin-bottom: 0;
    padding: 15px 0;
    background: #ddd;
    text-align: center
}

.main-schedule .schedule-body {
    min-width: 720px
}

.main-schedule .schedule-body .schedule-row {
    display: -ms-flexbox;
    display: flex
}

.main-schedule .schedule-body .schedule-row .schedule-block {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
    width: 16.66%;
    margin-bottom: 0;
    border: 1px solid #ddd;
    text-align: center;
    transition: all .25s
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-1 {
    border-color: #5255c5
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-1:hover {
    background: #5255c5
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-1:hover h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-1:hover .h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-1:hover p {
    color: #fff
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-2 {
    border-color: #5dba3b
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-2:hover {
    background: #5dba3b
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-2:hover h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-2:hover .h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-2:hover p {
    color: #fff
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-3 {
    border-color: #ff8b00
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-3:hover {
    background: #ff8b00
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-3:hover h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-3:hover .h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-3:hover p {
    color: #fff
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-4 {
    border-color: #ff5157
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-4:hover {
    background: #ff5157
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-4:hover h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-4:hover .h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-4:hover p {
    color: #fff
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-5 {
    border-color: #ffc000
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-5:hover {
    background: #ffc000
}

.main-schedule .schedule-body .schedule-row .schedule-block.color-5:hover h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-5:hover .h6,
.main-schedule .schedule-body .schedule-row .schedule-block.color-5:hover p {
    color: #fff
}

.main-schedule .schedule-body .schedule-row .schedule-block h6,
.main-schedule .schedule-body .schedule-row .schedule-block .h6 {
    transition: all .25s
}

@media (max-width: 1920px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .main-schedule .schedule-body .schedule-row .schedule-block h6,
    .main-schedule .schedule-body .schedule-row .schedule-block .h6 {
        margin-bottom: 5px
    }
}

.main-schedule .schedule-body .schedule-row .schedule-block p {
    margin-bottom: 0;
    transition: all .25s
}

.schedule-section h3,
.schedule-section .h3 {
    text-align: center
}

@media (max-width: 1920px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .schedule-section h3,
    .schedule-section .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1920px) {
    .home-content .home-preview {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-preview {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-preview {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-preview {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-preview {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-preview {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-preview {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-content .home-preview {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-preview {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-preview {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-preview {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-preview {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-preview {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-preview {
        padding-bottom: 30px
    }
}

.home-content .home-team {
    position: relative
}

@media (max-width: 1920px) {
    .home-content .home-team {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-team {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-team {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-team {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-team {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-team {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-team {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-content .home-team {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-team {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-team {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-team {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-team {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-team {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-team {
        padding-bottom: 30px
    }
}

.home-content .home-team .container {
    position: relative;
    z-index: 10
}

@media (max-width: 1920px) {
    .home-content .home-team .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-team .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-team .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-team .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-team .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-team .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-team .container {
        padding-top: 30px
    }
}

.home-content .home-team h2,
.home-content .home-team .h2 {
    text-align: center
}

@media (max-width: 1920px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-team h2,
    .home-content .home-team .h2 {
        margin-bottom: 30px
    }
}

.home-content .home-team .moving-element {
    z-index: 0
}

.home-content .home-team .moving-element.one {
    top: 10%;
    left: 10%
}

.home-content .home-team .moving-element.two {
    top: 55%;
    left: 90%
}

.home-content .home-gallery {
    position: relative;
    text-align: center;
    background-image: url(../img/absurdity.png);
    background-color: #ffc000
}

@media (max-width: 1920px) {
    .home-content .home-gallery {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-gallery {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-gallery {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-gallery {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-gallery {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-gallery {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-gallery {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-content .home-gallery {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-gallery {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-gallery {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-gallery {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-gallery {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-gallery {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-gallery {
        padding-bottom: 30px
    }
}

.home-content .home-gallery.color-1 {
    background-color: #5255c5
}

.home-content .home-gallery.color-2 {
    background-color: #5dba3b
}

.home-content .home-gallery.color-3 {
    background-color: #ff8b00
}

.home-content .home-gallery.color-4 {
    background-color: #ff5157
}

.home-content .home-gallery.color-5 {
    background-color: #ffc000
}

.home-content .home-gallery .gallery-wrap {
    margin-bottom: -30px
}

@media (max-width: 1920px) {
    .home-content .home-gallery .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-gallery .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-gallery .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-gallery .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-gallery .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-gallery .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-gallery .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-content .home-gallery .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-gallery .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-gallery .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-gallery .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-gallery .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-gallery .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-gallery .container {
        padding-bottom: 30px
    }
}

.home-content .home-gallery h2,
.home-content .home-gallery .h2 {
    color: #fff
}

@media (max-width: 1920px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-gallery h2,
    .home-content .home-gallery .h2 {
        margin-bottom: 30px
    }
}

.home-content .home-gallery .button {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .home-content .home-gallery .button {
        margin-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-content .home-gallery .button {
        margin-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-content .home-gallery .button {
        margin-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-content .home-gallery .button {
        margin-top: 45px
    }
}

@media (max-width: 991px) {
    .home-content .home-gallery .button {
        margin-top: 40px
    }
}

@media (max-width: 767px) {
    .home-content .home-gallery .button {
        margin-top: 30px
    }
}

@media (max-width: 480px) {
    .home-content .home-gallery .button {
        margin-top: 30px
    }
}

.home-content .home-gallery .bottom-img {
    position: absolute;
    right: 10%;
    bottom: 0
}

@media (max-width: 480px) {
    .home-content .home-gallery .bottom-img {
        display: none
    }
}

@media (max-width: 1440px) {
    .home-content .home-team .moving-element {
        height: 60px
    }
    .home-content .home-team .moving-element.one {
        top: 20%
    }
    .home-content .home-team .moving-element.two {
        top: 50%;
        left: 65%
    }
}

@media (max-width: 1199px) {
    .home-content .home-team .moving-element.one {
        top: 36%;
        left: 25%
    }
    .home-content .home-team .moving-element.two {
        top: 63%;
        left: 75%
    }
}

.home-enroll {
    background-image: url(../img/absurdity.png);
    background-color: #5dba3b
}

@media (max-width: 1920px) {
    .home-enroll {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-enroll {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-enroll {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-enroll {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-enroll {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-enroll {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-enroll {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-enroll {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-enroll {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-enroll {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-enroll {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-enroll {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-enroll {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-enroll {
        padding-bottom: 30px
    }
}

.home-enroll.color-1 {
    background-color: #5255c5
}

.home-enroll.color-2 {
    background-color: #5dba3b
}

.home-enroll.color-3 {
    background-color: #ff8b00
}

.home-enroll.color-4 {
    background-color: #ff5157
}

.home-enroll.color-5 {
    background-color: #ffc000
}

.home-enroll .container {
    text-align: center
}

@media (max-width: 1920px) {
    .home-enroll .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .home-enroll .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .home-enroll .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .home-enroll .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .home-enroll .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .home-enroll .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .home-enroll .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .home-enroll .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .home-enroll .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .home-enroll .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .home-enroll .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .home-enroll .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .home-enroll .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .home-enroll .container {
        padding-bottom: 30px
    }
}

.home-enroll .container h2,
.home-enroll .container .h2,
.home-enroll .container p {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .home-enroll .container h2,
    .home-enroll .container .h2,
    .home-enroll .container p {
        margin-bottom: 20px
    }
}

.home-enroll .container .button {
    margin-bottom: 0
}

@media (max-width: 480px) {
    .home-enroll .container p {
        text-align: justify
    }
}

@media (max-width: 1920px) {
    .single-team-article {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .single-team-article {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .single-team-article {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .single-team-article {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .single-team-article {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .single-team-article {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .single-team-article {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .single-team-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .single-team-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .single-team-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .single-team-article {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .single-team-article {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .single-team-article {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .single-team-article {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .single-team-article .team-post {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .single-team-article .team-post {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .single-team-article .team-post {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .single-team-article .team-post {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .single-team-article .team-post {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .single-team-article .team-post {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .single-team-article .team-post {
        padding-top: 30px
    }
}

.single-team-article .team-post img {
    float: left;
    margin-right: 30px;
    border-radius: 20px
}

@media (max-width: 1920px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .single-team-article .team-post .team-desc {
        margin-bottom: 15px
    }
}

.single-team-article .team-post .team-desc h2,
.single-team-article .team-post .team-desc .h2 {
    text-align: left
}

@media (max-width: 1920px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        margin-bottom: 5px
    }
}

.single-team-article .team-post .team-desc h4,
.single-team-article .team-post .team-desc .h4 {
    text-align: left
}

@media (max-width: 1920px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        margin-bottom: 10px
    }
}

@media (max-width: 1920px) {
    .single-team-article .team-post .soc-list {
        padding-top: 15px
    }
}

@media (max-width: 1600px) {
    .single-team-article .team-post .soc-list {
        padding-top: 15px
    }
}

@media (max-width: 1440px) {
    .single-team-article .team-post .soc-list {
        padding-top: 15px
    }
}

@media (max-width: 1199px) {
    .single-team-article .team-post .soc-list {
        padding-top: 15px
    }
}

@media (max-width: 991px) {
    .single-team-article .team-post .soc-list {
        padding-top: 15px
    }
}

@media (max-width: 767px) {
    .single-team-article .team-post .soc-list {
        padding-top: 10px
    }
}

@media (max-width: 480px) {
    .single-team-article .team-post .soc-list {
        padding-top: 10px
    }
}

.single-team-article .team-post .soc-list li a {
    font-size: 25px;
    color: #ff5157
}

.single-team-article .team-post .soc-list li a:hover {
    color: #e04b4e
}

.single-team-article .team-post .team-text p:last-child {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .single-team-article .team-post img {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px
    }
    .single-team-article .team-post .team-desc {
        text-align: center
    }
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2,
    .single-team-article .team-post .team-desc h4,
    .single-team-article .team-post .team-desc .h4 {
        text-align: center
    }
    .single-team-article .team-post .team-desc .post-info,
    .single-team-article .team-post .team-desc .soc-list {
        -ms-flex-pack: center;
        justify-content: center
    }
    .single-team-article .team-post .team-text {
        text-align: center
    }
}

@media (max-width: 767px) {
    .single-team-article .team-post .team-desc h2,
    .single-team-article .team-post .team-desc .h2 {
        text-align: center
    }
    .single-team-article .team-post .team-text p {
        text-align: justify
    }
}

@media (max-width: 991px) {
    .single-team-content .progress-skill h3,
    .single-team-content .progress-skill .h3 {
        text-align: center
    }
}

.single-team-content .team-slider-section .container {
    padding-top: 0
}

.event-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    max-width: 555px;
    margin-left: auto;
    margin-right: auto
}

.event-card:nth-child(4n+1) .event-logo time {
    background: #5dba3b
}

.event-card:nth-child(4n+1) .event-info-list li p i,
.event-card:nth-child(4n+1) .event-info-list li a:hover {
    color: #5dba3b
}

.event-card:nth-child(4n+2) .event-logo time {
    background: #ff5157
}

.event-card:nth-child(4n+2) .event-info-list li p i,
.event-card:nth-child(4n+2) .event-info-list li a:hover {
    color: #ff5157
}

.event-card:nth-child(4n+3) .event-logo time {
    background: #5255c5
}

.event-card:nth-child(4n+3) .event-info-list li p i,
.event-card:nth-child(4n+3) .event-info-list li a:hover {
    color: #5255c5
}

.event-card:nth-child(4n+4) .event-logo time {
    background: #ff8b00
}

.event-card:nth-child(4n+4) .event-info-list li p i,
.event-card:nth-child(4n+4) .event-info-list li a:hover {
    color: #ff8b00
}

.event-card .event-logo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-width: 305px;
    margin-right: 15px;
    text-decoration: none
}

.event-card .event-logo img {
    border-radius: 15px
}

.event-card .event-logo time {
    position: relative;
    z-index: 10;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: -40px;
    border-radius: 50%;
    background: #5dba3b;
    font-style: normal;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    color: #fff
}

.event-card figcaption h4,
.event-card figcaption .h4 {
    transition: all .2s
}

@media (max-width: 1920px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .event-card figcaption h4,
    .event-card figcaption .h4 {
        margin-bottom: 15px
    }
}

.event-card figcaption h4:hover,
.event-card figcaption .h4:hover {
    text-decoration: none;
    color: #5255c5
}

@media (max-width: 1920px) {
    .event-card figcaption p {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .event-card figcaption p {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .event-card figcaption p {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .event-card figcaption p {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .event-card figcaption p {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .event-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .event-card {
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 260px;
        text-align: center
    }
    .event-card .event-logo {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        margin-bottom: 20px
    }
    .event-card .event-logo time {
        margin-right: 0;
        margin-top: -40px
    }
    .event-card p {
        text-align: justify
    }
}

.slick-slide:nth-child(4n+1) .event-card .event-logo time {
    background: #5dba3b
}

.slick-slide:nth-child(4n+1) .event-card .event-info-list li p i,
.slick-slide:nth-child(4n+1) .event-card .event-info-list li a:hover {
    color: #5dba3b
}

.slick-slide:nth-child(4n+2) .event-card .event-logo time {
    background: #ff5157
}

.slick-slide:nth-child(4n+2) .event-card .event-info-list li p i,
.slick-slide:nth-child(4n+2) .event-card .event-info-list li a:hover {
    color: #ff5157
}

.slick-slide:nth-child(4n+3) .event-card .event-logo time {
    background: #5255c5
}

.slick-slide:nth-child(4n+3) .event-card .event-info-list li p i,
.slick-slide:nth-child(4n+3) .event-card .event-info-list li a:hover {
    color: #5255c5
}

.slick-slide:nth-child(4n+4) .event-card .event-logo time {
    background: #ff8b00
}

.slick-slide:nth-child(4n+4) .event-card .event-info-list li p i,
.slick-slide:nth-child(4n+4) .event-card .event-info-list li a:hover {
    color: #ff8b00
}

@media (max-width: 1920px) {
    .event-article {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .event-article {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .event-article {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .event-article {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .event-article {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .event-article {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .event-article {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .event-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .event-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .event-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .event-article {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .event-article {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .event-article {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .event-article {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .event-article .event-post {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .event-article .event-post {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .event-article .event-post {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .event-article .event-post {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .event-article .event-post {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .event-article .event-post {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .event-article .event-post {
        padding-top: 30px
    }
}

.event-article .event-post>img {
    float: left;
    margin-right: 30px;
    border-radius: 20px
}

@media (max-width: 1920px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        margin-bottom: 5px
    }
}

.event-article .event-desc time {
    display: block;
    font-style: normal;
    color: #090605
}

@media (max-width: 1920px) {
    .event-article .event-desc time {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .event-article .event-desc time {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .event-article .event-desc time {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .event-article .event-desc time {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .event-article .event-desc time {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .event-article .event-desc time {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .event-article .event-desc time {
        margin-bottom: 10px
    }
}

@media (max-width: 1920px) {
    .event-article .event-text {
        padding-top: 20px
    }
}

@media (max-width: 1600px) {
    .event-article .event-text {
        padding-top: 20px
    }
}

@media (max-width: 1440px) {
    .event-article .event-text {
        padding-top: 20px
    }
}

@media (max-width: 1199px) {
    .event-article .event-text {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    .event-article .event-text {
        padding-top: 20px
    }
}

@media (max-width: 767px) {
    .event-article .event-text {
        padding-top: 15px
    }
}

@media (max-width: 480px) {
    .event-article .event-text {
        padding-top: 15px
    }
}

@media (max-width: 1920px) {
    .event-article .event-text {
        margin-bottom: -20px
    }
}

@media (max-width: 1600px) {
    .event-article .event-text {
        margin-bottom: -20px
    }
}

@media (max-width: 1440px) {
    .event-article .event-text {
        margin-bottom: -20px
    }
}

@media (max-width: 1199px) {
    .event-article .event-text {
        margin-bottom: -20px
    }
}

@media (max-width: 991px) {
    .event-article .event-text {
        margin-bottom: -20px
    }
}

@media (max-width: 767px) {
    .event-article .event-text {
        margin-bottom: -15px
    }
}

@media (max-width: 480px) {
    .event-article .event-text {
        margin-bottom: -15px
    }
}

@media (max-width: 1920px) {
    .event-article .event-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .event-article .event-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .event-article .event-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .event-article .event-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .event-article .event-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .event-article .event-text p {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .event-article .event-text p {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    .event-article .event-post>img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    .event-article .event-post>img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    .event-article .event-post>img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    .event-article .event-post>img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    .event-article .event-post>img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    .event-article .event-post>img {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    .event-article .event-post>img {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .event-article .event-post>img {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto
    }
    .event-article .event-desc h2,
    .event-article .event-desc .h2 {
        text-align: center
    }
    .event-article .event-desc time {
        text-align: center
    }
    .event-article .post-info {
        -ms-flex-pack: center;
        justify-content: center
    }
    .event-article .event-text {
        text-align: center
    }
}

@media (max-width: 480px) {
    .event-article .event-text p {
        text-align: justify
    }
}

.single-event-content .events-slider-section .container {
    padding-top: 0
}

@media (max-width: 1920px) {
    .events-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .events-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .events-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .events-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .events-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .events-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .events-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .events-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .events-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .events-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .events-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .events-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .events-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .events-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .events-content .event-list-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .events-content .event-list-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .events-content .event-list-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .events-content .event-list-wrap {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .events-content .event-list-wrap {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .events-content .event-list-wrap {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .events-content .event-list-wrap {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .events-content .event-list-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .events-content .event-list-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .events-content .event-list-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .events-content .event-list-wrap {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .events-content .event-list-wrap {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .events-content .event-list-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .events-content .event-list-wrap {
        padding-bottom: 30px
    }
}

.events-content .main-title p {
    max-width: 875px
}

.post-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (max-width: 1920px) {
    .post-info {
        margin-bottom: -10px
    }
}

@media (max-width: 1600px) {
    .post-info {
        margin-bottom: -10px
    }
}

@media (max-width: 1440px) {
    .post-info {
        margin-bottom: -10px
    }
}

@media (max-width: 1199px) {
    .post-info {
        margin-bottom: -10px
    }
}

@media (max-width: 991px) {
    .post-info {
        margin-bottom: -10px
    }
}

@media (max-width: 767px) {
    .post-info {
        margin-bottom: -5px
    }
}

@media (max-width: 480px) {
    .post-info {
        margin-bottom: -5px
    }
}

.post-info.color-1 li p i,
.post-info.color-1 li a i {
    color: #5255c5
}

.post-info.color-2 li p i,
.post-info.color-2 li a i {
    color: #5dba3b
}

.post-info.color-3 li p i,
.post-info.color-3 li a i {
    color: #ff8b00
}

.post-info.color-4 li p i,
.post-info.color-4 li a i {
    color: #ff5157
}

.post-info.color-5 li p i,
.post-info.color-5 li a i {
    color: #ffc000
}

.post-info li {
    margin-right: 15px;
    padding-left: 0
}

@media (max-width: 1920px) {
    .post-info li {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .post-info li {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .post-info li {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .post-info li {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .post-info li {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .post-info li {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .post-info li {
        margin-bottom: 5px
    }
}

.post-info li::after {
    display: none
}

.post-info li:last-child {
    margin-right: 0
}

.post-info li p,
.post-info li a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    line-height: 1.3em
}

.post-info li p i,
.post-info li a i {
    margin-right: 5px;
    font-size: 20px;
    line-height: 1.3em;
    color: #ff5157
}

.post-info li a {
    text-decoration: none;
    font-size: 15px;
    color: #777
}

.post-info li a:hover {
    color: #090605
}

.post-info li p {
    font-size: 17px;
    color: #090605
}

.post-info li p span {
    padding-left: 5px;
    font-size: 15px;
    color: #777
}

.post-info li p a {
    padding-left: 5px
}

@media (max-width: 1920px) {
    .blog-list {
        margin-bottom: -40px
    }
}

@media (max-width: 1600px) {
    .blog-list {
        margin-bottom: -40px
    }
}

@media (max-width: 1440px) {
    .blog-list {
        margin-bottom: -40px
    }
}

@media (max-width: 1199px) {
    .blog-list {
        margin-bottom: -40px
    }
}

@media (max-width: 991px) {
    .blog-list {
        margin-bottom: -35px
    }
}

@media (max-width: 767px) {
    .blog-list {
        margin-bottom: -25px
    }
}

@media (max-width: 480px) {
    .blog-list {
        margin-bottom: -25px
    }
}

.blog-post {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

@media (max-width: 1920px) {
    .blog-post {
        margin-bottom: 40px
    }
}

@media (max-width: 1600px) {
    .blog-post {
        margin-bottom: 40px
    }
}

@media (max-width: 1440px) {
    .blog-post {
        margin-bottom: 40px
    }
}

@media (max-width: 1199px) {
    .blog-post {
        margin-bottom: 40px
    }
}

@media (max-width: 991px) {
    .blog-post {
        margin-bottom: 35px
    }
}

@media (max-width: 767px) {
    .blog-post {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) {
    .blog-post {
        margin-bottom: 25px
    }
}

.blog-post:nth-child(4n+4) .blog-prview .blog-mini-info {
    background: #5255c5
}

.blog-post:nth-child(4n+4) .blog-text h2:hover,
.blog-post:nth-child(4n+4) .blog-text .h2:hover {
    color: #5255c5
}

.blog-post:nth-child(4n+4) .blog-text .button::before {
    background: #5255c5
}

.blog-post:nth-child(4n+4) .blog-text .button::after {
    background: #474aab
}

.blog-post:nth-child(4n+4) .blog-info-list li p i {
    color: #5255c5
}

.blog-post:nth-child(4n+4) .blog-info-list li a:hover {
    color: #5255c5
}

.blog-post:nth-child(4n+4) .audioplayer-bar-played,
.blog-post:nth-child(4n+4) .audioplayer-volume-adjust div div {
    box-shadow: none;
    background: #5255c5
}

.blog-post:nth-child(4n+4).blockquote-post .blog-text>i,
.blog-post:nth-child(4n+4).blockquote-post .read-more:hover,
.blog-post:nth-child(4n+4).link-post .blog-text>i,
.blog-post:nth-child(4n+4).link-post .read-more:hover {
    color: #5255c5
}

.blog-post:nth-child(4n+4).blockquote-post .link-ico,
.blog-post:nth-child(4n+4).link-post .link-ico {
    background: #5255c5
}

.blog-post:nth-child(4n+4).video-post .blog-prview>a:hover .video-play-ico {
    color: #5255c5
}

.blog-post:nth-child(4n+4).type-slider-post .slick-arrow {
    background: #5255c5
}

.blog-post:nth-child(4n+4).type-slider-post .slick-arrow:hover {
    background: #474aab
}

.blog-post:nth-child(4n+1) .blog-prview .blog-mini-info {
    background: #5dba3b
}

.blog-post:nth-child(4n+1) .blog-text h2:hover,
.blog-post:nth-child(4n+1) .blog-text .h2:hover {
    color: #5dba3b
}

.blog-post:nth-child(4n+1) .blog-text .button::before {
    background: #5dba3b
}

.blog-post:nth-child(4n+1) .blog-text .button::after {
    background: #50a033
}

.blog-post:nth-child(4n+1) .blog-info-list li p i {
    color: #5dba3b
}

.blog-post:nth-child(4n+1) .blog-info-list li a:hover {
    color: #5dba3b
}

.blog-post:nth-child(4n+1) .audioplayer-bar-played,
.blog-post:nth-child(4n+1) .audioplayer-volume-adjust div div {
    box-shadow: none;
    background: #5dba3b
}

.blog-post:nth-child(4n+1).blockquote-post .blog-text>i,
.blog-post:nth-child(4n+1).blockquote-post .read-more:hover,
.blog-post:nth-child(4n+1).link-post .blog-text>i,
.blog-post:nth-child(4n+1).link-post .read-more:hover {
    color: #5dba3b
}

.blog-post:nth-child(4n+1).blockquote-post .link-ico,
.blog-post:nth-child(4n+1).link-post .link-ico {
    background: #5dba3b
}

.blog-post:nth-child(4n+1).video-post .blog-prview>a:hover .video-play-ico {
    color: #5dba3b
}

.blog-post:nth-child(4n+1).type-slider-post .slick-arrow {
    background: #5dba3b
}

.blog-post:nth-child(4n+1).type-slider-post .slick-arrow:hover {
    background: #50a033
}

.blog-post:nth-child(4n+3) .blog-prview .blog-mini-info {
    background: #ff8b00
}

.blog-post:nth-child(4n+3) .blog-text h2:hover,
.blog-post:nth-child(4n+3) .blog-text .h2:hover {
    color: #ff8b00
}

.blog-post:nth-child(4n+3) .blog-text .button::before {
    background: #ff8b00
}

.blog-post:nth-child(4n+3) .blog-text .button::after {
    background: #e07b21
}

.blog-post:nth-child(4n+3) .blog-info-list li p i {
    color: #ff8b00
}

.blog-post:nth-child(4n+3) .blog-info-list li a:hover {
    color: #ff8b00
}

.blog-post:nth-child(4n+3) .audioplayer-bar-played,
.blog-post:nth-child(4n+3) .audioplayer-volume-adjust div div {
    box-shadow: none;
    background: #ff8b00
}

.blog-post:nth-child(4n+3).blockquote-post .blog-text>i,
.blog-post:nth-child(4n+3).blockquote-post .read-more:hover,
.blog-post:nth-child(4n+3).link-post .blog-text>i,
.blog-post:nth-child(4n+3).link-post .read-more:hover {
    color: #ff8b00
}

.blog-post:nth-child(4n+3).blockquote-post .link-ico,
.blog-post:nth-child(4n+3).link-post .link-ico {
    background: #ff8b00
}

.blog-post:nth-child(4n+3).video-post .blog-prview>a:hover .video-play-ico {
    color: #ff8b00
}

.blog-post:nth-child(4n+3).type-slider-post .slick-arrow {
    background: #ff8b00
}

.blog-post:nth-child(4n+3).type-slider-post .slick-arrow:hover {
    background: #e07b21
}

.blog-post:nth-child(4n+2) .blog-prview .blog-mini-info {
    background: #ff5157
}

.blog-post:nth-child(4n+2) .blog-text h2:hover,
.blog-post:nth-child(4n+2) .blog-text .h2:hover {
    color: #ff5157
}

.blog-post:nth-child(4n+2) .blog-text .button::before {
    background: #ff5157
}

.blog-post:nth-child(4n+2) .blog-text .button::after {
    background: #e04b4e
}

.blog-post:nth-child(4n+2) .blog-info-list li p i {
    color: #ff5157
}

.blog-post:nth-child(4n+2) .blog-info-list li a:hover {
    color: #ff5157
}

.blog-post:nth-child(4n+2) .audioplayer-bar-played,
.blog-post:nth-child(4n+2) .audioplayer-volume-adjust div div {
    box-shadow: none;
    background: #ff5157
}

.blog-post:nth-child(4n+2).blockquote-post .blog-text>i,
.blog-post:nth-child(4n+2).blockquote-post .read-more:hover,
.blog-post:nth-child(4n+2).link-post .blog-text>i,
.blog-post:nth-child(4n+2).link-post .read-more:hover {
    color: #ff5157
}

.blog-post:nth-child(4n+2).blockquote-post .link-ico,
.blog-post:nth-child(4n+2).link-post .link-ico {
    background: #ff5157
}

.blog-post:nth-child(4n+2).video-post .blog-prview>a:hover .video-play-ico {
    color: #ff5157
}

.blog-post:nth-child(4n+2).type-slider-post .slick-arrow {
    background: #ff5157
}

.blog-post:nth-child(4n+2).type-slider-post .slick-arrow:hover {
    background: #e04b4e
}

.blog-post.type-slider-post .slick-arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%
}

.blog-post.type-slider-post .slick-arrow::after {
    content: '\f054';
    font-size: 26px
}

.blog-post.type-slider-post .slick-arrow.slick-prev {
    left: 30px
}

.blog-post.type-slider-post .slick-arrow.slick-next {
    right: 30px
}

.blog-post.type-slider-post .blog-prview-slider {
    margin-bottom: -5px
}

@media (max-width: 480px) {
    .blog-post.type-slider-post .slick-arrow {
        width: 30px;
        height: 30px
    }
    .blog-post.type-slider-post .slick-arrow::after {
        font-size: 24px
    }
    .blog-post.type-slider-post .slick-arrow.slick-prev {
        left: 0
    }
    .blog-post.type-slider-post .slick-arrow.slick-next {
        right: 0
    }
}

.blog-post.blockquote-post .blog-text,
.blog-post.link-post .blog-text {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    border-radius: 40px;
    border: 1px solid #d8d8d8;
    background-color: transparent
}

.blog-post.blockquote-post .blog-text .blog-info-list,
.blog-post.link-post .blog-text .blog-info-list {
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: -13px;
    padding-left: 20px;
    background: #fff
}

@media (max-width: 1920px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-bottom: 15px
    }
}

.blog-post.blockquote-post .blog-text h4,
.blog-post.blockquote-post .blog-text .h4,
.blog-post.link-post .blog-text h4,
.blog-post.link-post .blog-text .h4 {
    max-width: 510px;
    text-align: center
}

@media (max-width: 1920px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .blog-post.blockquote-post .blog-text h2,
    .blog-post.blockquote-post .blog-text .h2,
    .blog-post.link-post .blog-text h2,
    .blog-post.link-post .blog-text .h2 {
        margin-bottom: 15px
    }
}

.blog-post.blockquote-post .blog-text h6,
.blog-post.blockquote-post .blog-text .h6,
.blog-post.link-post .blog-text h6,
.blog-post.link-post .blog-text .h6 {
    font-style: italic
}

.blog-post.blockquote-post .blog-text>i,
.blog-post.link-post .blog-text>i {
    font-size: 45px
}

.blog-post.blockquote-post .blog-text .read-more,
.blog-post.link-post .blog-text .read-more {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 0 10px;
    background: #fff;
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%)
}

@media (max-width: 1920px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 30px
    }
}

@media (max-width: 1600px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 30px
    }
}

@media (max-width: 1440px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 30px
    }
}

@media (max-width: 1199px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 30px
    }
}

@media (max-width: 991px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 25px
    }
}

@media (max-width: 767px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 20px
    }
}

@media (max-width: 480px) {
    .blog-post.blockquote-post .blog-text .read-more,
    .blog-post.link-post .blog-text .read-more {
        margin-top: 20px
    }
}

@media (max-width: 991px) and (max-width: 1920px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 991px) and (max-width: 1600px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 991px) and (max-width: 1440px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 991px) and (max-width: 1199px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 25px
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 20px
    }
}

@media (max-width: 991px) and (max-width: 480px) {
    .blog-post.blockquote-post .blog-text,
    .blog-post.link-post .blog-text {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    .blog-post.blockquote-post .blog-text .blog-info-list,
    .blog-post.link-post .blog-text .blog-info-list {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0
    }
}

.blog-post.link-post .link-ico {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #5255c5
}

.blog-post.link-post .link-ico i {
    font-size: 18px;
    color: #fff
}

.blog-post.video-post .blog-prview>a {
    position: relative
}

.blog-post.video-post .blog-prview>a:hover .video-play-ico {
    color: #5255c5
}

.blog-post.video-post .blog-prview>a .video-play-ico {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 70px;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #fff;
    transition: all .2s
}

.blog-post.video-post .video-info {
    position: absolute;
    top: 0;
    left: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    padding: 7px 40px;
    color: #fff
}

.blog-post.video-post .video-info p {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff
}

.blog-post.video-post .video-info span {
    margin-right: -10px
}

.blog-post.video-post .video-info i {
    margin-right: 10px;
    font-size: 20px
}

.blog-post .blog-prview {
    position: relative
}

.blog-post .blog-prview .blog-mini-info {
    position: absolute;
    top: 40px;
    left: 40px;
    z-index: 10;
    overflow: hidden;
    height: 70px;
    width: 70px;
    padding: 15px 10px;
    border-radius: 35px;
    background: #5255c5;
    transition: all .3s
}

.blog-post .blog-prview .blog-mini-info:hover {
    height: 140px
}

.blog-post .blog-prview .blog-mini-info h5,
.blog-post .blog-prview .blog-mini-info .h5 {
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-prview .blog-mini-info h5,
    .blog-post .blog-prview .blog-mini-info .h5 {
        margin-bottom: 10px
    }
}

.blog-post .blog-prview .blog-mini-info h6,
.blog-post .blog-prview .blog-mini-info .h6 {
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-prview .blog-mini-info h6,
    .blog-post .blog-prview .blog-mini-info .h6 {
        margin-bottom: 10px
    }
}

.blog-post .blog-prview .blog-mini-info h6 p,
.blog-post .blog-prview .blog-mini-info .h6 p {
    color: #fff
}

.blog-post .blog-prview .blog-mini-info .coment-info h6,
.blog-post .blog-prview .blog-mini-info .coment-info .h6 {
    margin-bottom: 0
}

.blog-post .blog-prview img {
    border-radius: 20px 20px 0 0
}

@media (max-width: 991px) {
    .blog-post .blog-prview .blog-mini-info {
        top: 20px;
        left: 20px
    }
}

.blog-post .blog-text {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 0 0 20px 20px;
    background: #f8f8fc
}

@media (max-width: 1920px) {
    .blog-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-text {
        padding-top: 30px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-text {
        padding-top: 25px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-text {
        padding-top: 20px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-text {
        padding-top: 20px
    }
}

@media (max-width: 1920px) {
    .blog-post .blog-text {
        padding-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-text {
        padding-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-text {
        padding-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-text {
        padding-bottom: 30px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-text {
        padding-bottom: 25px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-text {
        padding-bottom: 20px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-text {
        padding-bottom: 20px
    }
}

.blog-post .blog-text h2,
.blog-post .blog-text .h2 {
    transition: all .2s;
    text-decoration: none
}

@media (max-width: 1920px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-text h2,
    .blog-post .blog-text .h2 {
        margin-bottom: 5px
    }
}

.blog-post .blog-text h2:hover,
.blog-post .blog-text .h2:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    .blog-post .blog-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-text p {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-text p {
        margin-bottom: 15px
    }
}

.blog-post .blog-text .button {
    margin-bottom: 0
}

@media (max-width: 480px) {
    .blog-post .blog-text p {
        max-height: 200px;
        overflow: hidden;
        text-align: justify
    }
}

.blog-post .blog-info-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0
}

.blog-post .blog-info-list li {
    margin-right: 20px;
    padding-left: 0
}

@media (max-width: 1920px) {
    .blog-post .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .blog-post .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .blog-post .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .blog-post .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .blog-post .blog-info-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .blog-post .blog-info-list li {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .blog-post .blog-info-list li {
        margin-bottom: 10px
    }
}

.blog-post .blog-info-list li::after {
    display: none
}

.blog-post .blog-info-list li p,
.blog-post .blog-info-list li a {
    margin-bottom: 0;
    color: #777;
    text-decoration: none
}

.blog-post .blog-info-list li p i,
.blog-post .blog-info-list li a i {
    margin-right: 10px;
    font-size: 16px;
    color: #5255c5
}

.blog-post .blog-info-list li a:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    .single-post-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .single-post-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .single-post-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .single-post-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .single-post-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .single-post-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .single-post-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .single-post-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .single-post-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .single-post-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .single-post-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .single-post-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .single-post-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .single-post-content {
        padding-bottom: 30px
    }
}

.single-post-content.color-1 .single-post-article .soc-list li a {
    color: #5255c5
}

.single-post-content.color-1 .single-post-article .soc-list li a:hover {
    color: #474aab
}

.single-post-content.color-1 .blog-mini-info {
    background: #5255c5
}

.single-post-content.color-2 .single-post-article .soc-list li a {
    color: #5dba3b
}

.single-post-content.color-2 .single-post-article .soc-list li a:hover {
    color: #50a033
}

.single-post-content.color-2 .blog-mini-info {
    background: #5dba3b
}

.single-post-content.color-3 .single-post-article .soc-list li a {
    color: #ff8b00
}

.single-post-content.color-3 .single-post-article .soc-list li a:hover {
    color: #e07b21
}

.single-post-content.color-3 .blog-mini-info {
    background: #ff8b00
}

.single-post-content.color-4 .single-post-article .soc-list li a {
    color: #ff5157
}

.single-post-content.color-4 .single-post-article .soc-list li a:hover {
    color: #e04b4e
}

.single-post-content.color-4 .blog-mini-info {
    background: #ff5157
}

.single-post-content.color-5 .single-post-article .soc-list li a {
    color: #ffc000
}

.single-post-content.color-5 .single-post-article .soc-list li a:hover {
    color: #e5ad00
}

.single-post-content.color-5 .blog-mini-info {
    background: #ffc000
}

@media (max-width: 1920px) {
    .single-post-content .single-post-article {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .single-post-content .single-post-article {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .single-post-content .single-post-article {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .single-post-content .single-post-article {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .single-post-content .single-post-article {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .single-post-content .single-post-article {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article {
        padding-top: 30px
    }
}

.single-post-content .single-post-article .single-article-logo {
    position: relative
}

@media (max-width: 1920px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 30px
    }
}

@media (max-width: 991px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 25px
    }
}

@media (max-width: 767px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 20px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article .single-article-logo {
        padding-bottom: 20px
    }
}

.single-post-content .single-post-article .single-article-logo img {
    border-radius: 20px
}

.single-post-content .single-post-article .article-text {
    border-bottom: 1px solid #d8d8d8
}

@media (max-width: 1920px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article .article-text {
        margin-bottom: 30px
    }
}

.single-post-content .single-post-article .article-text h2,
.single-post-content .single-post-article .article-text .h2 {
    margin-bottom: 0
}

.single-post-content .single-post-article .article-text .article-text-info {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article .article-text .article-text-info {
        margin-bottom: 15px
    }
}

@media (max-width: 1920px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article .article-text .article-text-info .blog-info-list,
    .single-post-content .single-post-article .article-text .article-text-info .soc-list-wrap {
        padding-top: 5px
    }
}

.single-post-content .single-post-article .article-text .soc-list-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.single-post-content .single-post-article .article-text .soc-list-wrap p {
    margin-right: 15px;
    margin-bottom: 0;
    color: #090605
}

.single-post-content .single-post-article .article-text img {
    float: left;
    margin-right: 30px;
    margin-bottom: 20px
}

@media (max-width: 1920px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -15px
    }
}

@media (max-width: 1600px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -15px
    }
}

@media (max-width: 1440px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -15px
    }
}

@media (max-width: 1199px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -15px
    }
}

@media (max-width: 991px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -15px
    }
}

@media (max-width: 767px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -10px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article .blog-info-list {
        margin-bottom: -10px
    }
}

.single-post-content .single-post-article .soc-list li a {
    font-size: 18px;
    color: #5255c5
}

.single-post-content .single-post-article .soc-list li a:hover {
    color: #474aab
}

@media (max-width: 1920px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 1600px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 1440px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 1199px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 991px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 35px
    }
}

@media (max-width: 767px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) {
    .single-post-content .comment-form-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) {
    .single-post-content .single-post-article .blog-mini-info {
        top: 15px;
        left: 15px
    }
    .single-post-content .single-post-article .article-text img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        float: none
    }
    .single-post-content .single-post-article .article-text p {
        text-align: justify
    }
}

.enroll-content .apply-section {
    background: #fff
}

.enroll-content .apply-section .how-apply h2,
.enroll-content .apply-section .how-apply .h2 {
    color: #090605
}

.enroll-content .apply-section .how-apply p {
    color: #777
}

.enroll-content .apply-section .how-apply ul {
    margin-bottom: 0
}

.enroll-content .apply-section .how-apply ul li::after {
    color: #ff5157
}

.enroll-content .apply-section .apply-form-wrap h2,
.enroll-content .apply-section .apply-form-wrap .h2 {
    color: #090605
}

@media (max-width: 1920px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .enroll-content .apply-section .apply-form-wrap h2,
    .enroll-content .apply-section .apply-form-wrap .h2 {
        margin-bottom: 30px
    }
}

.enroll-content .apply-section .apply-form-wrap .apply-form label {
    color: #777
}

.enroll-content .apply-section .apply-form-wrap .apply-form input,
.enroll-content .apply-section .apply-form-wrap .apply-form textarea {
    border: 1px solid #aeaeae
}

.enroll-content .apply-section .apply-form-wrap .apply-form textarea {
    height: 105px
}

.page-name {
    background: url(../img/stardust.png) repeat, #5255c5
}

@media (max-width: 1920px) {
    .page-name {
        padding-top: 30px
    }
}

@media (max-width: 1600px) {
    .page-name {
        padding-top: 30px
    }
}

@media (max-width: 1440px) {
    .page-name {
        padding-top: 30px
    }
}

@media (max-width: 1199px) {
    .page-name {
        padding-top: 30px
    }
}

@media (max-width: 991px) {
    .page-name {
        padding-top: 25px
    }
}

@media (max-width: 767px) {
    .page-name {
        padding-top: 20px
    }
}

@media (max-width: 480px) {
    .page-name {
        padding-top: 20px
    }
}

@media (max-width: 1920px) {
    .page-name {
        padding-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .page-name {
        padding-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .page-name {
        padding-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .page-name {
        padding-bottom: 30px
    }
}

@media (max-width: 991px) {
    .page-name {
        padding-bottom: 25px
    }
}

@media (max-width: 767px) {
    .page-name {
        padding-bottom: 20px
    }
}

@media (max-width: 480px) {
    .page-name {
        padding-bottom: 20px
    }
}

.page-name.color-1 {
    background-color: #5255c5
}

.page-name.color-2 {
    background-color: #5dba3b
}

.page-name.color-3 {
    background-color: #ff8b00
}

.page-name.color-4 {
    background-color: #ff5157
}

.page-name.color-5 {
    background-color: #ffc000
}

.page-name.type-2 .page-name-content h1,
.page-name.type-2 .page-name-content .h1 {
    text-align: left
}

.page-name.type-2 .bread-crumbs {
    -ms-flex-pack: start;
    justify-content: flex-start
}

.page-name.type-3 .page-name-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.page-name.type-3 .page-name-content h1,
.page-name.type-3 .page-name-content .h1 {
    margin-bottom: 0
}

@media (max-width: 767px) {
    .page-name.type-3 .page-name-content {
        display: block
    }
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        text-align: center
    }
}

@media (max-width: 767px) and (max-width: 1920px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1600px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1440px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1199px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 991px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) and (max-width: 480px) {
    .page-name.type-3 .page-name-content h1,
    .page-name.type-3 .page-name-content .h1 {
        margin-bottom: 15px
    }
}

.page-name.type-4 .page-name-content h1,
.page-name.type-4 .page-name-content .h1 {
    text-align: left
}

.page-name.type-4 .page-name-content .bread-crumbs {
    -ms-flex-pack: end;
    justify-content: flex-end
}

.page-name.type-5 .page-name-content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center
}

.page-name.type-5 .page-name-content .bread-crumbs {
    padding: 3px 15px;
    border-radius: 15px;
    background: #fff
}

@media (max-width: 1920px) {
    .page-name .page-name-content {
        padding-top: 30px
    }
}

@media (max-width: 1600px) {
    .page-name .page-name-content {
        padding-top: 30px
    }
}

@media (max-width: 1440px) {
    .page-name .page-name-content {
        padding-top: 30px
    }
}

@media (max-width: 1199px) {
    .page-name .page-name-content {
        padding-top: 30px
    }
}

@media (max-width: 991px) {
    .page-name .page-name-content {
        padding-top: 25px
    }
}

@media (max-width: 767px) {
    .page-name .page-name-content {
        padding-top: 20px
    }
}

@media (max-width: 480px) {
    .page-name .page-name-content {
        padding-top: 20px
    }
}

@media (max-width: 1920px) {
    .page-name .page-name-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .page-name .page-name-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .page-name .page-name-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .page-name .page-name-content {
        padding-bottom: 30px
    }
}

@media (max-width: 991px) {
    .page-name .page-name-content {
        padding-bottom: 25px
    }
}

@media (max-width: 767px) {
    .page-name .page-name-content {
        padding-bottom: 20px
    }
}

@media (max-width: 480px) {
    .page-name .page-name-content {
        padding-bottom: 20px
    }
}

.page-name .page-name-content h1,
.page-name .page-name-content .h1 {
    text-align: center
}

@media (max-width: 1920px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .page-name .page-name-content h1,
    .page-name .page-name-content .h1 {
        margin-bottom: 5px
    }
}

@media (max-width: 1920px) {
    .typography-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .typography-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .typography-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .typography-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .typography-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .typography-content .typography-content-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .typography-content-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .typography-content-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .typography-content-wrap {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .typography-content-wrap {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .typography-content-wrap {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .typography-content-wrap {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .typography-content-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .typography-content .header-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .header-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .header-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .header-preview {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .header-preview {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .header-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .header-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) {
    .typography-content .header-preview h1,
    .typography-content .header-preview .h1,
    .typography-content .header-preview h2,
    .typography-content .header-preview .h2,
    .typography-content .header-preview h3,
    .typography-content .header-preview .h3,
    .typography-content .header-preview h4,
    .typography-content .header-preview .h4,
    .typography-content .header-preview h5,
    .typography-content .header-preview .h5,
    .typography-content .header-preview h6,
    .typography-content .header-preview .h6 {
        text-align: left
    }
}

.typography-content .list-preview {
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1920px) {
    .typography-content .list-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .list-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .list-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .list-preview {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .list-preview {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .list-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .list-preview {
        margin-bottom: 30px
    }
}

.typography-content .list-preview .unoredered-wrap {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 120px
}

.typography-content .list-preview .oredered-wrap {
    -ms-flex-positive: 1;
    flex-grow: 1
}

.typography-content .list-preview ul,
.typography-content .list-preview ol {
    margin-bottom: 0
}

@media (max-width: 1199px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-right: 50px
    }
}

@media (max-width: 991px) {
    .typography-content .list-preview {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .typography-content .list-preview .unoredered-wrap {
        margin-right: 0
    }
}

@media (max-width: 767px) {
    .typography-content .list-preview h2,
    .typography-content .list-preview .h2 {
        text-align: left
    }
}

@media (max-width: 767px) and (max-width: 1920px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 1600px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 1440px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 1199px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 991px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 480px) {
    .typography-content .list-preview .unoredered-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 1920px) {
    .typography-content .button-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .button-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .button-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .button-preview {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .button-preview {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .button-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .button-preview {
        margin-bottom: 30px
    }
}

.typography-content .button-preview .button-wrap button {
    margin-bottom: 0;
    margin-right: 30px
}

.typography-content .button-preview .button-wrap button:last-child {
    margin-right: 0
}

@media (max-width: 1199px) {
    .typography-content .button-preview .button-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .typography-content .button-preview .button-wrap button {
        width: 40%
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    .typography-content .button-preview .button-wrap button:nth-child(1),
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .typography-content .button-preview .button-wrap button:nth-child(2) {
        margin-right: 0
    }
}

@media (max-width: 767px) {
    .typography-content .button-preview .button-wrap {
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-align: start;
        align-items: flex-start
    }
    .typography-content .button-preview .button-wrap button {
        margin-right: 15px;
        width: 200px
    }
}

@media (max-width: 767px) and (max-width: 1920px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1600px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1440px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 1199px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 991px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) and (max-width: 480px) {
    .typography-content .button-preview .button-wrap button:nth-child(3) {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .typography-content .button-preview .button-wrap button:nth-child(2),
    .typography-content .button-preview .button-wrap button:nth-child(4) {
        margin-right: 15px
    }
}

.typography-content .progress-preview {
    width: 100%;
    max-width: 555px
}

@media (max-width: 1920px) {
    .typography-content .progress-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .progress-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .progress-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .progress-preview {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .progress-preview {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .progress-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .progress-preview {
        margin-bottom: 30px
    }
}

.typography-content .progress-preview .progress-wrap:last-child {
    margin-bottom: 0
}

@media (max-width: 767px) {
    .typography-content .progress-preview {
        max-width: none
    }
}

@media (max-width: 1920px) {
    .typography-content .blockquote-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .blockquote-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .blockquote-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .blockquote-preview {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .blockquote-preview {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .blockquote-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .blockquote-preview {
        margin-bottom: 30px
    }
}

.typography-content .blockquote-preview blockquote,
.typography-content .blockquote-preview .blockquote {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .typography-content .form-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .form-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .form-preview {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .form-preview {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .form-preview {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .form-preview {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .form-preview {
        margin-bottom: 30px
    }
}

.typography-content .form-preview .preview-form {
    margin-bottom: 0
}

.typography-content .form-preview .preview-form .inner-preview-form {
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px
}

.typography-content .form-preview .preview-form .inner-preview-form .input-wrap {
    width: 33.33%;
    padding: 0 15px
}

.typography-content .form-preview .preview-form textarea {
    margin-bottom: 0
}

@media (max-width: 480px) {
    .typography-content .form-preview .preview-form .inner-preview-form {
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0
    }
    .typography-content .form-preview .preview-form .inner-preview-form .input-wrap {
        width: 100%;
        padding: 0
    }
    .typography-content .form-preview .preview-form textarea {
        height: 100px
    }
}

.typography-content .pie-chart-wrap {
    display: -ms-flexbox;
    display: flex
}

@media (max-width: 1920px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .typography-content .pie-chart-wrap {
        margin-bottom: 30px
    }
}

.typography-content .pie-chart-wrap .pie-wrap {
    margin-right: 30px;
    margin-bottom: 0
}

.typography-content .pie-chart-wrap .pie-wrap:last-child {
    margin-right: 0
}

@media (max-width: 991px) {
    .typography-content .pie-chart-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center
    }
    .typography-content .pie-chart-wrap .pie-wrap {
        min-width: 150px;
        margin-right: 0
    }
}

@media (max-width: 991px) and (max-width: 1920px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) and (max-width: 1600px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) and (max-width: 1440px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) and (max-width: 1199px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) and (max-width: 480px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .typography-content .pie-chart-wrap .pie-wrap:first-child {
        margin-right: 20px
    }
    .typography-content .pie-chart-wrap .pie-wrap:last-child {
        margin-bottom: 0
    }
}

@media (max-width: 767px) {
    .typography-content .pie-chart-wrap {
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .typography-content .pie-chart-wrap .pie-wrap {
        min-width: 140px;
        margin-right: 0;
        margin-right: 20px
    }
}

@media (max-width: 767px) and (max-width: 1920px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 1600px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 1440px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 1199px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 767px) and (max-width: 991px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) and (max-width: 767px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) and (max-width: 480px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .typography-content .pie-chart-wrap .pie-wrap:last-child {
        margin-bottom: 0;
        margin-right: 0
    }
}

@media (max-width: 480px) {
    .typography-content .pie-chart-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-right: 0
    }
}

@media (max-width: 480px) and (max-width: 1920px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) and (max-width: 1600px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) and (max-width: 1440px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) and (max-width: 1199px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) and (max-width: 991px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) and (max-width: 767px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) and (max-width: 480px) {
    .typography-content .pie-chart-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .typography-content .pie-chart-wrap .pie-wrap:first-child {
        margin-right: 10px
    }
}

.typography-content .testimonials-preview {
    width: 100%;
    max-width: 555px;
    margin-bottom: 0
}

.typography-content .testimonials-preview .testimonial-wrap {
    margin-bottom: 0
}

@media (max-width: 767px) {
    .typography-content .testimonials-preview {
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 767px) {
    .typography-content h1,
    .typography-content .h1,
    .typography-content h2,
    .typography-content .h2,
    .typography-content h3,
    .typography-content .h3,
    .typography-content h4,
    .typography-content .h4,
    .typography-content h5,
    .typography-content .h5,
    .typography-content h6,
    .typography-content .h6 {
        text-align: left
    }
    .typography-content .list-preview h2,
    .typography-content .list-preview .h2 {
        text-align: left
    }
    .typography-content blockquote h6,
    .typography-content blockquote .h6,
    .typography-content blockquote h4,
    .typography-content blockquote .h4 {
        text-align: center
    }
    .typography-content .timer-wrap .adaptive-wrap {
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .typography-content .testimonials-preview {
        max-width: none
    }
    .typography-content .sidebar h1,
    .typography-content .sidebar .h1,
    .typography-content .sidebar h2,
    .typography-content .sidebar .h2,
    .typography-content .sidebar h3,
    .typography-content .sidebar .h3,
    .typography-content .sidebar h4,
    .typography-content .sidebar .h4,
    .typography-content .sidebar h5,
    .typography-content .sidebar .h5,
    .typography-content .sidebar h6,
    .typography-content .sidebar .h6 {
        text-align: center
    }
}

.timer-wrap {
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    max-width: 780px
}

@media (max-width: 1920px) {
    .timer-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .timer-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .timer-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .timer-wrap {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .timer-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .timer-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .timer-wrap {
        margin-bottom: 30px
    }
}

.timer-wrap .adaptive-wrap {
    display: -ms-flexbox;
    display: flex;
    width: 50%
}

.timer-wrap .timer-block-wrap {
    width: 50%;
    max-width: 200px;
    padding: 0 15px
}

.timer-wrap .timer-block {
    overflow: hidden;
    border-radius: 20px
}

.timer-wrap .timer-block.color-1 .timer-count {
    background: #ff5157
}

.timer-wrap .timer-block.color-1 .timer-text {
    background: #e04b4e
}

.timer-wrap .timer-block.color-2 .timer-count {
    background: #5255c5
}

.timer-wrap .timer-block.color-2 .timer-text {
    background: #474aab
}

.timer-wrap .timer-block.color-3 .timer-count {
    background: #5dba3b
}

.timer-wrap .timer-block.color-3 .timer-text {
    background: #50a033
}

.timer-wrap .timer-block.color-4 .timer-count {
    background: #ffc000
}

.timer-wrap .timer-block.color-4 .timer-text {
    background: #e5ad00
}

.timer-wrap .timer-count p {
    width: 100%;
    margin: 0;
    padding: 15px 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 70px;
    text-align: center;
    color: #fff
}

.timer-wrap .timer-text {
    margin-bottom: 0;
    font-family: "Baloo", cursive;
    font-size: 24px;
    text-align: center;
    color: #fff
}

@media (max-width: 991px) {
    .timer-wrap {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .timer-wrap .adaptive-wrap {
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%
    }
    .timer-wrap .adaptive-wrap:last-child {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .timer-wrap .timer-block-wrap {
        max-width: 170px
    }
    .timer-wrap .timer-block {
        border-radius: 15px
    }
    .timer-wrap .timer-count {
        padding: 15px 0
    }
    .timer-wrap .timer-count p {
        font-size: 50px;
        padding: 0
    }
    .timer-wrap .timer-text {
        font-size: 20px
    }
}

.pie-wrap {
    width: 33%;
    max-width: 260px
}

@media (max-width: 1920px) {
    .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .pie-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .pie-wrap {
        margin-bottom: 20px
    }
}

.pie-wrap .chart {
    position: relative;
    width: 100%
}

@media (max-width: 1920px) {
    .pie-wrap .chart {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .pie-wrap .chart {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .pie-wrap .chart {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .pie-wrap .chart {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .pie-wrap .chart {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .pie-wrap .chart {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .pie-wrap .chart {
        margin-bottom: 15px
    }
}

.pie-wrap .chart .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    font-size: 48px;
    color: #090605;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.pie-wrap .pie-desc {
    margin: 0;
    text-align: center
}

@media (max-width: 1199px) {
    .pie-wrap {
        max-width: 180px
    }
    .pie-wrap .chart {
        width: 180px;
        height: 180px
    }
    .pie-wrap .chart canvas {
        width: 100% !important;
        height: 100% !important
    }
    .pie-wrap .chart .percent {
        font-size: 40px
    }
}

@media (max-width: 991px) {
    .pie-wrap {
        max-width: 150px;
        min-width: 140px
    }
    .pie-wrap .chart {
        width: 150px;
        height: 150px
    }
    .pie-wrap .chart .percent {
        font-size: 30px
    }
}

.search-overlay-wrap {
    position: fixed;
    top: -150%;
    left: 0;
    z-index: 1000;
    display: block;
    width: 100%;
    height: 100%;
    transition: all .5s
}

.search-overlay-wrap::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #5255c5;
    opacity: .95
}

.search-overlay-wrap.show-search-overlay {
    top: 0
}

.search-overlay-wrap.show-search-overlay .search-overlay {
    margin-top: 0;
    opacity: 1
}

.search-overlay-wrap.show-search-overlay .close-search-overlay {
    right: 20px;
    opacity: 1;
    cursor: pointer;
    -ms-transform: rotate(0);
    transform: rotate(0)
}

.search-overlay-wrap.color-1::after {
    background: #5255c5
}

.search-overlay-wrap.color-2::after {
    background: #5dba3b
}

.search-overlay-wrap.color-3::after {
    background: #ff8b00
}

.search-overlay-wrap.color-4::after {
    background: #ff5157
}

.search-overlay-wrap.color-5::after {
    background: #ffc000
}

.search-overlay-wrap .close-search-overlay {
    position: absolute;
    z-index: 10;
    top: 25px;
    right: 120px;
    font-size: 40px;
    color: #fff;
    opacity: 0;
    -ms-transform: rotate(-270deg);
    transform: rotate(-270deg);
    transition: all .5s;
    transition-delay: .4s
}

.search-overlay-wrap .close-search-overlay:hover i {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.search-overlay-wrap .close-search-overlay i {
    display: block;
    transition: all .3s
}

.search-overlay-wrap .search-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 600px;
    margin-top: -100px;
    padding: 0 15px;
    opacity: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: all .5s;
    transition-delay: .2s
}

.search-overlay-wrap .search-overlay:-ms-input-placeholder {
    color: #fff;
    opacity: 1
}

.search-overlay-wrap .search-overlay::placeholder {
    color: #fff;
    opacity: 1
}

.search-overlay-wrap .search-overlay:-ms-input-placeholder {
    color: #fff
}

.search-overlay-wrap .search-overlay::-ms-input-placeholder {
    color: #fff
}

.search-overlay-wrap .search-overlay input {
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    padding-left: 0;
    padding-right: 40px;
    border-bottom: 2px solid #fff;
    background-color: transparent;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    color: #fff
}

.search-overlay-wrap .search-overlay button[type='submit'] {
    position: absolute;
    right: 15px;
    top: 0;
    height: 100%;
    width: 40px;
    min-width: 0;
    margin-top: 0;
    padding: 0;
    border-radius: 0
}

.search-overlay-wrap .search-overlay button[type='submit']::before {
    background-color: transparent
}

.search-overlay-wrap .search-overlay button[type='submit']::after {
    display: none
}

.search-overlay-wrap .search-overlay button[type='submit'] i {
    font-size: 30px
}

.action-wrap {
    position: relative;
    background-image: url(../img/absurdity.png);
    background-color: #ff8b00
}

@media (max-width: 1920px) {
    .action-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .action-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .action-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .action-wrap {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .action-wrap {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .action-wrap {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .action-wrap {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .action-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .action-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .action-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .action-wrap {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .action-wrap {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .action-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .action-wrap {
        padding-bottom: 30px
    }
}

.action-wrap.color-1 {
    background-color: #5255c5
}

.action-wrap.color-2 {
    background-color: #5dba3b
}

.action-wrap.color-3 {
    background-color: #ff8b00
}

.action-wrap.color-4 {
    background-color: #ff5157
}

.action-wrap.color-5 {
    background-color: #ffc000
}

.action-wrap .moving-element {
    position: absolute;
    top: -80px;
    left: 13%
}

.action-wrap .moving-element.one {
    z-index: 2
}

.action-wrap .moving-element.two {
    top: 0;
    left: 18%;
    z-index: 1
}

.action-wrap .action-bg {
    position: absolute;
    bottom: 0;
    right: 5%;
    z-index: 1
}

.action-wrap .container {
    position: relative;
    z-index: 10
}

.action-wrap .action {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1920px) {
    .action-wrap .action {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .action-wrap .action {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .action-wrap .action {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .action-wrap .action {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .action-wrap .action {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .action-wrap .action {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .action-wrap .action {
        padding-top: 30px
    }
}

.action-wrap .action .timer-wrap {
    width: 100%;
    max-width: 750px
}

@media (max-width: 1920px) {
    .action-wrap .action .action-desc {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .action-wrap .action .action-desc {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .action-wrap .action .action-desc {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .action-wrap .action .action-desc {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .action-wrap .action .action-desc {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .action-wrap .action .action-desc {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .action-wrap .action .action-desc {
        margin-bottom: 30px
    }
}

.action-wrap .action .action-desc h2,
.action-wrap .action .action-desc .h2 {
    text-transform: uppercase;
    color: #fff
}

@media (max-width: 1920px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .action-wrap .action .action-desc h2,
    .action-wrap .action .action-desc .h2 {
        margin-bottom: 10px
    }
}

.action-wrap .action .action-desc h6,
.action-wrap .action .action-desc .h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #fff
}

@media (max-width: 1199px) {
    .action-wrap .action-bg {
        right: 0%
    }
    .action-wrap .action {
        -ms-flex-direction: column;
        flex-direction: column;
        text-align: center
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 25px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    .action-wrap .action .action-desc h6,
    .action-wrap .action .action-desc .h6 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .action-wrap .action-bg {
        display: none
    }
    .action-wrap .action .timer-wrap {
        -ms-flex-direction: row;
        flex-direction: row
    }
    .action-wrap .action .timer-wrap .adaptive-wrap:last-child {
        padding-top: 0
    }
}

@media (max-width: 767px) {
    .action-wrap .moving-element {
        top: -50px
    }
    .action-wrap .moving-element:nth-child(2) {
        top: 20px;
        left: 20%
    }
    .action-wrap .action .timer-wrap {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .action-wrap .action .timer-wrap .adaptive-wrap:last-child {
        padding-top: 30px
    }
}

.classes-wrap {
    position: relative
}

@media (max-width: 1920px) {
    .classes-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .classes-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .classes-wrap {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .classes-wrap {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .classes-wrap {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .classes-wrap {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .classes-wrap {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .classes-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .classes-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .classes-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .classes-wrap {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .classes-wrap {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .classes-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .classes-wrap {
        padding-bottom: 30px
    }
}

.classes-wrap .moving-element {
    top: 15%;
    left: 10%;
    z-index: 0
}

.classes-wrap .container {
    position: relative;
    z-index: 10
}

.classes-wrap .bottom-img {
    position: absolute;
    bottom: -9px;
    right: 10%
}

.classes-wrap h2,
.classes-wrap .h2 {
    text-align: center
}

@media (max-width: 1920px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .classes-wrap h2,
    .classes-wrap .h2 {
        padding-top: 30px
    }
}

.classes-wrap .classes-slider {
    margin-left: -15px;
    margin-right: -15px
}

@media (max-width: 1920px) {
    .classes-wrap .classes-slider {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .classes-wrap .classes-slider {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .classes-wrap .classes-slider {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .classes-wrap .classes-slider {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .classes-wrap .classes-slider {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .classes-wrap .classes-slider {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .classes-wrap .classes-slider {
        padding-bottom: 30px
    }
}

.classes-wrap .classes-slider .card {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 991px) {
    .classes-wrap .moving-element {
        top: 10%;
        left: 10%;
        z-index: 0
    }
}

@media (max-width: 767px) {
    .classes-wrap .bottom-img {
        bottom: -5px;
        height: 60px
    }
}

.employe-card {
    position: relative;
    z-index: 10;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px
}

@media (max-width: 1920px) {
    .employe-card {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .employe-card {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .employe-card {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .employe-card {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .employe-card {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .employe-card {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .employe-card {
        margin-bottom: 30px
    }
}

.employe-card:nth-child(4n+1)::after {
    background: #ff5157
}

.employe-card:nth-child(4n+1) figcaption a:hover {
    color: #5255c5
}

.employe-card:nth-child(4n+2)::after {
    background: #5255c5
}

.employe-card:nth-child(4n+2) figcaption a:hover {
    color: #ff8b00
}

.employe-card:nth-child(4n+3)::after {
    background: #5dba3b
}

.employe-card:nth-child(4n+3) figcaption a:hover {
    color: #5255c5
}

.employe-card:nth-child(4n+4)::after {
    background: #ff8b00
}

.employe-card:nth-child(4n+4) figcaption a:hover {
    color: #5255c5
}

.employe-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 80%;
    border-radius: 15px;
    background: #5255c5;
    transition: all .3s
}

.employe-card:hover::after {
    height: 100%
}

.employe-card:hover figcaption .read-more {
    visibility: visible;
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0)
}

.employe-card .employe {
    display: -ms-flexbox;
    display: flex
}

.employe-card .employe img {
    min-width: 135px;
    margin-right: 15px;
    border-radius: 10px
}

.employe-card figcaption {
    color: #fff
}

.employe-card figcaption h3,
.employe-card figcaption .h3 {
    color: #fff
}

@media (max-width: 1920px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        margin-bottom: 5px
    }
}

@media (max-width: 1920px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        padding-top: 5px
    }
}

.employe-card figcaption p {
    color: #fff
}

@media (max-width: 1920px) {
    .employe-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .employe-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .employe-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .employe-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .employe-card figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .employe-card figcaption p {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .employe-card figcaption p {
        margin-bottom: 10px
    }
}

.employe-card figcaption p em {
    font-style: italic
}

.employe-card figcaption a:hover {
    color: #ff8b00
}

.employe-card figcaption .read-more {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
    text-decoration: none;
    color: #fff;
    transition: all .2s;
    visibility: hidden;
    opacity: 0;
    -ms-transform: translateY(10px);
    transform: translateY(10px)
}

@media (max-width: 1920px) {
    .employe-card figcaption .read-more {
        margin-top: 20px
    }
}

@media (max-width: 1600px) {
    .employe-card figcaption .read-more {
        margin-top: 20px
    }
}

@media (max-width: 1440px) {
    .employe-card figcaption .read-more {
        margin-top: 20px
    }
}

@media (max-width: 1199px) {
    .employe-card figcaption .read-more {
        margin-top: 20px
    }
}

@media (max-width: 991px) {
    .employe-card figcaption .read-more {
        margin-top: 20px
    }
}

@media (max-width: 767px) {
    .employe-card figcaption .read-more {
        margin-top: 15px
    }
}

@media (max-width: 480px) {
    .employe-card figcaption .read-more {
        margin-top: 15px
    }
}

.employe-card figcaption .read-more i {
    margin-bottom: -2px;
    transition: transform .2s
}

@media (max-width: 1920px) {
    .employe-card figcaption .soc-list {
        margin-top: 20px
    }
}

@media (max-width: 1600px) {
    .employe-card figcaption .soc-list {
        margin-top: 20px
    }
}

@media (max-width: 1440px) {
    .employe-card figcaption .soc-list {
        margin-top: 20px
    }
}

@media (max-width: 1199px) {
    .employe-card figcaption .soc-list {
        margin-top: 20px
    }
}

@media (max-width: 991px) {
    .employe-card figcaption .soc-list {
        margin-top: 20px
    }
}

@media (max-width: 767px) {
    .employe-card figcaption .soc-list {
        margin-top: 15px
    }
}

@media (max-width: 480px) {
    .employe-card figcaption .soc-list {
        margin-top: 15px
    }
}

.employe-card figcaption .soc-list li {
    padding-left: 0;
    margin-right: 10px
}

.employe-card figcaption .soc-list li a {
    display: block;
    line-height: 1em;
    vertical-align: middle
}

.employe-card figcaption .soc-list li a i {
    display: block;
    line-height: 1em
}

.employe-card figcaption .soc-list i {
    font-size: 15px
}

@media (max-width: 767px) {
    .employe-card {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto
    }
    .employe-card::after {
        height: 100%
    }
    .employe-card figcaption .read-more {
        visibility: visible;
        opacity: 1;
        -ms-transform: translateY(0);
        transform: translateY(0)
    }
}

@media (max-width: 480px) {
    .employe-card {
        max-width: 290px;
        padding: 15px
    }
    .employe-card .employe {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .employe-card .employe>a,
    .employe-card .employe img {
        display: block;
        margin-left: auto;
        margin-right: auto
    }
    .employe-card .employe>a {
        margin-left: 0;
        margin-right: 15px
    }
    .employe-card .employe img {
        display: inline-block;
        height: 160px;
        width: auto;
        min-width: 0;
        margin-bottom: 0
    }
    .employe-card figcaption h3,
    .employe-card figcaption .h3 {
        text-align: left
    }
    .employe-card .soc-list {
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .employe-card .read-more {
        -ms-flex-pack: start;
        justify-content: flex-start;
        margin-left: auto;
        margin-right: auto;
        text-align: left
    }
}

.team-list {
    margin-bottom: 0
}

.team-list li {
    padding-left: 15px
}

.team-list li::after {
    display: none
}

.team-list li:nth-child(4n+1) .employe-card::after {
    background: #ff5157
}

.team-list li:nth-child(4n+1) .employe-card figcaption a:hover {
    color: #5255c5
}

.team-list li:nth-child(4n+2) .employe-card::after {
    background: #5255c5
}

.team-list li:nth-child(4n+2) .employe-card figcaption a:hover {
    color: #ff8b00
}

.team-list li:nth-child(4n+3) .employe-card::after {
    background: #5dba3b
}

.team-list li:nth-child(4n+3) .employe-card figcaption a:hover {
    color: #5255c5
}

.team-list li:nth-child(4n+4) .employe-card::after {
    background: #ff8b00
}

.team-list li:nth-child(4n+4) .employe-card figcaption a:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .team-list li:last-child figcaption p {
        margin-bottom: 10px
    }
}

.slick-slide:nth-child(4n+1) .employe-card::after,
.our-teachers-list li:nth-child(4n+1) .employe-card::after {
    background: #ff5157
}

.slick-slide:nth-child(4n+1) .employe-card figcaption a:hover,
.our-teachers-list li:nth-child(4n+1) .employe-card figcaption a:hover {
    color: #5255c5
}

.slick-slide:nth-child(4n+2) .employe-card::after,
.our-teachers-list li:nth-child(4n+2) .employe-card::after {
    background: #5255c5
}

.slick-slide:nth-child(4n+2) .employe-card figcaption a:hover,
.our-teachers-list li:nth-child(4n+2) .employe-card figcaption a:hover {
    color: #ff8b00
}

.slick-slide:nth-child(4n+3) .employe-card::after,
.our-teachers-list li:nth-child(4n+3) .employe-card::after {
    background: #5dba3b
}

.slick-slide:nth-child(4n+3) .employe-card figcaption a:hover,
.our-teachers-list li:nth-child(4n+3) .employe-card figcaption a:hover {
    color: #5255c5
}

.slick-slide:nth-child(4n+4) .employe-card::after,
.our-teachers-list li:nth-child(4n+4) .employe-card::after {
    background: #ff8b00
}

.slick-slide:nth-child(4n+4) .employe-card figcaption a:hover,
.our-teachers-list li:nth-child(4n+4) .employe-card figcaption a:hover {
    color: #5255c5
}

@media (max-width: 1920px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .slick-slide:last-child figcaption p,
    .our-teachers-list li:last-child figcaption p {
        margin-bottom: 10px
    }
}

.featherlight-content .featherlight-close-icon.featherlight-close {
    min-width: 0;
    margin-bottom: 0;
    background-color: transparent
}

.featherlight-content .featherlight-close-icon.featherlight-close::before,
.featherlight-content .featherlight-close-icon.featherlight-close::after {
    display: none
}

.featherlight .featherlight-content {
    border: none;
    padding: 0
}

.featherlight-close-icon.featherlight-close {
    right: -25px;
    top: -25px;
    border-radius: 0
}

.featherlight-close-icon.featherlight-close i {
    background-color: transparent;
    font-size: 24px;
    color: #fff
}

@media (max-width: 1199px) {
    .featherlight-close-icon.featherlight-close {
        right: 0;
        top: 0
    }
    .featherlight-close-icon.featherlight-close i {
        background: rgba(255, 255, 255, 0.4);
        color: #090605
    }
}

.featherlight .featherlight-content {
    overflow: visible
}

.gallery-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: -20px
}

.gallery-wrap .gallery-card {
    padding: 0 15px;
    width: 25%
}

.gallery-wrap a {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 15px
}

@media (max-width: 1920px) {
    .gallery-wrap a {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .gallery-wrap a {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .gallery-wrap a {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .gallery-wrap a {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .gallery-wrap a {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .gallery-wrap a {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .gallery-wrap a {
        margin-bottom: 20px
    }
}

.gallery-wrap a:hover .hide-desc {
    visibility: visible;
    opacity: 1;
    -ms-transform: translateY(0px);
    transform: translateY(0px)
}

.gallery-wrap a.color-1 .hide-desc::after {
    background: #5255c5
}

.gallery-wrap a.color-2 .hide-desc::after {
    background: #5dba3b
}

.gallery-wrap a.color-3 .hide-desc::after {
    background: #ff8b00
}

.gallery-wrap a.color-4 .hide-desc::after {
    background: #ff5157
}

.gallery-wrap a.color-5 .hide-desc::after {
    background: #ffc000
}

.gallery-wrap a img {
    border-radius: 15px
}

.gallery-wrap a .hide-desc {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    visibility: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    color: #fff;
    opacity: 0;
    -ms-transform: translateY(200px);
    transform: translateY(200px);
    transition: all .5s
}

.gallery-wrap a .hide-desc::before {
    content: '\f19f';
    font-family: "Material Design Icons";
    margin-bottom: 10px;
    font-size: 48px;
    line-height: 1
}

.gallery-wrap a .hide-desc::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: #5dba3b;
    opacity: .95
}

@media (max-width: 991px) {
    .gallery-wrap .gallery-card {
        width: 33.33%
    }
    .gallery-wrap .gallery-card a {
        display: block;
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 767px) {
    .gallery-wrap .gallery-card {
        width: 50%
    }
    .gallery-wrap a .hide-desc {
        font-size: 16px
    }
    .gallery-wrap a .hide-desc::before {
        font-size: 26px;
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .gallery-wrap {
        margin-bottom: -10px
    }
}

.skills-section {
    background-image: url(../img/absurdity.png);
    background-color: #ff5157
}

@media (max-width: 1920px) {
    .skills-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .skills-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .skills-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .skills-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .skills-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .skills-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .skills-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .skills-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .skills-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .skills-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .skills-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .skills-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .skills-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .skills-section {
        padding-bottom: 30px
    }
}

.skills-section.color-1 {
    background-color: #5255c5
}

.skills-section.color-2 {
    background-color: #5dba3b
}

.skills-section.color-3 {
    background-color: #ff8b00
}

.skills-section.color-4 {
    background-color: #ff5157
}

.skills-section.color-5 {
    background-color: #ffc000
}

.skills-section .container {
    position: relative;
    z-index: 10
}

@media (max-width: 1920px) {
    .skills-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .skills-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .skills-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .skills-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .skills-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .skills-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .skills-section .container {
        padding-top: 30px
    }
}

.skills-section .skill-block {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto
}

@media (max-width: 1920px) {
    .skills-section .skill-block {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .skills-section .skill-block {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .skills-section .skill-block {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .skills-section .skill-block {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .skills-section .skill-block {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .skills-section .skill-block {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .skills-section .skill-block {
        padding-bottom: 30px
    }
}

.skills-section .skill-block img {
    height: 45px
}

@media (max-width: 1920px) {
    .skills-section .skill-block img {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .skills-section .skill-block img {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .skills-section .skill-block img {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .skills-section .skill-block img {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .skills-section .skill-block img {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .skills-section .skill-block img {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .skills-section .skill-block img {
        margin-bottom: 15px
    }
}

.skills-section .skill-block h2,
.skills-section .skill-block .h2 {
    text-align: center;
    color: #fff
}

@media (max-width: 1920px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .skills-section .skill-block h2,
    .skills-section .skill-block .h2 {
        margin-bottom: 5px
    }
}

.skills-section .skill-block h5,
.skills-section .skill-block .h5 {
    margin-bottom: 0;
    text-align: center;
    color: #fff
}

.skills-section .moving-element {
    top: 50%;
    left: 85%;
    z-index: 0
}

@media (max-width: 1440px) {
    .skills-section .moving-element {
        height: 50px;
        top: 70%;
        left: 80%
    }
}

@media (max-width: 1199px) {
    .skills-section .moving-element {
        top: 60%;
        left: 50%
    }
}

@media (max-width: 1920px) {
    .our-story-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .our-story-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .our-story-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .our-story-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .our-story-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .our-story-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .our-story-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .our-story-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .our-story-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .our-story-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .our-story-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .our-story-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .our-story-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .our-story-section {
        padding-bottom: 30px
    }
}

.our-story-section .our-story {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1920px) {
    .our-story-section .our-story {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .our-story-section .our-story {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .our-story-section .our-story {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .our-story-section .our-story {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .our-story-section .our-story {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .our-story-section .our-story {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .our-story-section .our-story {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .our-story-section .our-story {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .our-story-section .our-story {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .our-story-section .our-story {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .our-story-section .our-story {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .our-story-section .our-story {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .our-story-section .our-story {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .our-story-section .our-story {
        padding-bottom: 30px
    }
}

.our-story-section .our-story>img {
    margin-right: 20px
}

.our-story-section .our-story .our-story-text {
    max-width: 555px
}

@media (max-width: 1920px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .our-story-section .our-story h2,
    .our-story-section .our-story .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 1920px) {
    .our-story-section .our-story p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .our-story-section .our-story p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .our-story-section .our-story p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .our-story-section .our-story p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .our-story-section .our-story p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .our-story-section .our-story p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .our-story-section .our-story p {
        margin-bottom: 20px
    }
}

.our-story-section .our-story .story-benefit-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.our-story-section .our-story .story-benefit-wrap .story-benefit {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start
}

.our-story-section .our-story .story-benefit-wrap .story-benefit img {
    height: 35px
}

@media (max-width: 1920px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .our-story-section .our-story .story-benefit-wrap .story-benefit img {
        margin-bottom: 5px
    }
}

.our-story-section .our-story .story-benefit-wrap .story-benefit h5,
.our-story-section .our-story .story-benefit-wrap .story-benefit .h5 {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .our-story-section .our-story {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .our-story-section .our-story .our-story-text {
        max-width: none
    }
    .our-story-section .our-story .our-story-text h2,
    .our-story-section .our-story .our-story-text .h2 {
        text-align: center
    }
    .our-story-section .our-story .our-story-text p {
        text-align: center
    }
    .our-story-section .our-story>img {
        margin-right: 0;
        margin-bottom: 20px
    }
    .our-story-section .our-story .story-benefit-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: -20px
    }
    .our-story-section .our-story .story-benefit-wrap .story-benefit {
        width: 25%;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .our-story-section .our-story .our-story-text p {
        text-align: justify
    }
    .our-story-section .our-story .story-benefit-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: -20px
    }
    .our-story-section .our-story .story-benefit-wrap .story-benefit {
        width: 50%;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 20px
    }
}

.apply-section {
    position: relative;
    background: #5dba3b
}

@media (max-width: 1920px) {
    .apply-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .apply-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .apply-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .apply-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .apply-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .apply-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .apply-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .apply-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .apply-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .apply-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .apply-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .apply-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .apply-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .apply-section {
        padding-bottom: 30px
    }
}

.apply-section.color-1 {
    background: #5255c5
}

.apply-section.color-2 {
    background: #5dba3b
}

.apply-section.color-3 {
    background: #ff8b00
}

.apply-section.color-4 {
    background: #ff5157
}

.apply-section.color-5 {
    background: #ffc000
}

@media (max-width: 1920px) {
    .apply-section .how-apply {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .apply-section .how-apply {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .apply-section .how-apply {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .apply-section .how-apply {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .apply-section .how-apply {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .apply-section .how-apply {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .apply-section .how-apply {
        padding-bottom: 30px
    }
}

.apply-section .how-apply p {
    color: #fff
}

@media (max-width: 1920px) {
    .apply-section .how-apply p {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .apply-section .how-apply p {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .apply-section .how-apply p {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .apply-section .how-apply p {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .apply-section .how-apply p {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .apply-section .how-apply p {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .apply-section .how-apply p {
        margin-bottom: 20px
    }
}

.apply-section .how-apply ul {
    padding: 0
}

@media (max-width: 1920px) {
    .apply-section .how-apply ul {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .apply-section .how-apply ul {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .apply-section .how-apply ul {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .apply-section .how-apply ul {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .apply-section .how-apply ul {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .apply-section .how-apply ul {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .apply-section .how-apply ul {
        margin-bottom: 30px
    }
}

.apply-section .how-apply ul li {
    padding-left: 25px
}

.apply-section .how-apply ul li::after {
    content: '\f12c';
    top: 0;
    width: auto;
    height: auto;
    border-right-style: none;
    background-color: transparent;
    font-family: 'Material Design Icons';
    font-size: 20px;
    color: #fff
}

.apply-section .how-apply ul li:last-child p {
    margin-bottom: 0
}

@media (max-width: 1920px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .apply-section .how-apply ul li p {
        margin-bottom: 5px
    }
}

.apply-section .how-apply .button {
    margin-bottom: 0
}

.apply-section h2,
.apply-section .h2 {
    color: #fff
}

@media (max-width: 1920px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .apply-section h2,
    .apply-section .h2 {
        margin-bottom: 30px
    }
}

.apply-section .container {
    position: relative;
    z-index: 10
}

@media (max-width: 1920px) {
    .apply-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .apply-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .apply-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .apply-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .apply-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .apply-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .apply-section .container {
        padding-top: 30px
    }
}

.apply-section .moving-element {
    top: 18%;
    left: 90%;
    z-index: 0
}

@media (max-width: 1199px) {
    .apply-section .moving-element {
        height: 65px
    }
}

@media (max-width: 991px) {
    .apply-section .moving-element {
        top: 45%;
        left: 90%
    }
}

@media (max-width: 480px) {
    .apply-section .how-apply {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .apply-section .how-apply p {
        text-align: justify
    }
    .apply-section .apply-form-wrap .apply-form .inner-apply-form {
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto
    }
    .apply-section .apply-form-wrap .apply-form .inner-apply-form .input-wrap {
        width: 100%;
        padding-left: 0;
        padding-right: 0
    }
    .apply-section .apply-form-wrap .apply-form textarea {
        height: 100px
    }
}

@media (max-width: 1920px) {
    .apply-form-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .apply-form-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .apply-form-wrap {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .apply-form-wrap {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .apply-form-wrap {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .apply-form-wrap {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .apply-form-wrap {
        padding-bottom: 30px
    }
}

.apply-form-wrap .apply-form {
    margin-bottom: 0
}

.apply-form-wrap .apply-form.color-1 input:focus,
.apply-form-wrap .apply-form.color-1 textarea:focus {
    border-color: #5255c5
}

.apply-form-wrap .apply-form.color-2 input:focus,
.apply-form-wrap .apply-form.color-2 textarea:focus {
    border-color: #5dba3b
}

.apply-form-wrap .apply-form.color-3 input:focus,
.apply-form-wrap .apply-form.color-3 textarea:focus {
    border-color: #ff8b00
}

.apply-form-wrap .apply-form.color-4 input:focus,
.apply-form-wrap .apply-form.color-4 textarea:focus {
    border-color: #ff5157
}

.apply-form-wrap .apply-form.color-5 input:focus,
.apply-form-wrap .apply-form.color-5 textarea:focus {
    border-color: #ffc000
}

.apply-form-wrap .apply-form .inner-apply-form {
    display: -ms-flexbox;
    display: flex;
    margin-left: -15px;
    margin-right: -15px
}

.apply-form-wrap .apply-form .inner-apply-form .input-wrap {
    width: 33.33%;
    padding: 0 15px
}

@media (max-width: 1920px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap input {
        margin-bottom: 20px
    }
}

.apply-form-wrap .apply-form input,
.apply-form-wrap .apply-form textarea {
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.5);
    color: #090605;
    transition: all .3s
}

.apply-form-wrap .apply-form input:focus,
.apply-form-wrap .apply-form textarea:focus {
    border-color: #5255c5
}

.apply-form-wrap .apply-form textarea {
    height: 165px
}

@media (max-width: 1920px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .apply-form-wrap .apply-form textarea {
        margin-bottom: 30px
    }
}

.apply-form-wrap .apply-form button[type='submit'] {
    margin-top: 0;
    margin-bottom: 0
}

.apply-form-wrap .apply-form label {
    color: #fff
}

@media (max-width: 480px) {
    .apply-form-wrap .apply-form .inner-apply-form {
        -ms-flex-direction: column;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto
    }
    .apply-form-wrap .apply-form .inner-apply-form .input-wrap {
        width: 100%;
        padding-left: 0;
        padding-right: 0
    }
    .apply-form-wrap .apply-form textarea {
        height: 100px
    }
}

.benefit-block {
    background: #ff8b00
}

@media (max-width: 1920px) {
    .benefit-block {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .benefit-block {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .benefit-block {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .benefit-block {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .benefit-block {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .benefit-block {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .benefit-block {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .benefit-block {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .benefit-block {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .benefit-block {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .benefit-block {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .benefit-block {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .benefit-block {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .benefit-block {
        padding-bottom: 30px
    }
}

.benefit-block.color-1 {
    background: #5255c5
}

.benefit-block.color-2 {
    background: #5dba3b
}

.benefit-block.color-3 {
    background: #ff8b00
}

.benefit-block.color-4 {
    background: #ff5157
}

.benefit-block.color-5 {
    background: #ffc000
}

@media (max-width: 1920px) {
    .benefit-block .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .benefit-block .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .benefit-block .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .benefit-block .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .benefit-block .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .benefit-block .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .benefit-block .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .benefit-block .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .benefit-block .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .benefit-block .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .benefit-block .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .benefit-block .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .benefit-block .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .benefit-block .container {
        padding-bottom: 30px
    }
}

.benefit-block .benefit-title {
    text-align: center
}

@media (max-width: 1920px) {
    .benefit-block .benefit-title {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .benefit-block .benefit-title {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .benefit-block .benefit-title {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .benefit-block .benefit-title {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .benefit-block .benefit-title {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .benefit-block .benefit-title {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .benefit-block .benefit-title {
        margin-bottom: 30px
    }
}

.benefit-block .benefit-title h2,
.benefit-block .benefit-title .h2 {
    color: #fff
}

@media (max-width: 1920px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .benefit-block .benefit-title h2,
    .benefit-block .benefit-title .h2 {
        margin-bottom: 20px
    }
}

.benefit-block .benefit-title p {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    color: #fff
}

@media (max-width: 1920px) {
    .our-classes-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .our-classes-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .our-classes-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .our-classes-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .our-classes-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .our-classes-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .our-classes-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .our-classes-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .our-classes-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .our-classes-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .our-classes-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .our-classes-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .our-classes-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .our-classes-section {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .our-classes-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .our-classes-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .our-classes-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .our-classes-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .our-classes-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .our-classes-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .our-classes-section .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .our-classes-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .our-classes-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .our-classes-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .our-classes-section .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .our-classes-section .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .our-classes-section .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .our-classes-section .container {
        padding-bottom: 30px
    }
}

.our-classes-section .our-classes-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px
}

@media (max-width: 1920px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -40px
    }
}

@media (max-width: 1600px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -40px
    }
}

@media (max-width: 1440px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -40px
    }
}

@media (max-width: 1199px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -40px
    }
}

@media (max-width: 991px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -35px
    }
}

@media (max-width: 767px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -25px
    }
}

@media (max-width: 480px) {
    .our-classes-section .our-classes-list {
        margin-bottom: -25px
    }
}

.our-classes-section .our-classes-list li {
    padding-left: 15px;
    padding-right: 15px
}

@media (max-width: 1920px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 40px
    }
}

@media (max-width: 1600px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 40px
    }
}

@media (max-width: 1440px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 40px
    }
}

@media (max-width: 1199px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 40px
    }
}

@media (max-width: 991px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 35px
    }
}

@media (max-width: 767px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) {
    .our-classes-section .our-classes-list li {
        margin-bottom: 25px
    }
}

.our-classes-section .our-classes-list li::after {
    display: none
}

@media (max-width: 991px) {
    .our-classes-section .our-classes-list li {
        width: 50%
    }
}

@media (max-width: 767px) {
    .our-classes-section .our-classes-list li {
        width: 100%
    }
}

@media (max-width: 1920px) {
    .choose-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .choose-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .choose-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .choose-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .choose-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .choose-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .choose-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .choose-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .choose-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .choose-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .choose-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .choose-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .choose-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .choose-section {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .choose-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .choose-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .choose-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .choose-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .choose-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .choose-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .choose-section .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .choose-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .choose-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .choose-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .choose-section .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .choose-section .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .choose-section .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .choose-section .container {
        padding-bottom: 30px
    }
}

.choose-section h2,
.choose-section .h2 {
    text-align: center
}

@media (max-width: 1920px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .choose-section h2,
    .choose-section .h2 {
        margin-bottom: 30px
    }
}

.choose-section .choose-pie-wrap {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media (max-width: 1920px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 1600px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 1440px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 1199px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 991px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -25px
    }
}

@media (max-width: 767px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 480px) {
    .choose-section .choose-pie-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 1920px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1600px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1440px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1199px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 991px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 25px
    }
}

@media (max-width: 767px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 480px) {
    .choose-section .choose-pie-wrap .pie-wrap {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .choose-section .choose-pie-wrap {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }
    .choose-section .choose-pie-wrap .pie-wrap {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        width: 50%;
        max-width: none
    }
}

@media (max-width: 1920px) {
    .class-article {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .class-article {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .class-article {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .class-article {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .class-article {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .class-article {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .class-article {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .class-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .class-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .class-article {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .class-article {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .class-article {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .class-article {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .class-article {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .class-article .class-post {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .class-article .class-post {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .class-article .class-post {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .class-article .class-post {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .class-article .class-post {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .class-article .class-post {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .class-article .class-post {
        padding-top: 30px
    }
}

.class-article .class-post img {
    float: left;
    margin-right: 30px;
    border-radius: 20px
}

@media (max-width: 1920px) {
    .class-article .class-post .class-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .class-article .class-post .class-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .class-article .class-post .class-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .class-article .class-post .class-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .class-article .class-post .class-desc {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .class-article .class-post .class-desc {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .class-article .class-post .class-desc {
        margin-bottom: 15px
    }
}

.class-article .class-post .class-desc h2,
.class-article .class-post .class-desc .h2 {
    text-align: left
}

@media (max-width: 1920px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        margin-bottom: 5px
    }
}

.class-article .class-post .class-desc .price {
    font-family: "Baloo", cursive;
    font-size: 20px;
    line-height: 1.2em;
    color: #ff5157
}

@media (max-width: 1920px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .class-article .class-post .class-desc .price {
        margin-bottom: 10px
    }
}

.class-article .class-post .class-text p:last-child {
    margin-bottom: 0
}

@media (max-width: 991px) {
    .class-article .class-post img {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px
    }
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        text-align: center
    }
    .class-article .class-post .class-desc p {
        text-align: center
    }
    .class-article .class-post .class-desc ul {
        -ms-flex-pack: center;
        justify-content: center
    }
    .class-article .class-post .class-text p {
        text-align: center
    }
}

@media (max-width: 767px) {
    .class-article .class-post .class-desc h2,
    .class-article .class-post .class-desc .h2 {
        text-align: center
    }
    .class-article .class-post .class-text p {
        text-align: justify
    }
}

.single-classes-content .classes-wrap {
    padding-top: 0
}

@media (max-width: 1920px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        margin-bottom: 15px
    }
}

.classes-gallery .gallery-wrap .gallery-card {
    width: 50%
}

.classes-gallery .gallery-wrap .gallery-card a {
    max-width: 260px
}

@media (max-width: 991px) {
    .classes-gallery h3,
    .classes-gallery .h3 {
        text-align: center
    }
}

@media (max-width: 480px) {
    .classes-gallery .gallery-wrap .gallery-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
        width: 100%
    }
}

.single-classes-content .classes-wrap .classes-slider {
    padding-top: 0
}

.single-classes-content h2,
.single-classes-content .h2 {
    text-align: center
}

@media (max-width: 1920px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .single-classes-content h2,
    .single-classes-content .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .our-teachers-section {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .our-teachers-section {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .our-teachers-section {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .our-teachers-section {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .our-teachers-section {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .our-teachers-section {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .our-teachers-section {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .our-teachers-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .our-teachers-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .our-teachers-section {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .our-teachers-section {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .our-teachers-section {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .our-teachers-section {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .our-teachers-section {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .our-teachers-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .our-teachers-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .our-teachers-section .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .our-teachers-section .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .our-teachers-section .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .our-teachers-section .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .our-teachers-section .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .our-teachers-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .our-teachers-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .our-teachers-section .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .our-teachers-section .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .our-teachers-section .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .our-teachers-section .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .our-teachers-section .container {
        padding-bottom: 30px
    }
}

.our-teachers-section .our-teachers-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (max-width: 1920px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -50px
    }
}

@media (max-width: 1600px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -50px
    }
}

@media (max-width: 1440px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -50px
    }
}

@media (max-width: 1199px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -45px
    }
}

@media (max-width: 991px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -40px
    }
}

@media (max-width: 767px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -30px
    }
}

@media (max-width: 480px) {
    .our-teachers-section .our-teachers-list {
        margin-bottom: -30px
    }
}

.our-teachers-section .our-teachers-list>li {
    width: 33.33%;
    padding-left: 15px;
    padding-right: 15px
}

.our-teachers-section .our-teachers-list>li::after {
    display: none
}

@media (max-width: 1199px) {
    .our-teachers-section .our-teachers-list>li {
        width: 50%
    }
}

@media (max-width: 991px) {
    .our-teachers-section .our-teachers-list {
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: start;
        align-items: flex-start
    }
    .our-teachers-section .our-teachers-list>li {
        width: 51%
    }
}

@media (max-width: 767px) {
    .our-teachers-section .our-teachers-list>li {
        padding-left: 0;
        padding-right: 0;
        width: 100%
    }
}

.organizer-block {
    display: -ms-flexbox;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
    background: #f8f8fc
}

@media (max-width: 1920px) {
    .organizer-block {
        padding-top: 20px
    }
}

@media (max-width: 1600px) {
    .organizer-block {
        padding-top: 20px
    }
}

@media (max-width: 1440px) {
    .organizer-block {
        padding-top: 20px
    }
}

@media (max-width: 1199px) {
    .organizer-block {
        padding-top: 20px
    }
}

@media (max-width: 991px) {
    .organizer-block {
        padding-top: 20px
    }
}

@media (max-width: 767px) {
    .organizer-block {
        padding-top: 15px
    }
}

@media (max-width: 480px) {
    .organizer-block {
        padding-top: 15px
    }
}

@media (max-width: 1920px) {
    .organizer-block {
        padding-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .organizer-block {
        padding-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .organizer-block {
        padding-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .organizer-block {
        padding-bottom: 20px
    }
}

@media (max-width: 991px) {
    .organizer-block {
        padding-bottom: 20px
    }
}

@media (max-width: 767px) {
    .organizer-block {
        padding-bottom: 15px
    }
}

@media (max-width: 480px) {
    .organizer-block {
        padding-bottom: 15px
    }
}

@media (max-width: 1920px) {
    .organizer-block {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .organizer-block {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .organizer-block {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .organizer-block {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .organizer-block {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .organizer-block {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .organizer-block {
        margin-bottom: 30px
    }
}

.organizer-block .organizer-avatar {
    width: 147px;
    min-width: 147px;
    height: 147px;
    margin-right: 20px;
    border: 1px solid #5255c5;
    overflow: hidden;
    border-radius: 50%
}

.organizer-block .organizer-avatar img {
    width: 100%
}

.organizer-block .organizer-avatar.color-1 {
    border: 1px solid #5255c5
}

.organizer-block .organizer-avatar.color-2 {
    border: 1px solid #5dba3b
}

.organizer-block .organizer-avatar.color-3 {
    border: 1px solid #ff8b00
}

.organizer-block .organizer-avatar.color-4 {
    border: 1px solid #ff5157
}

.organizer-block .organizer-avatar.color-5 {
    border: 1px solid #ffc000
}

@media (max-width: 1920px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .organizer-block h3,
    .organizer-block .h3 {
        margin-bottom: 5px
    }
}

.organizer-block h3 a,
.organizer-block h3 span,
.organizer-block .h3 a,
.organizer-block .h3 span {
    color: #5255c5
}

.organizer-block h3.color-1 a,
.organizer-block h3.color-1 span,
.organizer-block .h3.color-1 a,
.organizer-block .h3.color-1 span {
    color: #5255c5
}

.organizer-block h3.color-2 a,
.organizer-block h3.color-2 span,
.organizer-block .h3.color-2 a,
.organizer-block .h3.color-2 span {
    color: #5dba3b
}

.organizer-block h3.color-3 a,
.organizer-block h3.color-3 span,
.organizer-block .h3.color-3 a,
.organizer-block .h3.color-3 span {
    color: #ff8b00
}

.organizer-block h3.color-4 a,
.organizer-block h3.color-4 span,
.organizer-block .h3.color-4 a,
.organizer-block .h3.color-4 span {
    color: #ff5157
}

.organizer-block h3.color-5 a,
.organizer-block h3.color-5 span,
.organizer-block .h3.color-5 a,
.organizer-block .h3.color-5 span {
    color: #ffc000
}

@media (max-width: 1920px) {
    .organizer-block .post-info {
        padding-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .organizer-block .post-info {
        padding-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .organizer-block .post-info {
        padding-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .organizer-block .post-info {
        padding-bottom: 20px
    }
}

@media (max-width: 991px) {
    .organizer-block .post-info {
        padding-bottom: 20px
    }
}

@media (max-width: 767px) {
    .organizer-block .post-info {
        padding-bottom: 15px
    }
}

@media (max-width: 480px) {
    .organizer-block .post-info {
        padding-bottom: 15px
    }
}

.organizer-block p {
    margin-bottom: 0
}

@media (max-width: 767px) {
    .organizer-block {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .organizer-block .organizer-avatar {
        margin-right: 0;
        margin-bottom: 30px
    }
    .organizer-block h3,
    .organizer-block .h3 {
        text-align: center
    }
    .organizer-block .post-info {
        -ms-flex-pack: center;
        justify-content: center
    }
    .organizer-block p {
        text-align: justify
    }
}

@media (max-width: 1920px) {
    .location-map-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .location-map-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .location-map-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .location-map-wrap {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .location-map-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .location-map-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .location-map-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .location-map-wrap h3,
    .location-map-wrap .h3,
    .location-map-wrap p {
        margin-bottom: 15px
    }
}

.location-map-wrap #location-map {
    height: 260px
}

.timetable-wrap {
    overflow: auto
}

@media (max-width: 1920px) {
    .timetable-wrap {
        margin-top: 100px
    }
}

@media (max-width: 1600px) {
    .timetable-wrap {
        margin-top: 100px
    }
}

@media (max-width: 1440px) {
    .timetable-wrap {
        margin-top: 100px
    }
}

@media (max-width: 1199px) {
    .timetable-wrap {
        margin-top: 90px
    }
}

@media (max-width: 991px) {
    .timetable-wrap {
        margin-top: 80px
    }
}

@media (max-width: 767px) {
    .timetable-wrap {
        margin-top: 60px
    }
}

@media (max-width: 480px) {
    .timetable-wrap {
        margin-top: 60px
    }
}

@media (max-width: 1920px) {
    .timetable-wrap {
        margin-bottom: 100px
    }
}

@media (max-width: 1600px) {
    .timetable-wrap {
        margin-bottom: 100px
    }
}

@media (max-width: 1440px) {
    .timetable-wrap {
        margin-bottom: 100px
    }
}

@media (max-width: 1199px) {
    .timetable-wrap {
        margin-bottom: 90px
    }
}

@media (max-width: 991px) {
    .timetable-wrap {
        margin-bottom: 80px
    }
}

@media (max-width: 767px) {
    .timetable-wrap {
        margin-bottom: 60px
    }
}

@media (max-width: 480px) {
    .timetable-wrap {
        margin-bottom: 60px
    }
}

.timetable-wrap::before {
    content: ''
}

.timetable-wrap.shadow::before {
    box-shadow: 12px 0px 5px -15px rgba(255, 255, 255, 0.75)
}

.timetable-wrap .left-shadow {
    display: none;
    position: absolute;
    z-index: 10;
    width: 0;
    left: 15px;
    top: 0;
    height: 100%;
    background-color: transparent;
    max-width: 15px
}

.timetable-wrap .left-shadow.shadow {
    background: linear-gradient(to right, #e6e6e6 0%, rgba(255, 255, 255, 0) 100%)
}

.timetable-wrap .right-shadow {
    display: none;
    position: absolute;
    z-index: 10;
    width: 0;
    right: 14px;
    top: 0;
    height: 100%;
    max-width: 15px
}

.timetable-wrap .right-shadow.shadow {
    width: 15px;
    background: linear-gradient(to left, #e6e6e6 0%, rgba(255, 255, 255, 0) 100%)
}

.timetable-wrap .right-shadow.off-shadow {
    background-color: transparent
}

.timetable-wrap .inner-timetable-wrap {
    position: relative
}

.timetable-wrap .timetable-head {
    display: -ms-flexbox;
    display: flex;
    min-width: 900px;
    border-radius: 15px 15px 0 0;
    background: #ddd
}

.timetable-wrap .timetable-head .timetable-head-block {
    width: 190px
}

.timetable-wrap .timetable-head .timetable-head-block h5,
.timetable-wrap .timetable-head .timetable-head-block .h5 {
    margin-bottom: 0;
    text-align: center
}

@media (max-width: 1920px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 10px
    }
}

@media (max-width: 1600px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 10px
    }
}

@media (max-width: 1440px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 10px
    }
}

@media (max-width: 1199px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 10px
    }
}

@media (max-width: 991px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 10px
    }
}

@media (max-width: 767px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 5px
    }
}

@media (max-width: 480px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-top: 5px
    }
}

@media (max-width: 1920px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 10px
    }
}

@media (max-width: 991px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 10px
    }
}

@media (max-width: 767px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 5px
    }
}

@media (max-width: 480px) {
    .timetable-wrap .timetable-head .timetable-head-block h5,
    .timetable-wrap .timetable-head .timetable-head-block .h5 {
        padding-bottom: 5px
    }
}

.timetable-wrap .timetable-body {
    overflow: hidden;
    min-width: 900px;
    border-radius: 0 0 15px 15px
}

.timetable-wrap .timetable-body .timetable-row {
    display: -ms-flexbox;
    display: flex
}

.timetable-wrap .timetable-body .timetable-row .timetable-block {
    width: 190px;
    height: 165px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    transition: all .3s
}

.timetable-wrap .timetable-body .timetable-row .timetable-block h5,
.timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
    color: #fff
}

@media (max-width: 1920px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 1600px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 1440px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 1199px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 991px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 10px
    }
}

@media (max-width: 767px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 5px
    }
}

@media (max-width: 480px) {
    .timetable-wrap .timetable-body .timetable-row .timetable-block h5,
    .timetable-wrap .timetable-body .timetable-row .timetable-block .h5 {
        margin-bottom: 5px
    }
}

.timetable-wrap .timetable-body .timetable-row .timetable-block p {
    margin-bottom: 0;
    color: #fff
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-1 {
    background: #5255c5
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-1:hover {
    background: #474aab
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-2 {
    background: #5dba3b
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-2:hover {
    background: #50a033
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-3 {
    background: #ff8b00
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-3:hover {
    background: #e07b21
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-4 {
    background: #ff5157
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-4:hover {
    background: #e04b4e
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-5 {
    background: #ffc000
}

.timetable-wrap .timetable-body .timetable-row .timetable-block.color-5:hover {
    background: #e5ad00
}

@media (max-width: 1199px) {
    .timetable-wrap {
        overflow-x: auto
    }
    .timetable-wrap .timetable-head .timetable-head-block {
        width: 157px
    }
    .timetable-wrap .left-shadow,
    .timetable-wrap .right-shadow {
        display: block
    }
    .timetable-wrap .timetable-body .timetable-row .timetable-block {
        width: 157px
    }
}

@media (max-width: 767px) {
    .timetable-wrap .timetable-body {
        overflow: hidden;
        min-width: 900px;
        border-radius: 0 0 15px 15px
    }
    .timetable-wrap .timetable-body .timetable-row {
        display: -ms-flexbox;
        display: flex
    }
    .timetable-wrap .timetable-body .timetable-row .timetable-block {
        height: 110px
    }
}

@media (max-width: 1920px) {
    .comments-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .comments-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .comments-wrap {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .comments-wrap {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .comments-wrap {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .comments-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .comments-wrap {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 40px
    }
}

@media (max-width: 1600px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 40px
    }
}

@media (max-width: 1440px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 40px
    }
}

@media (max-width: 1199px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 40px
    }
}

@media (max-width: 991px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 35px
    }
}

@media (max-width: 767px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 25px
    }
}

@media (max-width: 480px) {
    .comments-wrap h3,
    .comments-wrap .h3 {
        margin-bottom: 25px
    }
}

.comments-wrap .comment-list {
    margin-bottom: 0
}

.comments-wrap .comment-list.color-1 li .comment .comment-avatar {
    border: 1px solid #5255c5
}

.comments-wrap .comment-list.color-2 li .comment .comment-avatar {
    border: 1px solid #5dba3b
}

.comments-wrap .comment-list.color-3 li .comment .comment-avatar {
    border: 1px solid #ff8b00
}

.comments-wrap .comment-list.color-4 li .comment .comment-avatar {
    border: 1px solid #ff5157
}

.comments-wrap .comment-list.color-5 li .comment .comment-avatar {
    border: 1px solid #ffc000
}

.comments-wrap .comment-list li {
    padding-left: 0;
    margin-bottom: 20px
}

.comments-wrap .comment-list li::after {
    display: none
}

.comments-wrap .comment-list li ul {
    margin-bottom: 0;
    padding-top: 30px;
    padding-left: 30px
}

.comments-wrap .comment-list li .comment {
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    border-radius: 20px;
    background: #f8f8fc
}

.comments-wrap .comment-list li .comment .comment-avatar {
    overflow: hidden;
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-right: 20px;
    border-radius: 50%;
    border: 1px solid #5255c5
}

.comments-wrap .comment-list li .comment .comment-text h5,
.comments-wrap .comment-list li .comment .comment-text .h5 {
    margin-bottom: 10px
}

@media (max-width: 1920px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1600px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1440px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 20px
    }
}

@media (max-width: 767px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 15px
    }
}

@media (max-width: 480px) {
    .comments-wrap .comment-list li .comment .comment-text p {
        margin-bottom: 15px
    }
}

@media (max-width: 1920px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 15px
    }
}

@media (max-width: 1600px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 15px
    }
}

@media (max-width: 1440px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 10px
    }
}

@media (max-width: 480px) {
    .comments-wrap .comment-list li .comment .comment-text time {
        margin-bottom: 10px
    }
}

.comments-wrap .comment-list li .comment .comment-text a {
    display: block;
    text-align: right;
    color: #090605
}

@media (max-width: 480px) {
    .comments-wrap .comment-list li .comment {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .comments-wrap .comment-list li .comment .comment-avatar {
        margin-right: 0;
        margin-bottom: 20px
    }
    .comments-wrap .comment-list li .comment .comment-text p {
        text-align: justify
    }
    .comments-wrap .comment-list li .comment .comment-text time {
        text-align: center
    }
}

@media (max-width: 1920px) {
    .contact-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .contact-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .contact-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .contact-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .contact-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .contact-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .contact-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .contact-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .contact-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .contact-content .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .contact-content .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .contact-content .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .contact-content .contact-info {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content .contact-info {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content .contact-info {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content .contact-info {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .contact-content .contact-info {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .contact-content .contact-info {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .contact-content .contact-info {
        margin-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .contact-content .contact-info h2,
    .contact-content .contact-info .h2 {
        margin-bottom: 30px
    }
}

.contact-content .contact-info .contact-list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.contact-content .contact-info .contact-list li {
    margin-bottom: 0;
    margin-right: 10px
}

.contact-content .contact-info .contact-list li:last-child {
    margin-bottom: 0
}

@media (max-width: 1199px) {
    .contact-content .contact-info .contact-list {
        -ms-flex-pack: center;
        justify-content: center
    }
    .contact-content .contact-info .contact-list li {
        width: 50%;
        margin-right: 0
    }
}

@media (max-width: 1199px) and (max-width: 1920px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1600px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1440px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 1199px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 991px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 20px
    }
}

@media (max-width: 1199px) and (max-width: 767px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 1199px) and (max-width: 480px) {
    .contact-content .contact-info .contact-list li {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) {
    .contact-content .contact-info .contact-list {
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .contact-content .contact-info .contact-list li {
        width: auto;
        margin-right: 20px
    }
}

@media (max-width: 991px) and (max-width: 1920px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) and (max-width: 1600px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) and (max-width: 1440px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) and (max-width: 1199px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) and (max-width: 991px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 20px
    }
}

@media (max-width: 991px) and (max-width: 767px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 991px) and (max-width: 480px) {
    .contact-content .contact-info .contact-list li:last-child {
        margin-bottom: 15px
    }
}

@media (max-width: 767px) {
    .contact-content .contact-info .contact-list {
        -ms-flex-pack: distribute;
        justify-content: space-around
    }
}

@media (max-width: 480px) {
    .contact-content .contact-info p {
        text-align: justify
    }
    .contact-content .contact-info .contact-list {
        -ms-flex-pack: start;
        justify-content: flex-start;
        width: 100%;
        max-width: 200px;
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 1920px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .contact-content .apply-form-wrap h2,
    .contact-content .apply-form-wrap .h2 {
        margin-bottom: 30px
    }
}

.contact-content .apply-form-wrap .apply-form label {
    color: #777
}

.contact-content .apply-form-wrap .apply-form input,
.contact-content .apply-form-wrap .apply-form textarea {
    border: 1px solid #aeaeae
}

.contact-content .apply-form-wrap .apply-form textarea {
    height: 105px
}

.contact-content .contact-map {
    height: 440px
}

@media (max-width: 1920px) {
    .contact-content .contact-map {
        margin-top: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content .contact-map {
        margin-top: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content .contact-map {
        margin-top: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content .contact-map {
        margin-top: 45px
    }
}

@media (max-width: 991px) {
    .contact-content .contact-map {
        margin-top: 40px
    }
}

@media (max-width: 767px) {
    .contact-content .contact-map {
        margin-top: 30px
    }
}

@media (max-width: 480px) {
    .contact-content .contact-map {
        margin-top: 30px
    }
}

@media (max-width: 1920px) {
    .contact-content .contact-map {
        margin-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .contact-content .contact-map {
        margin-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .contact-content .contact-map {
        margin-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .contact-content .contact-map {
        margin-bottom: 45px
    }
}

@media (max-width: 991px) {
    .contact-content .contact-map {
        margin-bottom: 40px
    }
}

@media (max-width: 767px) {
    .contact-content .contact-map {
        margin-bottom: 30px
    }
}

@media (max-width: 480px) {
    .contact-content .contact-map {
        margin-bottom: 30px
    }
}

.contact-content .contact-map #map {
    height: 100%
}

@media (max-width: 480px) {
    .contact-content .contact-map {
        height: 300px
    }
}

@media (max-width: 1920px) {
    .custom-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .custom-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .custom-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .custom-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .custom-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .custom-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .custom-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .custom-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .custom-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .custom-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .custom-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .custom-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .custom-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .custom-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .custom-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .custom-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .custom-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .custom-content .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .custom-content .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .custom-content .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .custom-content .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .custom-content .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .custom-content .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .custom-content .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .custom-content .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .custom-content .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .custom-content .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .custom-content .container {
        padding-bottom: 30px
    }
}

.custom-content .container *:last-child {
    margin-bottom: 0
}

.custom-content .container h2,
.custom-content .container .h2 {
    text-align: center
}

@media (max-width: 480px) {
    .custom-content p {
        text-align: justify
    }
}

@media (max-width: 1920px) {
    .gallery-page .gallery-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .gallery-page .gallery-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .gallery-page .gallery-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .gallery-page .gallery-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .gallery-page .gallery-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .gallery-page .gallery-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .gallery-page .gallery-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .gallery-page .gallery-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .gallery-page .gallery-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .gallery-page .gallery-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .gallery-page .gallery-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .gallery-page .gallery-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .gallery-page .gallery-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .gallery-page .gallery-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .gallery-page .gallery-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .gallery-page .gallery-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .gallery-page .gallery-content .container {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .gallery-page .gallery-content .container {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .gallery-page .gallery-content .container {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .gallery-page .gallery-content .container {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .gallery-page .gallery-content .container {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .gallery-page .gallery-content .container {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 1600px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 1440px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 1199px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -30px
    }
}

@media (max-width: 991px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -25px
    }
}

@media (max-width: 767px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -20px
    }
}

@media (max-width: 480px) {
    .gallery-page .gallery-content .gallery-wrap {
        margin-bottom: -20px
    }
}

.gallery-page .gallery-content .gallery-wrap .gallery-card {
    width: 33.33%
}

@media (max-width: 480px) {
    .gallery-page .gallery-content .gallery-wrap .gallery-card {
        width: 50%
    }
}

@media (max-width: 1920px) {
    .blog-page .blog-content {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .blog-page .blog-content {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .blog-page .blog-content {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .blog-page .blog-content {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .blog-page .blog-content {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .blog-page .blog-content {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .blog-page .blog-content {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .blog-page .blog-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .blog-page .blog-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .blog-page .blog-content {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .blog-page .blog-content {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .blog-page .blog-content {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .blog-page .blog-content {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .blog-page .blog-content {
        padding-bottom: 30px
    }
}

@media (max-width: 1920px) {
    .blog-page .blog-content .blog-list {
        padding-top: 50px
    }
}

@media (max-width: 1600px) {
    .blog-page .blog-content .blog-list {
        padding-top: 50px
    }
}

@media (max-width: 1440px) {
    .blog-page .blog-content .blog-list {
        padding-top: 50px
    }
}

@media (max-width: 1199px) {
    .blog-page .blog-content .blog-list {
        padding-top: 45px
    }
}

@media (max-width: 991px) {
    .blog-page .blog-content .blog-list {
        padding-top: 40px
    }
}

@media (max-width: 767px) {
    .blog-page .blog-content .blog-list {
        padding-top: 30px
    }
}

@media (max-width: 480px) {
    .blog-page .blog-content .blog-list {
        padding-top: 30px
    }
}

@media (max-width: 1920px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 50px
    }
}

@media (max-width: 1600px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 50px
    }
}

@media (max-width: 1440px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 50px
    }
}

@media (max-width: 1199px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 45px
    }
}

@media (max-width: 991px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 40px
    }
}

@media (max-width: 767px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 30px
    }
}

@media (max-width: 480px) {
    .blog-page .blog-content .my-pagination {
        padding-bottom: 30px
    }
}

.blog-two-columns .blog-list {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px
}

.blog-two-columns .blog-list .blog-post {
    width: 50%;
    padding-left: 15px;
    padding-right: 15px
}

.blog-two-columns .blog-list .blog-post .blog-prview a {
    display: block
}

.blog-two-columns .blog-list .blog-post .blog-prview img {
    height: 320px;
    width: 100%
}

.blog-two-columns .blog-list .blog-post .blog-text {
    padding-left: 25px;
    padding-right: 25px
}

@media (max-width: 1199px) {
    .blog-two-columns .blog-list .blog-post .blog-prview img {
        height: 270px;
        width: 100%
    }
}

@media (max-width: 991px) {
    .blog-two-columns .blog-list {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center
    }
    .blog-two-columns .blog-list .blog-post {
        width: 100%;
        max-width: 555px
    }
    .blog-two-columns .blog-list .blog-post .blog-prview img {
        height: auto;
        width: 100%
    }
    .blog-two-columns .blog-list .blog-post .blog-text {
        padding-left: 20px;
        padding-right: 20px
    }
}

.blog-chess .blog-list .blog-post {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-align: center;
    align-items: center
}

.blog-chess .blog-list .blog-post:nth-child(even) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.blog-chess .blog-list .blog-post:nth-child(even) .blog-prview {
    padding-left: 15px
}

.blog-chess .blog-list .blog-post:nth-child(even) .blog-text {
    padding-right: 15px;
    text-align: right
}

.blog-chess .blog-list .blog-post:nth-child(even) .blog-text .blog-info-list {
    -ms-flex-pack: end;
    justify-content: flex-end
}

.blog-chess .blog-list .blog-post .blog-prview {
    width: 50%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-right: 15px
}

.blog-chess .blog-list .blog-post .blog-prview img {
    border-radius: 20px
}

.blog-chess .blog-list .blog-post .blog-text {
    width: 50%;
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent
}

.blog-chess .blog-list .blog-post .blog-text p {
    text-align: justify
}

@media (max-width: 1199px) {
    .blog-chess .blog-list .blog-post {
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 555px;
        margin-left: auto;
        margin-right: auto
    }
    .blog-chess .blog-list .blog-post:nth-child(even) {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .blog-chess .blog-list .blog-post:nth-child(even) .blog-prview {
        padding-left: 0
    }
    .blog-chess .blog-list .blog-post:nth-child(even) .blog-text {
        text-align: left
    }
    .blog-chess .blog-list .blog-post:nth-child(even) .blog-text .blog-info-list {
        -ms-flex-pack: start;
        justify-content: flex-start
    }
    .blog-chess .blog-list .blog-post .blog-prview {
        width: 100%;
        padding-right: 0
    }
    .blog-chess .blog-list .blog-post .blog-prview img {
        border-radius: 20px 20px 0 0
    }
    .blog-chess .blog-list .blog-post .blog-text {
        width: 100%;
        padding-left: 0;
        padding: 20px;
        background-color: #f8f8fc
    }
    .blog-chess .blog-list .blog-post .blog-text p {
        text-align: justify
    }
}

.gallery-two-columns .gallery-content .gallery-wrap .gallery-card {
    width: 50%
}

.gallery-four-columns .gallery-content .gallery-wrap .gallery-card {
    width: 25%
}

@media (max-width: 991px) {
    .gallery-four-columns .gallery-content .gallery-wrap .gallery-card {
        width: 50%
    }
}

@media (max-width: 991px) {
    .single-class-page .classes-gallery .gallery-wrap .gallery-card a {
        max-width: none
    }
    .single-class-page .classes-gallery .gallery-wrap .gallery-card a img {
        width: 100%
    }
}

@media (max-width: 991px) {
    .single-event-page .classes-gallery .gallery-wrap .gallery-card a {
        max-width: none
    }
    .single-event-page .classes-gallery .gallery-wrap .gallery-card a img {
        width: 100%
    }
}

@media (max-width: 991px) {
    .single-event-page .location-map-wrap {
        text-align: center
    }
}