
ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
*/
.tab-content {
  padding: 15px;
  border: 1px solid #eeeeee;
}
.nav-tabs {
  border: none;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
  /* border-radius: 15px 15px 0 0; */

}
.nav-tabs > li.active a,
.nav-tabs > li.active a:hover,
.nav-tabs > li.active a:focus {
  color: #111111;
  /* background-color: #f8f8f8; */
}
.nav-tabs > li > a {
  border-radius: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #555555;
  padding: 12px 20px;
  margin-right: 0;
}
.nav-tabs > li > a .fa {
  margin-right: 10px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #333333;
  background-color: transparent;
}
.nav-tabs > li > a i {
  font-size: 32px;
  margin-right: 20px;
  vertical-align: middle;
}
/* -------- Nav Tabs ---------- */
.nav-tabs > li {
  text-align: center;
  list-style: none;
}
.nav-tabs > li > a i {
  display: block;
  font-size: 48px;
  margin-right: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}

@media only screen and (max-width: 767px) {
  .vertical-tab .tab-content {
    padding: 20px 5px;
  }
  .nav-tabs > li > a {
    border-radius: 0;
    border-bottom: none;
    /* border-left: 2px solid #d3d3d3; */
  }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    /* border-bottom: none; */
    /* border-left: 2px solid #d3d3d3; */
  }
}
.custom-nav-tabs {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: -moz-use-text-color;
  border-image: none;
  border-style: none solid none none;
  border-width: medium 0px medium medium;
  margin-right: 53px;
}
.custom-nav-tabs > li {
  float: none;
  margin: 0 0 1px;
}
.custom-nav-tabs > li a {
  background: #eeeeee none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  display: block;
  font-size: 17px;
  font-weight: 700;
  line-height: 59px;
  margin: 0;
  outline: medium none;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
}
.custom-nav-tabs > li.active > a,
.custom-nav-tabs > li.active > a:hover,
.custom-nav-tabs > li.active > a:focus,
.custom-nav-tabs > li > a:hover,
.custom-nav-tabs > li > a:focus {
  border: medium none;
  color: #000;
  padding: 0;
}
/* -------- Services Tab ---------- */
.services-tab .nav-tabs {
  /* border-bottom: 1px solid #f1f1f1; */
  text-align: center;
}
.services-tab .nav-tabs > li {
  /* border-left: 1px solid #eeeeee; */
  margin-bottom: 0;
  width: 33.3%;
}
.services-tab .nav-tabs > li:first-child {
  border-left: none;
}
.services-tab .nav-tabs > li > a {
  color: #fff;
  font-size: 13px;
  margin-right: 0;
  padding: 35px 20px;
  text-transform: uppercase;
}
.services-tab .nav-tabs > li.active > a {
  color: #222;
}
.services-tab .nav-tabs > li > a i {
  display: block;
  font-size: 36px;
  margin-right: 0;
  margin-bottom: 10px;
  vertical-align: middle;
}
@media only screen and (max-width: 1199px) {
  .services-tab .service-content {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .services-tab .service-content {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .services-tab .nav.nav-tabs a {
    /* padding: 10px 0; */
    padding: 10px 0 0 10px;
    font-size: 9px;
  }
  .services-tab .nav.nav-tabs a i {
    font-size: 28px;
  }
  .services-tab .nav.nav-tabs a span {
    display: none;
  }
}
.vertical-tab .nav-tabs li a:active {
  color: #d3d3d3;
}

/* background-theme-color-red */
.bg-theme-color-red {
    background-color: #FF5252 !important;
  }
  .bg-theme-color-orange {
    background-color: #FF9800 !important;
  }
  .bg-theme-color-blue {
    background-color: #5D2999 !important;
  }
  .bg-theme-color-sky {
    background-color: #00BCD4 !important;
  }
  .bg-theme-color-lemon {
    background-color: #96D81F !important;
  }
  .bg-theme-color-yellow {
    background-color: #FEC02A !important;
  }
  .bg-theme-color-green {
    background-color: #31C3B0 !important;
  }
  /* background-color-transparent */
  .bg-theme-color-red-transparent-9 {
    background-color: rgba(255, 82, 82, 0.9);
  }
  .bg-theme-color-red-transparent-8 {
    background-color: rgba(255, 82, 82, 0.8);
  }
  /* bg-hover-theme-color-red */
  .bg-hover-theme-color-red:hover {
    background: #FF5252 !important;
    border-color: #FF5252 !important;
    color: #fff !important;
  }


  .bg-white {
    background-color: #fff !important;
}

.border-3px {
    border: 5px solid #00BCD4 !important;
    border-top: 0px !important;
    /* border-radius: 15px 15px ; */
}
.tab-content {
    padding: 15px;
    border: 1px solid #eeeeee;
}

.ml-20 {margin-left: 20px !important}
.ml-20 {margin-left: 20px !important}
.mt-30 {margin-top: 30px !important}
.mb-20 {margin-bottom: 20px !important}
.mt-10 {margin-top: 10px !important}

.bar{
   
    color: #fff;
    background:#96D81F;
    margin-top: 0px
}

.vertical-text {
	transform: rotate(90deg);
	transform-origin: left top 0;
}


.nav-tabs>li>a:hover {
  border-color:transparent;
}
.text-color-1{
  
  color:#6A2E90 !important;
}

.text-color-2{
  
  color:#5dba3b !important;
}

.text-color-3{
  color: #ff8b00 !important;
}

.text-color-4{
  
  color:#ff5157 !important;
}
.text-color-5{
  color:#fff !important;

}
.color-6{
  background: #4561BE !important;
}
.text-color-6{
  
  color:#4561BE !important;
}

.text-color-8{
  
  color:#D32179 !important;
}

/* hr tag */


hr.style-seven {
  overflow: visible; /* For IE */
  height: 20px;
  border-style: solid;
  border-color: #4561be;
  border-width: 1px 0 0 0;
  border-radius: 40px;
}
hr.style-seven:before { /* Not really supposed to work, but does */
  display: block;
  content: "";
  height: 20px;
  margin-top: -21px;
  border-style: solid;
  border-color: #4561be;
  border-width: 0 0 1px 0;
  border-radius: 40px;
}


/* footer */

.footer-top { padding: 60px 0;  text-align: left; color: #fff; font-family: "Baloo", cursive; }
.footer-top h3 { padding-bottom: 10px; color: #fff; }

.footer-about img.logo-footer { max-width: 154px; margin-top: 0; margin-bottom: 18px; }
/* .footer-about p a { border: 0; }
.footer-about p { word-wrap: break-word; } */
.footer-about p a:hover, .footer-about p a:focus { border: 0; }

.footer-contact p { word-wrap: break-word; }
.footer-contact i { padding-right: 10px; font-size: 22px; color: #fff; }
.footer-contact p a { border: 0; }
.footer-contact p a:hover, .footer-contact p a:focus { border: 0; }

.footer-links a { color: #fff; border: 0; }
.footer-links a:hover, .footer-links a:focus { color: #fff; }

.footer-bottom { padding: 15px 0 17px 0; border-top:1px solid #fff; text-align: left; color: #fff; }

.footer-social { padding-top: 3px; text-align: right; }
.footer-social a { margin-left: 20px; color: #fff; border: 0; }
.footer-social a:hover, .footer-social a:focus { color: #ff5157; border: 0; }
.footer-social i { font-size: 34px; vertical-align: middle; color: #fff }

.footer-copyright { padding-top: 5px; }
.footer-copyright a { color: #fff; border: 0; }
.footer-copyright a:hover, .footer-copyright a:focus { color: #ff5157; border: 0; }


@media only screen and (max-width: 991px) {
  .footer-about img.logo-footer { 
    display: block;
    margin-left: auto;
    margin-right: auto;
   }
   .footer-contact p.about-text{
     display: none;
   }
}


body{
  font-family: "Baloo", cursive;
}


/* header
 */



.sticky {
  position: fixed;
  top: 0;
  background: #fff;
  /* border-bottom: 1px solid azure; */
  border-bottom: 5px solid #ff5157;
  box-shadow: 3px -4px 3px rgba(50, 50, 50, 0.55);
  width: 100%
}


.single-post-content .single-post-article .article-text {
  border-bottom: 1px solid #fff;
}

body h4, body .h4 {
  font-size: 14px;
}
















.history-sec {
	float: left;
	width: 100%;
	padding-bottom: 65px;
}
.history-sec > h3 {
	font-size: 30px;
	font-weight: 500;
	margin-bottom: 30px;
}
.history-sec p {
	float: left;
	width: 100%;
	margin-bottom: 30px;
}
.history-sec .timeline-sec-details {
	margin-top: 75px;
}
body.hpp3.st2.no-bg {
	background:inherit;
}


        /* ================================================================================== 
									TIMELINE SEC DETAILS
===================================================================================== */


.timeline-sec-details {
	float: left;
	width: 100%;
	position: relative;
}
.timeline-div {
	float: left;
	width: 100%;
	position: relative;
}
.timeline-div:last-child:before {
	display: none;
}
.timeline-div:before {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 1px;
	height: 100%;
	background-color: #fff;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	transform: translateX(-50%);
}
.timeline-details {
	float: left;
	width: 50%;
	text-align: right;
	padding-right: 90px;
	position: relative;
	padding-bottom: 60px;
}

.timeline-details:before {
	content: '';
	position: absolute;
	top: 0;
	right: -8px;
	width: 15px;
	height: 15px;
	background-color: #8488c0;
    z-index: 9;
    border-radius: 50%;

}
.timeline-details:after {
	content: '';
	position: absolute;
	top: -7px;
	right: -15px;
	width: 29px;
	height: 29px;
    background-color: #f5f6fa;
    border-radius: 50%;
}

.timeline-details > span {
	display: inline-block;
	color: #83e21d;
	font-size: 16px;
	margin-bottom: 13px;
	position: relative;
	padding-right: 13px;
}
.timeline-details > span:before {
	content: '';
	position: absolute;
	top: 8px;
	left: 100%;
	width: 50px;
	height: 1px;
	background-color: #83e21d;
}
.timeline-details > h3 {
	color: #ffffff;
	font-size: 22px;
	margin-bottom: 25px;
}
.timeline-details > p {
	color: #8f83ab;
	font-size: 16px;
	line-height: 30px;
}
.timeline-div:nth-child(even) .timeline-details {
	float: right;
	text-align: left;
	padding-right: 0;
	padding-left: 90px;
} 
.timeline-div:nth-child(even) .timeline-details > span:before {
	right: 100%;
	left: auto;
}
.timeline-div:nth-child(even) .timeline-details > span {
	padding-left: 13px;
	padding-right: 0;
}
.timeline-div:nth-child(even) .timeline-details:before {
	left: -8px;
	right: auto;
}
.timeline-div:nth-child(even) .timeline-details:after {
	left: -15px;
	right: auto;
}
         


 .timeline-div:before {
	background-color: #e1e3ef;
}
 .timeline-details:before {
	background-color: #8488c0;
}
 .timeline-details:after {
	background-color: #f5f6fa;
}
 .timeline-details > span {
	color: #8488c0;
}
 .timeline-details > span:before {
	background-color: #8488c0;
}
 .timline-sec {
	background-color: #fff;
	background-image: url(../images/timeline-bg.png);
	background-repeat: no-repeat;
	background-size: cover;
}
 .app-sec {
	background-color: #f3f4fa;
}
 .timeline-div:last-child .timeline-details {
	padding-bottom: 0;
}


.bg-gradient-1{
background: rgb(69,97,190);
background: linear-gradient(41deg, rgba(69,97,190,1) 1%, rgba(255,255,255,1) 39%);

}


@media (max-width: 1366px) {
	.history-sec p {font-size: 15px;}
}

@media (max-width: 480px) {
    .history-sec > h3 {font-size: 20px;}
}

@media (max-width: 768px) {
	.timeline-details {width: 100%;}
    .timeline-div::before {display: none;}
}

@media (max-width: 648px) {
	header .header-top-info {
    display: none
}
}


/* accordion
 */



#accordion .panel {
  border: medium none;
  border-radius: 0;
  box-shadow: none;
  margin: 0 0 15px 10px;
}
#accordion .panel-heading {
  border-radius: 30px;
  padding: 0;
}
#accordion .panel-title a {
  background: #ffb900 none repeat scroll 0 0;
  border: 2px solid transparent;
  /* border-radius: 30px; */
  color: #fff;
  display: block;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 20px 12px 50px;
  position: relative;
  transition: all 0.3s ease 0s;
  text-align: center;
}
#accordion .panel-title a.collapsed {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #ddd;
  color: #333;
}
#accordion .panel-title a::after, #accordion .panel-title a.collapsed::after {
  background: #ffb900 none repeat scroll 0 0;
  border: 1px solid transparent;
  border-radius: 50%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.58);
  color: #fff;
  content: "";
  font-family: fontawesome;
  font-size: 25px;
  height: 55px;
  left: -20px;
  line-height: 55px;
  position: absolute;
  text-align: center;
  top: -5px;
  transition: all 0.3s ease 0s;
  width: 55px;
}
#accordion .panel-title a.collapsed::after {
  background: #fff none repeat scroll 0 0;
  border: 2px solid #ddd;
  box-shadow: none;
  color: #333;
  content: "";
}
#accordion .panel-body {
  background: transparent none repeat scroll 0 0;
  border-top: medium none;
  padding: 20px 25px 10px 9px;
  position: relative;
}
#accordion .panel-body p {
  border-left: 1px dashed #8488c0;;
  padding-left: 25px;
}

#accordion .panel-body:after{
  content: "";
  position: absolute;
  top: -30px;
  left: 50px;
  border: 15px solid transparent;
  border-bottom: 15px solid #00BDD0;
 }

 /* backgrounds */

 .background-1{
  background-color: #ffc000;
  background-image: url(../img/absurdity.png);

 }